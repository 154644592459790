$prefix: bs-;


// ==============Spacers
  $spacer: 1rem;
  $spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    7: $spacer * .4375,
    10: $spacer * .625,
    20: $spacer * 1.25,
    30: $spacer * 1.875,
    35: $spacer * 2.1875,
    40: $spacer * 2.5,
    60: $spacer * 3.75,
    70: $spacer * 4.375,
    80: $spacer * 5,
    90: $spacer * 5.625,
    100: $spacer * 6.25,
    120: $spacer * 7.5,
    130: $spacer * 8.125,
    140: $spacer * 8.75,
    150: $spacer * 9.375,
    160: $spacer * 10,
    200: $spacer * 12.5,
  );
// ==============Spacers


// ==============Typography
  $font-weight-base:        400;
  $line-height-base:        1.444444444;
  $body-color:              #696971;
  $font-family-sans-serif:  "Yantramanav", sans-serif;

  // $box-shadow:               0 .1rem .95rem rgba(0, 0, 0, 0.1);

  
  $headings-font-weight:    600;
  $headings-font-family:    "Lexend", sans-serif;
  $headings-line-height:    1.3;

  $headings-color:          var(--#{$prefix}secondary);
// ==============Typography


// ==============Buttons
  // $btn-padding-x : 1.878rem;
  $btn-padding-x : 1.9rem;
  $btn-padding-y : .9rem; 
  $btn-border-width : 2px;
  $btn-line-height : 28px;
  $btn-font-weight : 500;
  $btn-font-family : "Lexend", sans-serif;  
  $btn-border-radius: 4px;
 
  $btn-padding-x-sm : 15.8px; //(20px changed)
  $btn-padding-y-sm : 7px; // btn-height 46px (11px changed)
  $btn-border-radius-sm : 2px; //3px(changed)
// ==============Buttons


// ==============Font Sizes
  $font-size-base: 1.125rem; //18px

  $h1-font-size:                4.6875rem; //75px
  $h2-font-size:                4rem; //64px
  $h3-font-size:                2.5rem; //40px
  $h4-font-size:                2rem; //32px
  $h5-font-size:                1.5rem; //24px
  $h6-font-size:                $font-size-base; //18px

  $small-font-size:             14px;
  $lead-font-size:              $font-size-base * 1.11111111;
  $lead-font-weight:            500 ;

  $display-font-sizes: (
    1: 20.25rem, //324px
    2: 12.5rem, //200px  
    3: 5.375rem, // 90px
    4: 3.75rem, // 50px
    5: 3rem, // 48px
    6: 2.125rem // 34px
  );
// ==============Font Sizes


// ============== Nav
$nav-link-padding-x:                0; 
$nav-link-font-size:                1.125rem; // 18px
$nav-link-font-weight:              400;

$navbar-padding-y:                  0;
$navbar-nav-link-padding-x:         0; 

$navbar-light-hover-color:          var(--#{$prefix}primary);
$navbar-dark-hover-color:           var(--#{$prefix}white);
$navbar-light-active-color:         var(--#{$prefix}primary);
$navbar-dark-active-color:          var(--#{$prefix}white);
// ============== Nav


// ==============zindex-levels-map
  $zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    5: 5,
    9: 9,
    99: 99,
    999: 999,
    9999: 9999,
  );
// ==============zindex-levels-map



$enable-negative-margins:     true;
$enable-important-utilities:  false;

$card-inner-border-radius:          10px;
$card-title-color:                  var(--#{$prefix}heading-color);
$card-subtitle-color:               var(--#{$prefix}body-color);
$card-border-width:                 0;

$breadcrumb-margin-bottom:          0;
$breadcrumb-active-color:           var(--#{$prefix}primary);

$pagination-padding-y:              .75rem;
$pagination-padding-x:              1.25rem;

$pagination-focus-color:            none;
$pagination-focus-bg:               none;
$pagination-focus-box-shadow:       none;

$accordion-button-active-bg:              var(--#{$prefix}body-bg);
$accordion-button-active-color:           var(--#{$prefix}primary);

$accordion-padding-y:                     .8rem;