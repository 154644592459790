/*!
   * Bootstrap  v5.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Yantramanav", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.444444;
  --bs-body-color: #696971;
  --bs-body-color-rgb: 105, 105, 113;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(105, 105, 113, 0.75);
  --bs-secondary-color-rgb: 105, 105, 113;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(105, 105, 113, 0.5);
  --bs-tertiary-color-rgb: 105, 105, 113;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: var(--bs-secondary);
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.59375rem + 4.125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 4.6875rem;
  }
}

h2, .h2 {
  font-size: calc(1.525rem + 3.3vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 4rem;
  }
}

h3, .h3 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.5rem;
  }
}

h4, .h4 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 2rem;
  }
}

h5, .h5 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.5rem;
  }
}

h6, .h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875rem;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875rem;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875rem;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 500;
}

.display-1 {
  font-size: calc(3.15rem + 22.8vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 20.25rem;
  }
}

.display-2 {
  font-size: calc(2.375rem + 13.5vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 12.5rem;
  }
}

.display-3 {
  font-size: calc(1.6625rem + 4.95vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 5.375rem;
  }
}

.display-4 {
  font-size: calc(1.5rem + 3vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.75rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3375rem + 1.05vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.125rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.265625rem + 0.1875vw);
}

@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.40625rem;
  }
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 0.4375rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 0.4375rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 0.625rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 0.625rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 1.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 1.25rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 1.875rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 1.875rem;
}

.g-35,
.gx-35 {
  --bs-gutter-x: 2.1875rem;
}

.g-35,
.gy-35 {
  --bs-gutter-y: 2.1875rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 2.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 2.5rem;
}

.g-60,
.gx-60 {
  --bs-gutter-x: 3.75rem;
}

.g-60,
.gy-60 {
  --bs-gutter-y: 3.75rem;
}

.g-70,
.gx-70 {
  --bs-gutter-x: 4.375rem;
}

.g-70,
.gy-70 {
  --bs-gutter-y: 4.375rem;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 5rem;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 5rem;
}

.g-90,
.gx-90 {
  --bs-gutter-x: 5.625rem;
}

.g-90,
.gy-90 {
  --bs-gutter-y: 5.625rem;
}

.g-100,
.gx-100 {
  --bs-gutter-x: 6.25rem;
}

.g-100,
.gy-100 {
  --bs-gutter-y: 6.25rem;
}

.g-120,
.gx-120 {
  --bs-gutter-x: 7.5rem;
}

.g-120,
.gy-120 {
  --bs-gutter-y: 7.5rem;
}

.g-130,
.gx-130 {
  --bs-gutter-x: 8.125rem;
}

.g-130,
.gy-130 {
  --bs-gutter-y: 8.125rem;
}

.g-140,
.gx-140 {
  --bs-gutter-x: 8.75rem;
}

.g-140,
.gy-140 {
  --bs-gutter-y: 8.75rem;
}

.g-150,
.gx-150 {
  --bs-gutter-x: 9.375rem;
}

.g-150,
.gy-150 {
  --bs-gutter-y: 9.375rem;
}

.g-160,
.gx-160 {
  --bs-gutter-x: 10rem;
}

.g-160,
.gy-160 {
  --bs-gutter-y: 10rem;
}

.g-200,
.gx-200 {
  --bs-gutter-x: 12.5rem;
}

.g-200,
.gy-200 {
  --bs-gutter-y: 12.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 0.4375rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 0.4375rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-35,
  .gx-sm-35 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-35,
  .gy-sm-35 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-70,
  .gx-sm-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-70,
  .gy-sm-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-90,
  .gx-sm-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-90,
  .gy-sm-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-100,
  .gx-sm-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-100,
  .gy-sm-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-120,
  .gx-sm-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-120,
  .gy-sm-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-130,
  .gx-sm-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-sm-130,
  .gy-sm-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-sm-140,
  .gx-sm-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-sm-140,
  .gy-sm-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-sm-150,
  .gx-sm-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-sm-150,
  .gy-sm-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-sm-160,
  .gx-sm-160 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-160,
  .gy-sm-160 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-200,
  .gx-sm-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-200,
  .gy-sm-200 {
    --bs-gutter-y: 12.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 0.4375rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 0.4375rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-35,
  .gx-md-35 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-35,
  .gy-md-35 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-70,
  .gx-md-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-70,
  .gy-md-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 5rem;
  }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 5rem;
  }
  .g-md-90,
  .gx-md-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-90,
  .gy-md-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-100,
  .gx-md-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-100,
  .gy-md-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-120,
  .gx-md-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-120,
  .gy-md-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-130,
  .gx-md-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-md-130,
  .gy-md-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-md-140,
  .gx-md-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-md-140,
  .gy-md-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-md-150,
  .gx-md-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-md-150,
  .gy-md-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-md-160,
  .gx-md-160 {
    --bs-gutter-x: 10rem;
  }
  .g-md-160,
  .gy-md-160 {
    --bs-gutter-y: 10rem;
  }
  .g-md-200,
  .gx-md-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-200,
  .gy-md-200 {
    --bs-gutter-y: 12.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 0.4375rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 0.4375rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-35,
  .gx-lg-35 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-35,
  .gy-lg-35 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-70,
  .gx-lg-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-70,
  .gy-lg-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-90,
  .gx-lg-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-90,
  .gy-lg-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-100,
  .gx-lg-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-100,
  .gy-lg-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-120,
  .gx-lg-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-120,
  .gy-lg-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-130,
  .gx-lg-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-lg-130,
  .gy-lg-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-lg-140,
  .gx-lg-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-lg-140,
  .gy-lg-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-lg-150,
  .gx-lg-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-lg-150,
  .gy-lg-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-lg-160,
  .gx-lg-160 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-160,
  .gy-lg-160 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-200,
  .gx-lg-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-200,
  .gy-lg-200 {
    --bs-gutter-y: 12.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 0.4375rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 0.4375rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-35,
  .gx-xl-35 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-35,
  .gy-xl-35 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-60,
  .gx-xl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-60,
  .gy-xl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-70,
  .gx-xl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-70,
  .gy-xl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xl-80,
  .gx-xl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-80,
  .gy-xl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-90,
  .gx-xl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xl-90,
  .gy-xl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xl-100,
  .gx-xl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xl-100,
  .gy-xl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xl-120,
  .gx-xl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-120,
  .gy-xl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-130,
  .gx-xl-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xl-130,
  .gy-xl-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xl-140,
  .gx-xl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xl-140,
  .gy-xl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xl-150,
  .gx-xl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xl-150,
  .gy-xl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xl-160,
  .gx-xl-160 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-160,
  .gy-xl-160 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-200,
  .gx-xl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xl-200,
  .gy-xl-200 {
    --bs-gutter-y: 12.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 0.4375rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 0.4375rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-35,
  .gx-xxl-35 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-35,
  .gy-xxl-35 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-60,
  .gx-xxl-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-60,
  .gy-xxl-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-70,
  .gx-xxl-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-70,
  .gy-xxl-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxl-80,
  .gx-xxl-80 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-80,
  .gy-xxl-80 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-90,
  .gx-xxl-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxl-90,
  .gy-xxl-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxl-100,
  .gx-xxl-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxl-100,
  .gy-xxl-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxl-120,
  .gx-xxl-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-120,
  .gy-xxl-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-130,
  .gx-xxl-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xxl-130,
  .gy-xxl-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xxl-140,
  .gx-xxl-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xxl-140,
  .gy-xxl-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xxl-150,
  .gx-xxl-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xxl-150,
  .gy-xxl-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xxl-160,
  .gx-xxl-160 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-160,
  .gy-xxl-160 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-200,
  .gx-xxl-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxl-200,
  .gy-xxl-200 {
    --bs-gutter-y: 12.5rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.444444;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(1.265625rem + 0.1875vw);
}

@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.40625rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.984375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.444444;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.444444em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.444444;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.444444em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.444444em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.265625rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg);
}

@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.40625rem;
  }
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.444444em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.444444em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.444444em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.444444em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.444444em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.444444em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.444444;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.984375rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.265625rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg);
}

@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.40625rem;
  }
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.625rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.222222em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.444444;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.265625rem + 0.1875vw);
  border-radius: var(--bs-border-radius-lg);
}

@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.40625rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.984375rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.444444em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.361111em + 0.1875rem) center;
  background-size: calc(0.722222em + 0.375rem) calc(0.722222em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.444444em + 0.75rem);
  background-position: top calc(0.361111em + 0.1875rem) right calc(0.361111em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.722222em + 0.375rem) calc(0.722222em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.444444em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.984375rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.444444em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.361111em + 0.1875rem) center;
  background-size: calc(0.722222em + 0.375rem) calc(0.722222em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.444444em + 0.75rem);
  background-position: top calc(0.361111em + 0.1875rem) right calc(0.361111em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.722222em + 0.375rem) calc(0.722222em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.444444em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.9rem;
  --bs-btn-padding-y: 0.9rem;
  --bs-btn-font-family: Lexend, sans-serif;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 28px;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 2px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 4px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.265625rem + 0.1875vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.40625rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 7px;
  --bs-btn-padding-x: 15.8px;
  --bs-btn-font-size: 0.984375rem;
  --bs-btn-border-radius: 2px;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.125rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.984375rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 4px;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(2px * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.425rem;
  padding-left: 1.425rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 11.85px;
  padding-left: 11.85px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(2px * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 1.125rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: var(--bs-primary);
  --bs-navbar-brand-padding-y: 0.296875rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: var(--bs-primary);
  --bs-navbar-brand-hover-color: var(--bs-primary);
  --bs-navbar-nav-link-padding-x: 0;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28105, 105, 113, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 4px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 1.125rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: var(--bs-white);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: var(--bs-white);
  --bs-navbar-brand-color: var(--bs-white);
  --bs-navbar-brand-hover-color: var(--bs-white);
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: var(--bs-heading-color);
  --bs-card-subtitle-color: var(--bs-body-color);
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 10px;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 0.8rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23696971'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 0.8rem;
  --bs-accordion-active-color: var(--bs-primary);
  --bs-accordion-active-bg: var(--bs-body-bg);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.125rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-primary);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 1.25rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(1.265625rem + 0.1875vw);
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

@media (min-width: 1200px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.40625rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.984375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.84375rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.444444;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.984375rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.444444;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.984375rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1.125rem;
  --bs-popover-header-color: var(--bs-secondary);
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.444444;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.444444;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

body {
  padding-right: 0 !important;
}

@media (max-width: 991.98px) {
  .page {
    overflow-x: hidden;
  }
}

:root {
  --bs-primary: #F06728;
  --bs-primary-rgb: 240, 103, 40;
  --bs-secondary: #25224B;
  --bs-secondary-rgb: 37, 34, 75;
  --bs-border-color: var(--bs-body-color-style-1);
  --bs-border-width: 2px;
  --bs-font-family-style-1: "Lexend", sans-serif;
  --bs-font-family-style-2: "Yantramanav", sans-serif;
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  --bs-body-color-style-1: #f9f9f9;
  --bs-body-color-style-1-rgb: 249, 249, 249;
  --bs-body-color-style-2: #484655;
  --bs-body-color-style-2-rgb: 72, 70, 85, 1;
  --bs-body-color-style-3: #EBEBEF;
  --bs-body-color-style-3-rgb: 235, 235, 239;
  --bs-bg-custom-light: #fffbf9;
  --bs-bg-custom-light-rgb: 255, 251, 249;
  --bs-bg-custom-light-style-2: #EFEFF7;
  --bs-bg-custom-light-style-2-rgb: 239, 239, 247;
  --bs-heading-color-style-1: #4D447B;
  --bs-heading-color-style-1-rgb: 77, 68, 123;
  --bs-brands-border-color: #D9D9D9;
  --bs-border-color-style-1: #9C9CA0;
  --bs-border-color-style-2: #EBEBEF;
}

[data-bs-theme="light"] {
  --bs-primary: #F06728;
  --bs-primary-rgb: 240, 103, 40;
  --bs-secondary: #25224B;
  --bs-secondary-rgb: 37, 34, 75;
  --bs-border-color: var(--bs-primary);
}

[data-bs-theme="light"] .form-control {
  --bs-border-width: 0;
}

[data-bs-theme="dark"] {
  --bs-heading-color-style-1: #EFEFF7;
  --bs-heading-color-style-1-rgb: 239, 239, 247;
  --bs-bg-custom-light: #000;
  --bs-bg-custom-light-rgb: 0,0,0;
  --bs-bg-custom-light-style-2: #000;
  --bs-bg-custom-light-style-2-rgb: 0,0,0;
  --bs-body-color-style-3: var(--bs-body-bg);
  --bs-body-color-style-2: #f5f5f6;
}

[data-bs-theme="dark"] .heading-color-style-1 {
  --bs-heading-color-style-1: #EFEFF7;
  --bs-heading-color-style-1-rgb: 239, 239, 247;
}

[data-bs-theme="dark"] .shadow {
  box-shadow: 0 0.1rem 0.95rem rgba(255, 255, 255, 0.1) !important;
}

[data-bs-theme="dark"] .team-image-hover {
  --bs-team-image-hover-bg: rgba(33, 33, 33, .9);
}

[data-bs-theme="dark"] .portfolio-image-hover {
  --bs-portfolio-image-hover-bg: rgba(33, 33, 33, .9);
}

[data-bs-theme="dark"] .arrow-icon {
  --bs-arrow-icon-stroke-color: #EFEFF7;
}

[data-bs-theme="dark"] .about-service {
  --bs-about-service-text-color: rgba(255,255,255, .8);
}

[data-bs-theme="dark"] .contact-about {
  --bs-link-color-rgb: var(--bs-body-color-style-2-rgb);
  --bs-link-color: var(--bs-body-color-style-2);
  --bs-link-hover-color: var(--bs-body-color-style-2);
  --bs-link-hover-color-rgb: var(--bs-body-color-style-2-rgb);
}

[data-bs-theme="dark"] .contact-about .contact-icon {
  --bs-contact-icon-bg: var(--bs-body-color-style-2);
}

[data-bs-theme="dark"] .progress {
  --bs-progress-bg: #696194;
  --bs-progress-bar-bg: var(--bs-white);
}

[data-bs-theme="dark"] .section-header {
  --bs-header-box-shadow: 0 2px 10px rgba(var(--bs-white-rgb), .05);
}

[data-bs-theme="dark"] .header-2 {
  --bs-header-bg: var(--bs-body-bg);
}

[data-bs-theme="dark"] .check-circle-icon {
  --bs-check-icon-stroke-color: #EFEFF7;
  --bs-check-icon-color: var(--bs-body-bg);
}

[data-bs-theme="dark"] .btn-primary {
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

[data-bs-theme="dark"] .btn-primary:hover .arrow-icon-2 {
  --bs-arrow-icon-2-stroke-color: var(--bs-secondary);
}

[data-bs-theme="dark"] .btn-secondary {
  --bs-btn-bg: var(--bs-white);
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

[data-bs-theme="dark"] .navbar {
  --bs-navbar-color: var(--bs-white);
}

[data-bs-theme="dark"] .card {
  --bs-card-box-shadow: rgba(var(--bs-white-rgb), 0.08) 0px 4px 5px;
}

[data-bs-theme="dark"] .card.card-shadow-style-1 {
  --bs-card-box-shadow: rgba(var(--bs-white-rgb), 0.08) 0px 4px 5px;
}

[data-bs-theme="dark"] .card.card-shadow-style-2 {
  --bs-card-box-shadow: rgba(var(--bs-white-rgb), 0.08) 0px 4px 5px;
}

[data-bs-theme="dark"] .top-bar-contact-lists {
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  --bs-top-bar-icons-color: var(--bs-white);
}

@media (max-width: 1399.98px) {
  .display-3 {
    font-size: 3.2rem;
  }
  .display-6 {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  h3, .h3 {
    font-size: 38px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .4s ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: fit-content;
}

@media (max-width: 991.98px) {
  .btn {
    --bs-btn-font-size: 16px;
  }
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
}

.btn-outline-secondary {
  --bs-btn-bg: transparent;
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-body-color-style-2);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

.btn-secondary {
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-color: var(--bs-white);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

.btn-link {
  --bs-btn-padding-x:0;
  --bs-btn-padding-y:0;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: none;
  padding: 0 2px 2px 0;
}

.btn-link.link-hover-animation-1::before {
  bottom: 1px;
}

.btn-link:hover .btn-link-icon svg {
  transform: rotate(45deg);
}

.btn-link-style-2 {
  --bs-btn-padding-x:0;
  --bs-btn-padding-y:0;
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-bg: transparent;
  --bs-btn-font-size: 14px;
  --bs-btn-font-weight: 400;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: none;
  padding: 0 2px 2px 0;
}

.btn-link-style-2.link-hover-animation-1::before {
  bottom: 1px;
}

.btn-link-style-2:hover .chevron-down-icon {
  --bs-chevron-down-icon-stroke-color: var(--bs-link-hover-color);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-font-weight: 500;
  --bs-btn-font-size: 16px;
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.032rem;
  --bs-btn-padding-x: 2.022rem;
  --bs-btn-font-size: 18px;
}

.btn-reply {
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-padding-y: 0;
  --bs-btn-padding-x: 0;
}

.wp-block-tag-cloud {
  --bs-tag-cloud-bg: var(--bs-body-bg);
  --bs-link-color: var(--bs-body-color);
  --bs-link-color-rgb: var(--bs-body-color-rgb);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  transition: all 0.2s ease-in-out;
}

.wp-block-tag-cloud a {
  padding: 12px 20px;
  border-radius: 4px;
  background-color: var(--bs-tag-cloud-bg);
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0.1rem 0.95rem rgba(0, 0, 0, 0.06);
}

.wp-block-tag-cloud a:hover {
  --bs-tag-cloud-bg: var(--bs-primary);
}

.btn-arrow {
  --bs-btn-arrow-size: 60px;
  --bs-btn-arrow-bg: #A1B2C3;
  background-color: var(--bs-btn-arrow-bg);
  width: var(--bs-btn-arrow-size);
  height: var(--bs-btn-arrow-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  transition: transform .3s ease-in-out;
}

.btn-arrow:hover {
  transform: translateY(-2px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: rgba(var(--bs-primary-rgb), 0.4) 0px 3px 30px;
}

.btn-arrow:hover svg {
  transform: rotate(45deg);
}

.logistics-toggler-icon {
  --bs-logistics-toggler-icon-stroke-color: #253B2F;
}

.logistics-toggler-icon svg {
  stroke: var(--bs-logistics-toggler-icon-stroke-color);
}

@media (max-width: 991.98px) {
  .navbar-brand img {
    width: 165px;
  }
}

.section-header {
  background-color: var(--bs-header-bg);
  --bs-header-box-shadow: 0 4px 20px rgba(var(--bs-secondary-rgb), .065);
  transition: all .4s ease-in-out;
  box-shadow: var(--bs-header-box-shadow);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.section-header.header-tra {
  --bs-header-tra-navbar-color: var(--bs-white);
  --bs-header-tra-bg: transparent;
  --bs-header-tra-padding-top: 45px;
  --bs-header-bg: var(--bs-header-tra-bg);
  padding-top: var(--bs-header-tra-padding-top);
}

.section-header.header-tra .logistics-navbar {
  --bs-navbar-color: var(--bs-header-tra-navbar-color);
}

.section-header.header-tra .logistics-navbar .navbar-nav {
  --bs-nav-link-padding-y: 2rem;
}

@media (max-width: 1399.98px) {
  .section-header.header-tra .logistics-navbar .navbar-nav {
    --bs-nav-link-padding-y: 1.8rem;
  }
}

@media (max-width: 1199.98px) {
  .section-header.header-tra .logistics-navbar .navbar-nav {
    --bs-nav-link-padding-y: 1px;
  }
}

.section-header.header-tra .btn-primary {
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

.section-header.header-tra .header-icon {
  --bs-header-icon-bg: rgba(var(--bs-body-color-style-3-rgb), .15);
  --bs-header-icon-stroke-color: var(--bs-white);
}

.section-header.header-tra .logo-dark {
  display: none;
}

.section-header.header-tra .logistics-toggler-icon {
  --bs-logistics-toggler-icon-stroke-color: #fff;
}

@media (max-width: 1399.98px) {
  .section-header.header-tra {
    --bs-header-tra-padding-top: 30px;
  }
}

@media (max-width: 1199.98px) {
  .section-header.header-tra {
    --bs-header-tra-navbar-color: var(--bs-heading-color-style-1);
  }
  .section-header.header-tra .header-icon {
    --bs-header-icon-bg: var(--bs-body-color-style-3);
    --bs-header-icon-stroke-color: var(--bs-secondary);
  }
}

@media (max-width: 767.98px) {
  .section-header.header-tra {
    --bs-header-tra-padding-top: 16px;
  }
}

.section-header.sticky {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
  animation: header-fadeInDown .7s ease-in-out 0s 1 normal none running;
  transition: all .3s ease-in-out;
}

@keyframes header-fadeInDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.section-header.sticky .navbar-nav {
  --bs-nav-link-padding-y: 2rem;
}

@media (max-width: 1199.98px) {
  .section-header.sticky .navbar-nav {
    --bs-nav-link-padding-y: 1px;
  }
}

.section-header.sticky .top-bar {
  display: none;
}

.section-header.sticky.header-2 {
  padding: 0;
}

.section-header.sticky .logistics-navbar .logistics-navbar-inner {
  border-radius: 0 0 4px 4px;
  animation: fadeInDown .7s ease-in-out 0s 1 normal none running;
  transition: all .3s ease-in-out;
}

@media (min-width: 1200px) {
  .section-header.sticky .custom-align {
    --bs-custom-padding-bottom: 6px;
    padding-bottom: var(--bs-custom-padding-bottom);
  }
  .section-header.sticky .custom-align .dropdown .dropdown-menu.show {
    top: 100% !important;
  }
}

.section-header.sticky.header-tra {
  --bs-header-tra-navbar-color: var(--bs-heading-color-style-1);
  --bs-header-tra-bg: var(--bs-body-bg);
  --bs-header-tra-padding-top: 0;
}

.section-header.sticky.header-tra .header-icon {
  --bs-header-icon-bg: var(--bs-body-color-style-3);
  --bs-header-icon-stroke-color: var(--bs-secondary);
}

.section-header.sticky.header-tra .logo-dark {
  display: block;
}

.section-header.sticky.header-tra .logo-white {
  display: none;
}

.section-header.sticky.header-tra .logistics-toggler-icon {
  --bs-logistics-toggler-icon-stroke-color: #253B2F;
}

.section-header.sticky.header-tra .btn-primary {
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
}

.header-icon {
  --bs-header-icon-bg: var(--bs-body-color-style-3);
  --bs-header-icon-size: 50px;
  --bs-header-icon-stroke-color: var(--bs-secondary);
  width: var(--bs-header-icon-size);
  height: var(--bs-header-icon-size);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-header-icon-bg);
}

.header-icon svg {
  transition: all .3s ease-in-out;
}

.header-icon:hover {
  --bs-header-icon-stroke-color: var(--bs-primary) !important;
}

.header-1 {
  --bs-header-bg: var(--bs-bg-custom-light);
}

.header-2 {
  --bs-header-bg: var(--bs-body-bg);
  padding: 20px 0 40px 0;
}

@media (max-width: 991.98px) {
  .header-2 {
    padding: 0;
  }
}

.logistics-navbar {
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: var(--bs-heading-color-style-1);
  --bs-navbar-hover-color: var(--bs-primary);
  --bs-navbar-active-color: var(--bs-primary);
}

.logistics-navbar .nav-link {
  position: relative;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.logistics-navbar .nav-link:hover, .logistics-navbar .nav-link.show {
  --bs-nav-link-font-weight: 500;
}

.logistics-navbar .nav-link:hover .dropdown-icon, .logistics-navbar .nav-link.show .dropdown-icon {
  --bs-dropdown-icon-stroke-color: var(--bs-navbar-active-color);
}

.logistics-navbar .nav-link.active {
  --bs-nav-link-font-weight: 500;
}

.logistics-navbar .nav-link.active .dropdown-icon {
  --bs-dropdown-icon-stroke-color: var(--bs-navbar-active-color);
}

.logistics-navbar .nav-link.active .nav-title {
  position: relative;
}

.logistics-navbar .nav-link.active .nav-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background: var(--bs-navbar-active-color);
}

.logistics-navbar.custom-align {
  --bs-custom-spacing: 5px;
}

@media (min-width: 1200px) {
  .logistics-navbar.custom-align .offcanvas-body {
    position: relative;
    top: var(--bs-custom-spacing);
  }
  .logistics-navbar.custom-align .offcanvas-body .dropdown .dropdown-menu.show {
    top: calc(100% - var(--bs-custom-spacing));
  }
}

.logistics-navbar .navbar-nav {
  --bs-nav-link-padding-y: 2.9384rem;
}

@media (max-width: 1399.98px) {
  .logistics-navbar .navbar-nav {
    --bs-nav-link-padding-y: 2.2rem;
  }
}

@media (max-width: 1199.98px) {
  .logistics-navbar .navbar-nav {
    --bs-nav-link-padding-y: 1px;
  }
}

.logistics-navbar .logistics-navbar-inner {
  --bs-logistics-navbar-inner-bg: var(--bs-primary);
  --bs-navbar-color: #D9F8FF;
  --bs-navbar-hover-color: var(--bs-white);
  --bs-navbar-active-color: var(--bs-white);
  background-color: var(--bs-logistics-navbar-inner-bg);
  border-radius: 4px;
  padding: 0 40px;
  position: absolute;
  left: 0;
  z-index: 3;
  top: 0;
}

.logistics-navbar .logistics-navbar-inner .logistics-toggler-icon {
  --bs-logistics-toggler-icon-stroke-color: var(--bs-navbar-active-color);
}

.logistics-navbar .logistics-navbar-inner .dropdown-icon {
  --bs-dropdown-icon-stroke-color: var(--bs-navbar-color);
  transition: all 0.2s ease-in-out;
}

.logistics-navbar .logistics-navbar-inner .nav-link:hover, .logistics-navbar .logistics-navbar-inner .nav-link.show, .logistics-navbar .logistics-navbar-inner .nav-link.active {
  --bs-nav-link-font-weight: 500;
}

.logistics-navbar .logistics-navbar-inner .nav-link:hover .dropdown-icon, .logistics-navbar .logistics-navbar-inner .nav-link.show .dropdown-icon, .logistics-navbar .logistics-navbar-inner .nav-link.active .dropdown-icon {
  --bs-dropdown-icon-stroke-color: var(--bs-navbar-active-color);
}

.logistics-navbar .logistics-navbar-inner .offcanvas {
  --bs-offcanvas-bg: var(--bs-primary);
}

.logistics-navbar .logistics-navbar-inner .btn-secondary {
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary);
}

.logistics-navbar .logistics-navbar-inner .navbar-nav {
  --bs-nav-link-padding-y: 1.8rem;
}

@media (max-width: 1199.98px) {
  .logistics-navbar .logistics-navbar-inner .navbar-nav {
    --bs-nav-link-padding-y: 1px;
  }
}

@media (max-width: 1199.98px) {
  .logistics-navbar .logistics-navbar-inner {
    padding: 0 16px;
  }
}

@media (max-width: 991.98px) {
  .logistics-navbar .logistics-navbar-inner {
    border-radius: 0 0 4px 4px;
  }
}

.logistics-navbar .logistics-navbar-inner .top-bar-contact-lists {
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  --bs-top-bar-icons-color: var(--bs-white);
}

.logistics-navbar .logistics-navbar-inner .link-hover-animation-1::before {
  background-color: var(--bs-white);
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .logistics-navbar .navbar-nav {
    --bs-nav-link-padding-y: 2rem;
  }
  .section-header.sticky .navbar-nav {
    --bs-nav-link-padding-y: 1.85rem;
  }
}

.top-bar .social-icons {
  --bs-social-icons-size: 24px;
}

.top-bar-contact-lists {
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-link-color: var(--bs-secondary);
  --bs-link-hover-color: var(--bs-secondary);
  --bs-link-hover-color-rgb: var(--bs-secondary-rgb);
  --bs-top-bar-icons-color: var(--bs-primary);
}

.top-bar-contact-lists .topbar-contact-icon {
  --bs-contact-icon-stroke-color: var(--bs-top-bar-icons-color);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-top-bar-icons-color);
}

.top-bar-contact-lists .topbar-contact-icon.icon-border {
  border: 1px solid var(--bs-top-bar-icons-color);
  border-radius: 50%;
}

.top-bar-contact-lists.contact-separator li {
  border-right: 1px solid #fff;
  padding-right: 20px;
}

.top-bar-contact-lists.contact-separator li:last-child {
  border-right: none;
  padding-right: 0;
}

@media (max-width: 991.98px) {
  .top-bar-contact-lists.contact-separator li {
    border-right: none;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .section-top-contents {
    max-width: 532px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .contents-width {
    max-width: 690px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .descriptions-width {
    max-width: 532px;
  }
}

.fs-base {
  font-size: 16px;
}

@media screen and (min-width: 1925px) {
  .max-width {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .lead {
    font-size: 18px;
  }
}

.shadow {
  box-shadow: 0 0.1rem 0.95rem rgba(0, 0, 0, 0.1) !important;
}

.font-family-style-1 {
  font-family: var(--bs-font-family-style-1);
}

.font-family-style-2 {
  font-family: var(--bs-font-family-style-2);
}

.facebook-icon-2 {
  color: #0866FF;
}

.priterest-icon {
  color: #E60019;
}

.linkedin-icon-2 {
  color: #0A66C2;
}

.star-big-icon {
  color: var(--bs-primary);
  transform: rotate(0deg);
  transition: transform 0.7s linear;
  animation: rotate 30s infinite linear;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.star-icon-1 {
  position: absolute;
  top: 0;
  right: 55px;
}

@media (max-width: 1199.98px) {
  .star-icon-1 svg {
    width: 110px;
  }
}

@media (max-width: 991.98px) {
  .star-icon-1 {
    right: -50px;
    top: -35px;
  }
}

@media (max-width: 767.98px) {
  .star-icon-1 svg {
    width: 85px;
  }
}

@media (max-width: 575.98px) {
  .star-icon-1 {
    right: -38px;
  }
  .star-icon-1 svg {
    width: 75px;
  }
}

.star-icon-2 {
  position: absolute;
  bottom: -35px;
  right: 36%;
}

@media (max-width: 1199.98px) {
  .star-icon-2 {
    bottom: -12px;
  }
  .star-icon-2 svg {
    width: 80px;
  }
}

@media (max-width: 991.98px) {
  .star-icon-2 {
    bottom: -100px;
    right: 15%;
  }
}

@media (max-width: 991.98px) {
  .star-icon-2 {
    bottom: -60px;
    right: 16%;
  }
  .star-icon-2 svg {
    width: 65px;
  }
}

@media (max-width: 575.98px) {
  .star-icon-2 {
    bottom: -40px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1680px) {
  .star-icon-1 {
    right: 90px;
  }
}

.body-color-style-1 {
  color: var(--bs-body-color-style-1);
}

.body-color-style-2 {
  color: var(--bs-body-color-style-2);
}

.body-color-style-3 {
  color: var(--bs-body-color-style-3);
}

.bg-custom-light {
  background-color: var(--bs-bg-custom-light);
}

.extra-small {
  font-size: 12px;
}

svg {
  fill: currentColor;
}

.arrow-reverse svg {
  transform: rotate(180deg);
}

.sticky-contents {
  position: sticky;
  top: 120px;
}

.dropdown-icon {
  display: inline-flex;
  position: relative;
  transition: transform .4s ease-in-out;
  --bs-dropdown-icon-stroke-color: var(--bs-navbar-color);
}

.dropdown-icon svg {
  stroke: var(--bs-dropdown-icon-stroke-color);
}

.location-icon {
  --bs-location-icon-color: var(--bs-body-color);
  color: var(--bs-location-icon-color);
}

.message-icon-2 {
  --bs-message-icon-color: var(--bs-primary);
  color: var(--bs-message-icon-color);
}

.freight-icon {
  --bs-freight-icon-bg: var(--bs-secondary);
  --bs-freight-icon-color: var(--bs-white);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bs-freight-icon-bg);
  color: var(--bs-freight-icon-color);
  transition: all .5s ease-in-out;
}

.quote-icon {
  --bs-quote-icon-bg: #d9d9d9;
  --bs-quote-icon-color: var(--bs-white);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bs-quote-icon-bg);
  color: var(--bs-quote-icon-color);
  transition: all .5s ease-in-out;
}

.mail-icon, .location-icon, .phone-icon {
  --bs-contact-icon-stroke-color: var(--bs-white);
}

.mail-icon svg, .location-icon svg, .phone-icon svg {
  stroke: var(--bs-contact-icon-stroke-color);
}

.chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-body-color-style-1);
}

.chevron-right-icon svg {
  stroke: var(--bs-chevron-right-icon-stroke-color);
  position: relative;
  top: -1px;
  transition: all 0.2s ease-in-out;
}

.chevron-down-icon {
  --bs-chevron-down-icon-stroke-color: var(--bs-body-color);
}

.chevron-down-icon svg {
  stroke: var(--bs-chevron-down-icon-stroke-color);
  transition: all 0.2s ease-in-out;
}

.calender-icon {
  --bs-calender-icon-stroke-color: var(--bs-body-color);
}

.calender-icon svg {
  stroke: var(--bs-calender-icon-stroke-color);
  position: relative;
  top: -2px;
}

.calender-icon.calender-icon-2 {
  --bs-calender-icon-stroke-color: var(--bs-primary);
}

.instagram-icon-lg {
  --bs-instagram-icon-lg-stroke-color: var(--bs-primary);
}

.instagram-icon-lg svg {
  stroke: var(--bs-instagram-icon-lg-stroke-color);
}

.search-icon-2 svg {
  stroke: var(--bs-header-icon-stroke-color);
}

.user-icon svg {
  stroke: var(--bs-header-icon-stroke-color);
}

.star-rating-icon {
  --bs-star-rating-icon-color: #F8B404;
  color: var(--bs-star-rating-icon-color);
}

.check-icon {
  --bs-check-icon-stroke-color: var(--bs-primary);
}

.check-icon svg {
  stroke: var(--bs-check-icon-stroke-color);
}

.globe-icon {
  --bs-globe-icon-stroke-color: var(--bs-primary);
}

.globe-icon svg {
  stroke: var(--bs-globe-icon-stroke-color);
}

.arrow-icon-2 {
  --bs-arrow-icon-2-stroke-color: var(--bs-white);
}

.arrow-icon-2 svg {
  stroke: var(--bs-arrow-icon-2-stroke-color);
  transition: all 0.2s ease-in-out;
  position: relative;
  top: -1px;
}

.arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-white);
  --bs-arrow-style-1-bg: var(--bs-primary);
  --bs-arrow-style-1-size: 40px;
  --bs-arrow-style-1-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.arrow-icon-3 svg {
  stroke: var(--bs-arrow-icon-3-stroke-color);
  transition: all 0.2s ease-in-out;
}

.arrow-icon-3.arrow-style-1 {
  width: var(--bs-arrow-style-1-size);
  height: var(--bs-arrow-style-1-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-arrow-style-1-bg);
  border-radius: var(--bs-arrow-style-1-radius);
  transition: all 0.2s ease-in-out;
}

.arrow-icon-3:hover {
  --bs-arrow-style-1-bg: var(--bs-secondary);
}

.btn-link-icon {
  --bs-arrow-icon-2-stroke-color: var(--bs-primary);
  transition: transform .3s ease-in-out;
}

.btn-link-icon svg {
  stroke: var(--bs-arrow-icon-2-stroke-color);
  transition: all 0.2s ease-in-out;
}

.odometer-digit:last-child {
  padding-right: 2.5px;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  margin-top: 0 !important;
}

.odometer-pertial-text {
  position: relative;
  top: 1px;
}

.contact-icon {
  --bs-contact-icon-stroke-color: var(--bs-white);
  --bs-contact-icon-bg: var(--bs-primary);
  --bs-contact-icon-width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--bs-contact-icon-width);
  min-width: var(--bs-contact-icon-width);
  height: var(--bs-contact-icon-width);
  border-radius: 50%;
  background-color: var(--bs-contact-icon-bg);
}

.contact-icon svg {
  stroke: var(--bs-contact-icon-stroke-color);
}

.contact-icon.phone-icon-style-2 {
  --bs-contact-icon-bg: var(--bs-secondary);
  --bs-contact-icon-width: 50px;
}

.contact-about {
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-link-color: var(--bs-secondary);
  --bs-link-hover-color: var(--bs-secondary);
  --bs-link-hover-color-rgb: var(--bs-secondary-rgb);
}

.contact-about .contact-icon {
  --bs-contact-icon-bg: var(--bs-secondary);
  --bs-contact-icon-width: 50px;
}

.dropdown .dropdown-menu.show {
  transition: all .4s ease-in-out;
  --bs-border-radius: 0 0 10px 10px;
  --bs-dropdown-spacer: 0;
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-width: 0;
  --bs-dropdown-color: var(--bs-heading-color-style-1);
  --bs-dropdown-link-color: var(--bs-heading-color-style-1);
  --bs-dropdown-link-hover-color: var(--bs-primary);
  --bs-dropdown-link-active-color: var(--bs-primary);
  --bs-dropdown-link-hover-bg: var(--bs-dropdown-bg);
  --bs-dropdown-link-active-bg: var(--bs-dropdown-bg);
  --bs-dropdown-item-padding-x: 20px;
  --bs-dropdown-item-padding-y: 8px;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-font-size: 14px;
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-item-separator-color: var(--bs-dropdown-link-color);
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
  overflow: hidden;
}

.dropdown .dropdown-menu.show .dropdown-item {
  --bs-dropdown-item-bg: rgba(235, 235, 239, .6);
  position: relative;
}

.dropdown .dropdown-menu.show .dropdown-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 0;
  background: var(--bs-primary);
  transition: height .4s,opacity .4s ease,top .4s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.dropdown .dropdown-menu.show .dropdown-item:hover {
  background-color: var(--bs-dropdown-item-bg);
}

.dropdown .dropdown-menu.show .dropdown-item:hover::before {
  height: calc(100% + 1px);
  opacity: 1;
  visibility: visible;
  top: 0;
  left: -1px;
}

.dropdown .dropdown-menu.show.dropdown-menu-lg {
  --bs-dropdown-min-width: 26rem;
  border-top: 3px solid var(--bs-primary);
}

.dropdown .dropdown-menu.show.dropdown-menu-lg .dropdown-item {
  --bs-dropdown-item-bg: transparent;
  border-bottom: 1px solid var(--bs-body-color-style-3);
}

.dropdown .dropdown-menu.show.dropdown-menu-lg .dropdown-item:last-child {
  border-bottom: none;
}

.dropdown .dropdown-menu.show.dropdown-menu-lg .dropdown-item::before {
  content: none;
}

.dropdown .dropdown-menu.show li {
  border-bottom: 1px solid var(--bs-body-color-style-3);
}

.dropdown .dropdown-menu.show li:last-child {
  border-bottom: none;
}

@media (max-width: 1199.98px) {
  .dropdown .dropdown-menu.show {
    --bs-dropdown-item-padding-x: 20px;
  }
  .dropdown .dropdown-menu.show.dropdown-menu-lg {
    --bs-dropdown-min-width: 15.6rem;
  }
}

.dropdown .submenu .dropdown-menu.show {
  left: 99.8%;
  top: 1rem;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.dropdown .dropdown-item {
  font-weight: 500;
  font-family: var(--bs-font-family-style-1);
  display: inline-flex;
  align-items: center;
  gap: 40px;
}

.dropdown .dropdown-item span {
  transition: all 0.2s ease-in-out;
}

.dropdown .dropdown-item:hover span {
  padding-left: 8px;
}

.separator {
  --bs-separator-padding-bottom: 0;
  --bs-separator-margin-bottom: 0;
  position: relative;
  padding-bottom: var(--bs-separator-padding-bottom);
  margin-bottom: var(--bs-separator-margin-bottom);
  --bs-separator-width: 2px;
  --bs-separator-height: 90%;
  --bs-separator-color: var(--bs-body-color-style-3);
}

.separator::after {
  content: "";
  position: absolute;
  top: 5%;
  left: 50%;
  width: var(--bs-separator-width);
  height: var(--bs-separator-height);
  background: var(--bs-separator-color);
}

.btn-close:focus {
  box-shadow: none;
}

.link-hover-animation-1 {
  position: relative;
  text-decoration: none;
}

.link-hover-animation-1::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--bs-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.link-hover-animation-1:hover::before {
  transform: scaleX(1);
}

.link-hover-animation-2 {
  --bs-link-hover-animation-color: var(--bs-primary);
  background: linear-gradient(to right, var(--bs-link-hover-animation-color) 0%, var(--bs-link-hover-animation-color) 100%);
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: 0px 2px;
  background-repeat: no-repeat;
  background-position: left 100%;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  --bs-btn-border-radius: 0 !important;
}

.link-hover-animation-2:hover {
  background-size: 100% 2px;
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .link-hover-animation-1:hover {
    background-size: 100% 2.1px;
  }
}

.brand-2 {
  background-image: url("../images/brand-2-bg.jpg");
  padding: 100px 0;
}

.brand-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-secondary-rgb), 0.9);
}

.brand-2 img {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .brand-2 {
    padding: 60px 0;
  }
}

.brand-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-body-bg);
  border: 2px solid var(--bs-brands-border-color);
  height: 184px;
  transition: all 0.2s ease-in-out;
}

.brand-item:hover {
  --bs-brands-border-color: var(--bs-primary);
}

@media screen and (min-width: 575px) and (max-width: 768px) {
  .brand-item img {
    max-width: 180px;
  }
}

@media screen and (min-width: 768.1px) and (max-width: 991.9px) {
  .brand-item img {
    max-width: 250px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .brand-item img {
    max-width: 266px;
  }
}

.offcanvas .social-icons {
  --bs-social-icon-border-color: transparent;
  --bs-social-icon-bg: var(--bs-secondary);
}

.offcanvas .top-bar-contact-lists {
  --bs-top-bar-icons-color: var(--bs-secondary);
}

.social-icons {
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  --bs-social-icons-size: 40px;
  --bs-social-icons-margin-top: -2px;
  --bs-social-icon-border-color: var(--bs-white);
  --bs-social-icon-border-width: 1px;
  --bs-social-icon-bg: transparent;
}

.social-icons a {
  width: var(--bs-social-icons-size);
  height: var(--bs-social-icons-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--bs-social-icon-border-width) solid var(--bs-social-icon-border-color);
  border-radius: 50%;
  background: var(--bs-social-icon-bg);
  transition: all 0.2s ease-in-out;
  position: relative;
  top: 0;
}

.social-icons a span {
  margin-top: var(--bs-social-icons-margin-top);
}

.social-icons a:hover {
  background: var(--bs-primary);
  --bs-social-icon-border-color: var(--bs-primary);
  top: -2px;
}

.social-icons.social-icons-style-2 {
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  --bs-social-icon-border-color: var(--bs-primary);
  --bs-social-icon-border-width: 3px;
  --bs-social-icon-bg: var(--bs-white);
}

.social-icons.social-icons-style-2 a:hover {
  background: var(--bs-primary);
  --bs-social-icon-border-color: var(--bs-white);
  top: -2px;
}

.social-icons.social-icons-style-3 {
  --bs-social-icons-size: 22px;
  --bs-link-color-rgb: var(--bs-black-rgb);
  --bs-link-color: var(--bs-black);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
  --bs-social-icons-margin-top: -3px;
  --bs-social-icon-border-color: var(--bs-black);
  --bs-social-icon-bg: var(--bs-white);
}

.social-icons.social-icons-style-3 svg {
  width: 11px;
  height: 11px;
}

.social-icons.social-icons-style-3 a:hover {
  background: var(--bs-primary);
  --bs-social-icon-border-color: var(--bs-primary);
  top: 0;
}

.parallax {
  background-image: var(--bg-cover-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.parallax:after {
  content: "";
  background-image: var(--bg-parallax-image);
  opacity: var(--bg-parallax-opacity, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.border-10 {
  border-radius: 10px;
}

.border-10 img {
  border-radius: 10px;
  transition: transform .3s ease-in-out;
}

.border-8 {
  border-radius: 8px;
}

.border-8 img {
  border-radius: 8px;
  transition: transform .3s ease-in-out;
}

.odometer-wrapper {
  --bs-odometer-wrapper-width: 863px;
  --bs-odometer-heading-fs: 64px;
  --bs-odometer-description-fs: 28px;
  background-image: url("../images/brand-2-bg.jpg");
  padding: 24px 40px;
  position: relative;
  width: var(--bs-odometer-wrapper-width);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
}

.odometer-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-secondary-rgb), 0.9);
  border-radius: 0 0 10px 10px;
}

.odometer-wrapper .about-service {
  width: fit-content;
  height: 100%;
  --bs-about-service-text-color: var(--bs-white);
}

.odometer-wrapper .odometer-description {
  font-size: var(--bs-odometer-description-fs);
}

.odometer-wrapper .odometer-heading {
  font-size: var(--bs-odometer-heading-fs);
}

@media (max-width: 1399.98px) {
  .odometer-wrapper {
    --bs-odometer-wrapper-width: 744px;
    --bs-odometer-heading-fs: 60px;
    --bs-odometer-description-fs: 24px;
  }
}

@media (max-width: 1199.98px) {
  .odometer-wrapper {
    --bs-odometer-heading-fs: 50px;
    --bs-odometer-description-fs: 22px;
    position: relative;
    flex-wrap: wrap;
    width: auto;
    gap: 20px;
  }
  .odometer-wrapper .about-service {
    align-items: start !important;
  }
}

@media (max-width: 991.98px) {
  .odometer-wrapper {
    --bs-odometer-heading-fs: 45px;
    --bs-odometer-description-fs: 20px;
    padding: 24px;
    gap: 30px;
  }
}

.about-service {
  width: 164px;
  height: 120px;
  --bs-about-service-text-color: var(--bs-secondary);
  position: relative;
  z-index: 1;
}

.about-service p {
  color: var(--bs-about-service-text-color);
}

@media (max-width: 1399.98px) {
  .about-service {
    width: 170px;
    height: 102px;
  }
}

@media (max-width: 1199.98px) {
  .about-service {
    width: 230px;
    height: 150px;
  }
}

@media (max-width: 991.98px) {
  .about-service {
    width: 208px;
    height: 135px;
  }
}

@media (max-width: 767.98px) {
  .about-service {
    width: 155px;
    height: 130px;
  }
}

.support-image-wrapper {
  --bs-support-inner-image-1-width: 410px;
  --bs-support-inner-image-1-height: 530px;
  --bs-support-inner-image-2-width: 310px;
  --bs-support-inner-image-2-height: 456px;
}

.support-image-wrapper .support-inner-image-1 {
  max-width: var(--bs-support-inner-image-1-width);
  height: var(--bs-support-inner-image-1-height);
}

.support-image-wrapper .support-inner-image-1 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.support-image-wrapper .support-inner-image-2 {
  border: 6px solid #fff;
  border-radius: 10px;
  max-width: var(--bs-support-inner-image-2-width);
  height: var(--bs-support-inner-image-2-height);
  position: absolute;
  right: 0;
  top: calc(50% - (var(--bs-support-inner-image-2-height) * .5 ));
}

.support-image-wrapper .support-inner-image-2 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 6px;
}

@media (max-width: 1399.98px) {
  .support-image-wrapper {
    --bs-support-inner-image-1-width: 370px;
    --bs-support-inner-image-1-height: 500px;
    --bs-support-inner-image-2-width: 300px;
    --bs-support-inner-image-2-height: 420px;
  }
}

@media (max-width: 1199.98px) {
  .support-image-wrapper {
    --bs-support-inner-image-1-width: 300px;
    --bs-support-inner-image-1-height: 450px;
    --bs-support-inner-image-2-width: 250px;
    --bs-support-inner-image-2-height: 370px;
  }
}

@media (max-width: 991.98px) {
  .support-image-wrapper {
    --bs-support-inner-image-1-width: 80%;
    --bs-support-inner-image-2-width: 70%;
  }
}

.support-image-wrapper.support-image-wrapper-style-1 {
  overflow: hidden;
  height: 555px;
}

.support-image-wrapper.support-image-wrapper-style-1 img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1199.98px) {
  .support-image-wrapper.support-image-wrapper-style-1 {
    height: 350px;
  }
}

.about-image-1 {
  height: 100%;
}

.about-image-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image-2 {
  height: 387px;
}

.about-image-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image-3 {
  height: 500px;
}

.about-image-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .about-image-3 {
    height: 400px;
  }
}

.about-image-4 {
  --bs-about-image-4-height: 640px;
  height: var(--bs-about-image-4-height);
  min-height: var(--bs-about-image-4-height);
}

.about-image-4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1399.98px) {
  .about-image-4 {
    --bs-about-image-4-height: 626px;
  }
}

@media (max-width: 1199.98px) {
  .about-image-4 {
    --bs-about-image-4-height: 630px;
  }
}

@media (max-width: 991.98px) {
  .about-image-4 {
    --bs-about-image-4-height: 592px;
  }
}

@media (max-width: 767.98px) {
  .about-image-4 {
    --bs-about-image-4-height: 430px;
  }
}

@media (max-width: 575.98px) {
  .about-image-4 {
    --bs-about-image-4-height: 370px;
  }
}

.about-image-5 {
  --bs-about-image-5-height: 454px;
  height: var(--bs-about-image-5-height);
  min-height: var(--bs-about-image-5-height);
}

.about-image-5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image-5.border-10 {
  border-radius: 10px 10px 0 0;
}

.about-image-5.border-10 img {
  border-radius: 10px 10px 0 0;
}

@media (max-width: 1199.98px) {
  .about-image-5 {
    --bs-about-image-5-height: 344px;
  }
}

@media (max-width: 991.98px) {
  .about-image-5 {
    --bs-about-image-5-height: 317px;
  }
}

.about-experience, .about-experience-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 30px 20px;
  background-color: var(--bs-body-bg);
}

.about-experience li:last-child, .about-experience-2 li:last-child {
  padding-left: 10px;
  border-left: 3px solid var(--bs-heading-color);
  max-width: 210px;
}

@media (max-width: 1399.98px) {
  .about-experience, .about-experience-2 {
    padding: 30px 16px;
  }
}

@media (max-width: 991.98px) {
  .about-experience, .about-experience-2 {
    padding: 30px 16px 30px 50px;
  }
}

.about-image-wrapper {
  --bs-about-image-wrapper-width: 300px;
  position: relative;
}

.about-image-wrapper .about-experience {
  position: absolute;
  top: 40px;
  right: -26%;
}

@media (max-width: 1399.98px) {
  .about-image-wrapper .about-experience {
    right: -7%;
    padding: 30px 16px 30px 40px;
    max-width: var(--bs-about-image-wrapper-width);
  }
}

@media (max-width: 1199.98px) {
  .about-image-wrapper .about-experience {
    right: -5%;
  }
}

@media (max-width: 991.98px) {
  .about-image-wrapper .about-experience {
    right: -4%;
  }
}

@media (max-width: 575.98px) {
  .about-image-wrapper .about-experience {
    right: calc(50% - (var(--bs-about-image-wrapper-width) * .5));
    top: -25px;
    padding: 20px 16px 20px 40px;
  }
}

.about-image-wrapper .about-experience-2 {
  position: absolute;
  top: 40px;
  left: -26%;
}

@media (max-width: 1399.98px) {
  .about-image-wrapper .about-experience-2 {
    left: -7%;
    padding: 30px 16px 30px 40px;
    max-width: var(--bs-about-image-wrapper-width);
  }
}

@media (max-width: 1199.98px) {
  .about-image-wrapper .about-experience-2 {
    left: -5%;
  }
}

@media (max-width: 991.98px) {
  .about-image-wrapper .about-experience-2 {
    left: -4%;
  }
}

@media (max-width: 575.98px) {
  .about-image-wrapper .about-experience-2 {
    left: calc(50% - (var(--bs-about-image-wrapper-width) * .5));
    top: -25px;
    padding: 20px 16px 20px 40px;
  }
}

.about-video-image {
  position: absolute;
  bottom: -60px;
  right: -50px;
  border: 2px solid var(--bs-body-bg);
  box-shadow: 0 0.1rem 0.9rem rgba(0, 0, 0, 0.2);
}

.about-video-image .video-image-wrapper {
  width: 235px;
  height: 170px;
}

.about-video-image .video-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-video-image .video-image-wrapper::before {
  content: none;
}

@media (max-width: 1399.98px) {
  .about-video-image {
    right: -22px;
    bottom: -38px;
  }
}

.about-3 .about-image-wrapper {
  max-width: 675px;
  height: 520px;
}

@media (max-width: 1199.98px) {
  .about-3 .about-image-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .about-3 .about-image-wrapper {
    height: 400px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1499px) {
  .about-image-wrapper .about-experience {
    right: -6%;
  }
  .about-image-wrapper .about-experience-2 {
    left: -6%;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .about-image-wrapper .about-experience {
    right: -10%;
  }
  .about-image-wrapper .about-experience-2 {
    left: -10%;
  }
}

.custom-border-left {
  padding-left: 20px;
  position: relative;
}

.custom-border-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: var(--bs-primary);
  border-radius: 20px;
}

@media (max-width: 575.98px) {
  .custom-border-left {
    padding-left: 12px;
  }
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--bs-secondary);
}

.video-popup {
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  --bs-video-icon-margin-left: 5px;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: calc(50% - (var(--bs-video-popup-area-size)) *.5);
  left: calc(50% - (var(--bs-video-popup-area-size)) *.5);
  background: var(--bs-video-icon-bg);
  border-radius: 50%;
  width: var(--bs-video-popup-area-size);
  height: var(--bs-video-popup-area-size);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  animation: pulse 1.5s linear infinite;
}

.video-popup.video-popup-style-1 {
  --bs-video-popup-area-size: 70px;
  --bs-video-icon-bg: var(--bs-white);
}

.video-popup.video-popup-style-2 {
  --bs-video-popup-area-size: 50px;
  --bs-video-icon-margin-left: 3px;
  --bs-video-icon-bg: var(--bs-primary);
  color: var(--bs-white);
}

.video-popup.video-popup-style-2 svg {
  width: 21px;
  height: 16px;
}

.video-popup.video-popup-style-3 {
  --bs-video-popup-area-size: 50px;
  --bs-video-icon-margin-left: 3px;
  --bs-video-icon-bg: var(--bs-white);
  color: var(--bs-primary);
}

.video-popup.video-popup-style-3 svg {
  width: 21px;
  height: 16px;
}

.video-popup.video-popup-style-3:hover {
  --bs-video-icon-bg: var(--bs-primary);
  color: var(--bs-white);
}

.video-popup .video-icon {
  margin-left: var(--bs-video-icon-margin-left);
}

.video-popup.custom-pulse-animation {
  animation: pulse-style-2 2s linear infinite;
}

.video-image-wrapper {
  width: 100%;
  height: 358px;
}

.video-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1399.98px) {
  .video-image-wrapper {
    height: 372px;
  }
}

@media (max-width: 1199.98px) {
  .video-image-wrapper {
    height: 268px;
  }
}

.video-image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.video-image-wrapper.video-image-sm {
  height: 288px;
}

@media (max-width: 1399.98px) {
  .video-image-wrapper.video-image-sm {
    height: 295px;
  }
}

@media (max-width: 1199.98px) {
  .video-image-wrapper.video-image-sm {
    height: 265px;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-style-2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.portfolio-image {
  --bs-portfolio-image-height: 300px;
  height: var(--bs-portfolio-image-height);
  min-height: var(--bs-portfolio-image-height);
  overflow: hidden;
}

.portfolio-image.portfolio-image-md {
  --bs-portfolio-image-height: 340px;
}

.portfolio-image.portfolio-image-lg {
  --bs-portfolio-image-height: 400px;
}

.portfolio-image.portfolio-image-lg img {
  transition: transform .4s ease-in-out;
}

.portfolio-image.portfolio-image-lg:hover img, .portfolio-image.portfolio-image-lg.active img {
  transform: scale(1.12);
}

.portfolio-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

.portfolio-image:hover img, .portfolio-image.active img {
  transform: scale(1.08);
}

.portfolio-image:hover .portfolio-image-hover, .portfolio-image.active .portfolio-image-hover {
  opacity: 1;
  bottom: 20px;
}

.portfolio-image:hover .portfolio-image-hover.portfolio-image-hover-2, .portfolio-image.active .portfolio-image-hover.portfolio-image-hover-2 {
  bottom: 0;
}

@media (max-width: 991.98px) {
  .portfolio-image.portfolio-image-2 {
    max-width: 350px;
  }
}

@media (max-width: 575.98px) {
  .portfolio-image.portfolio-image-2 {
    max-width: 100%;
  }
}

.portfolio-image-hover {
  --bs-portfolio-image-hover-width: 92%;
  --bs-portfolio-image-hover-bg: rgba(255, 255, 255, .9);
  --bs-portfolio-hover-heading-color: var(--bs-primary);
  --bs-ortfolio-hover-left-content-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 20px 30px;
  width: var(--bs-portfolio-image-hover-width);
  background-color: var(--bs-portfolio-image-hover-bg);
  position: absolute;
  left: calc(50% - (var(--bs-portfolio-image-hover-width) * .5));
  bottom: -100px;
  border-radius: 4px;
  opacity: 0;
  transition: all .4s ease-in-out;
}

.portfolio-image-hover .portfolio-hover-left-content {
  max-width: var(--bs-ortfolio-hover-left-content-width);
}

@media (max-width: 767.98px) {
  .portfolio-image-hover .portfolio-hover-left-content .portfolio-hover-heading {
    font-size: 20px;
  }
}

@media (max-width: 991.98px) {
  .portfolio-image-hover {
    padding: 20px;
  }
}

.portfolio-image-hover:hover .portfolio-subtitle {
  color: var(--bs-primary);
  border: 1px solid var(--bs-white);
  background-color: white;
}

.portfolio-image-hover:hover .arrow-icon-3 {
  --bs-arrow-style-1-bg: var(--bs-secondary);
}

.portfolio-image-hover:hover .arrow-icon-3 svg {
  animation: toLeftFromRight .6s alternate-reverse;
}

.portfolio-image-hover .portfolio-hover-heading {
  color: var(--bs-portfolio-hover-heading-color);
}

.portfolio-image-hover.portfolio-image-hover-2 {
  --bs-portfolio-image-hover-width: 100%;
  --bs-portfolio-image-hover-bg: var(--bs-primary);
  --bs-portfolio-hover-heading-color: var(--bs-white);
}

.portfolio-image-hover.portfolio-image-hover-2 .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-primary);
  --bs-arrow-style-1-bg: var(--bs-white);
}

@media (min-width: 1400px) {
  .portfolio-image-hover.portfolio-image-hover-2 {
    --bs-ortfolio-hover-left-content-width: 100%;
  }
}

.portfolio-image-hover .portfolio-subtitle {
  color: var(--bs-white);
  border: 1px solid var(--bs-white);
  background-color: transparent;
  border-radius: 11px;
  padding: 0 10px;
  width: fit-content;
  margin-bottom: 5px !important;
  font-size: 16px;
  transition: all .4s ease-in-out;
  display: inline-block;
}

.portfolio-subtitle-style-2 {
  --bs-link-color: var(--bs-body-color);
  --bs-link-color-rgb: var(--bs-body-color-rgb);
  --bs-link-hover-color: var(--bs-secondary);
  --bs-link-hover-color-rgb: var(--bs-secondary-rgb);
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .portfolio-hover-heading {
    font-size: 20px;
  }
  .portfolio-image-hover {
    padding: 20px;
  }
}

@keyframes toLeftFromRight {
  49% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  51% {
    opacity: 1;
  }
}

.portfolio-button {
  --bs-portfolio-button-color: var(--bs-primary);
  --bs-portfolio-button-bg: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 32px 0;
  width: 100%;
  border: 1px solid var(--bs-primary);
  background-color: var(--bs-portfolio-button-bg);
  transition: all .4s ease-in-out;
}

.portfolio-button .portfolio-btn-title {
  color: var(--bs-portfolio-button-color);
  position: relative;
}

.portfolio-button .portfolio-btn-title::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--bs-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.portfolio-button .portfolio-freight-icon {
  color: var(--bs-portfolio-button-color);
  transition: all .4s ease-in-out;
}

.portfolio-button.active {
  --bs-portfolio-button-color: var(--bs-white);
  --bs-portfolio-button-bg: var(--bs-primary);
}

.portfolio-button:hover .portfolio-btn-title::before {
  transform: scaleX(1);
}

@media (max-width: 1199.98px) {
  .portfolio-button {
    gap: 14px;
    padding: 24px 5px;
  }
  .portfolio-button .portfolio-btn-title {
    font-size: 20px;
  }
}

.team-wrapper {
  height: 420px;
  min-height: 420px;
  overflow: hidden;
}

.team-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

.team-wrapper:hover img, .team-wrapper.active img {
  transform: scale(1.03);
}

.team-wrapper:hover .team-image-hover, .team-wrapper.active .team-image-hover {
  opacity: 1;
  bottom: 10px;
}

.team-wrapper.custom-active {
  --bs-heading-color: #fff;
}

.team-wrapper.custom-active .team-image-hover {
  --bs-portfolio-image-hover-width: 82%;
  bottom: calc(50% - 120px);
  background-color: transparent;
  opacity: 1;
  z-index: 1;
}

.team-wrapper.custom-active .team-image-text {
  color: #fff;
  max-width: 240px;
}

.team-wrapper.custom-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--bs-secondary-rgb), 0.8);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.team-wrapper.custom-active .btn-primary {
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

.team-image-hover {
  --bs-team-image-hover-width: 95%;
  --bs-team-image-hover-bg: rgba(255, 255, 255, .9);
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 20px 30px;
  width: var(--bs-team-image-hover-width);
  background-color: var(--bs-team-image-hover-bg);
  position: absolute;
  left: calc(50% - (var(--bs-team-image-hover-width) * .5));
  bottom: -100px;
  border-radius: 4px;
  opacity: 0;
  transition: all .4s ease-in-out;
}

@media (max-width: 1199.98px) {
  .team-image-hover {
    padding: 20px;
  }
}

.team-details {
  --bs-team-details-spacing: 80px;
  border-radius: 10px;
  width: calc(100% - var(--bs-team-details-spacing));
  margin-top: var(--bs-team-details-spacing);
  margin-left: auto;
}

@media (max-width: 1199.98px) {
  .team-details {
    --bs-team-details-spacing: 60px;
  }
}

@media (max-width: 991.98px) {
  .team-details {
    --bs-team-details-spacing: 40px;
  }
}

@media (max-width: 575.98px) {
  .team-details {
    --bs-team-details-spacing: 24px;
  }
}

.team-details-inner {
  padding: 40px 40px 40px 0;
  --bs-link-color-rgb: var(--bs-body-color-rgb);
  --bs-link-color: var(--bs-body-color);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
}

@media (max-width: 1399.98px) {
  .team-details-inner {
    padding: 40px 0 40px 0;
  }
}

@media (max-width: 1199.98px) {
  .team-details-inner {
    padding: 40px 40px 40px 0;
  }
}

@media (max-width: 991.98px) {
  .team-details-inner {
    padding: 0 40px 40px 40px;
  }
}

@media (max-width: 575.98px) {
  .team-details-inner {
    padding: 0 20px 20px 20px;
  }
}

.team-details-inner .social-icons {
  --bs-social-icons-size: 30px;
  --bs-social-icon-border-width: 0;
  --bs-social-icon-bg: var(--bs-primary);
}

.team-single-image {
  --bs-team-single-image-height:   512px;
  --bs-team-single-image-width:    480px;
  border: 3px solid var(--bs-white);
  border-radius: 10px;
  position: relative;
  left: calc(-1 * var(--bs-team-details-spacing));
  top: calc(-1 * var(--bs-team-details-spacing));
  height: var(--bs-team-single-image-height);
  min-height: var(--bs-team-single-image-height);
  width: var(--bs-team-single-image-width);
  max-width: var(--bs-team-single-image-width);
}

.team-single-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .team-single-image {
    --bs-team-single-image-height:   450px;
    --bs-team-single-image-width:    400px;
  }
}

@media (max-width: 575.98px) {
  .team-single-image {
    --bs-team-single-image-height:   250px;
    --bs-team-single-image-width:    280px;
  }
}

.bio-title {
  border-bottom: 2px solid var(--bs-border-color-style-1);
}

.blogSwiper-buttons {
  --bs-blogSwiper-button-size: 40px;
  --bs-blog-arrow-icon-stroke-color: var(--bs-primary);
  transition: all 0.2s ease-in-out;
}

.blogSwiper-buttons .blog-arrow-icon {
  overflow: hidden;
}

.blogSwiper-buttons .blog-arrow-icon svg {
  stroke: var(--bs-blog-arrow-icon-stroke-color);
  transition: all 0.2s ease-in-out;
}

.blogSwiper-buttons .blogSwiper-button-prev, .blogSwiper-buttons .blogSwiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--bs-blogSwiper-button-size);
  height: var(--bs-blogSwiper-button-size);
  border: 1px solid var(--bs-primary);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}

.blogSwiper-buttons .blogSwiper-button-prev:hover, .blogSwiper-buttons .blogSwiper-button-next:hover {
  background-color: var(--bs-primary);
  --bs-blog-arrow-icon-stroke-color: var(--bs-white);
}

.blogSwiper-buttons.blogSwiper-buttons-style-2 {
  --bs-blogSwiper-button-size: 60px;
  --bs-blog-arrow-icon-stroke-color: var(--bs-white);
}

.blogSwiper-buttons.blogSwiper-buttons-style-2 .blogSwiper-button-prev, .blogSwiper-buttons.blogSwiper-buttons-style-2 .blogSwiper-button-next {
  border-radius: 4px;
  background-color: var(--bs-secondary);
  border: 0;
}

.blogSwiper-buttons.blogSwiper-buttons-style-2 .blogSwiper-button-prev:hover, .blogSwiper-buttons.blogSwiper-buttons-style-2 .blogSwiper-button-next:hover {
  background-color: var(--bs-primary);
}

.blog-arrow-style-2 {
  transform: rotate(45deg);
}

.delivery-wrapper {
  background-color: rgba(var(--bs-secondary-rgb), 1);
  position: relative;
  border-radius: 10px;
  --bs-heading-color: var(--bs-white);
}

.delivery-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/delivery-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .2;
  border-radius: 10px;
}

.delivery-wrapper .btn-primary {
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

.faq-image {
  --bs-faq-image-height: 420px;
  height: var(--bs-faq-image-height);
  min-height: var(--bs-faq-image-height);
}

.faq-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .faq-image {
    --bs-faq-image-height: 350px;
  }
}

.accordion-icon {
  --bs-accordion-icon-color: var(--bs-body-color-style-2);
  position: relative;
  transition: all .4s ease-in-out;
}

.accordion-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18.6px;
  height: 3px;
  background: var(--bs-accordion-icon-color);
}

.accordion-icon:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 8px;
  width: 3px;
  height: 18.6px;
  background: var(--bs-accordion-icon-color);
  transition: all .4s ease-in-out;
}

.accordion {
  --bs-border-radius: 0;
  --bs-accordion-btn-color: var(--bs-body-color-style-2);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accordion .accordion-item {
  overflow: hidden;
  border: 0;
}

.accordion .accordion-title {
  max-width: 480px;
}

@media (max-width: 1399.98px) {
  .accordion .accordion-title {
    max-width: 420px;
  }
}

@media (max-width: 575.98px) {
  .accordion .accordion-title {
    max-width: 320px;
  }
}

.accordion .accordion-button::after {
  content: none;
}

.accordion .accordion-button svg {
  transition: transform .3s ease-in-out;
}

.accordion .accordion-button .accordion-minus-icon {
  display: none;
}

.accordion .accordion-button .accordion-plus-icon {
  display: block;
}

.accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) .accordion-icon {
  --bs-accordion-icon-color: var(--bs-primary);
}

.accordion .accordion-button:not(.collapsed) .accordion-icon:before {
  display: none;
}

.accordion .accordion-button:focus, .accordion .accordion-button:focus-visible, .accordion .accordion-button:focus-within {
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .accordion {
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-body-padding-x: 0;
  }
  .accordion .accordion-icon {
    right: 20px;
  }
}

.accordion.accordion-style-2 {
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-body-padding-x: 0;
}

.accordion.accordion-style-2 .accordion-icon {
  right: 20px;
}

.bg-custom-light .accordion {
  --bs-accordion-bg: var(--bs--bg-custom-light);
  --bs-accordion-active-bg: var(--bs--bg-custom-light);
}

.cta-1 {
  --bs-cta-bg: var(--bs-secondary);
}

.cta-1 .cta-wrapper {
  background-color: var(--bs-cta-bg);
}

.cta-2 .cta-wrapper {
  position: relative;
  background-image: url("../images/cta-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.cta-2 .cta-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--bs-secondary-rgb), 0.2);
}

.cta-2 .cta-wrapper .cta-image-tra {
  position: absolute;
  bottom: 0;
  left: 0;
}

.cta-2 .cta-wrapper .cta-image-tra-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cta-2.cta-3 .cta-wrapper {
  background-image: url("../images/brand-2-bg.jpg");
}

.cta-2.cta-3 .cta-wrapper::before {
  background-color: rgba(var(--bs-secondary-rgb), 0.8);
}

@media (max-width: 767.98px) {
  .cta-image img {
    width: 210px;
    height: 210px;
    object-fit: cover;
  }
}

.cta-heading {
  position: relative;
  z-index: 1;
}

.callRequestForm-wrapper {
  --bs-callRequestForm-wrapper-bg: var(--bs-secondary);
  --bs-heading-color: var(--bs-white);
  background-color: var(--bs-callRequestForm-wrapper-bg);
  border-radius: 10px;
}

.callRequestForm-wrapper .form-control.form-control-lg, .callRequestForm-wrapper .form-select {
  --bs-border-color: transparent;
}

.callRequestForm-wrapper .btn-primary {
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

#callRequestForm {
  --bs-form-label-color: var(--bs-heading-color);
}

#callRequestForm .form-label {
  font-weight: 500;
  font-family: var(--bs-font-family-style-1);
  color: var(--bs-form-label-color);
}

.call-request-inner {
  --bs-call-request-bg: var(--bs-white);
  --bs-call-request-border-radius: 10px;
  --bs-call-request-image-border-radius: 10px 0 0 10px;
  border-radius: var(--bs-call-request-border-radius);
  background-color: var(--bs-call-request-bg);
}

@media (max-width: 991.98px) {
  .call-request-inner {
    --bs-call-request-image-border-radius: 10px 10px 0 0;
  }
}

.call-request-image {
  --bs-call-request-image-height: 615px;
  border-radius: var(--bs-call-request-image-border-radius);
  height: var(--bs-call-request-image-height);
  min-height: var(--bs-call-request-image-height);
}

.call-request-image img {
  border-radius: var(--bs-call-request-image-border-radius);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .call-request-image {
    --bs-call-request-image-height: 400px;
  }
}

@media (max-width: 575.98px) {
  .call-request-image {
    --bs-call-request-image-height: 300px;
  }
}

.call-request-form {
  padding: 40px 40px 10px 40px;
  border-radius: 10px;
}

@media (max-width: 1399.98px) {
  .call-request-form {
    padding: 30px;
  }
}

.call-request-form .form-control.form-control-sm {
  border-width: 0 0 2px 0;
}

.call-request-form-width {
  max-width: 502px;
}

@media (min-width: 992px) {
  .call-request-form-width {
    margin-left: auto;
  }
}

.custom-text-bg-light {
  --bs-body-color: #696971;
  --bs-body-bg: var(--bs-white);
  --bs-heading-color: var(--bs-secondary);
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
}

.custom-text-bg-light .form-control {
  color: var(--bs-body-color);
}

.custom-text-bg-light .form-control::placeholder {
  color: var(--bs-body-color);
}

.custom-text-bg-light .btn-primary {
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
}

.custom-text-bg-light .btn-primary:hover .arrow-icon-2 {
  --bs-arrow-icon-2-stroke-color: var(--bs-secondary);
}

.service-3-wrapper {
  --bs-service-3-bg: var(--bs-body-bg);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background-color: var(--bs-service-3-bg);
  --bs-link-color-rgb: var(--bs-heading-color-rgb);
  --bs-link-color: var(--bs-heading-color);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  transition: all .5s ease-in-out;
}

.service-3-wrapper a, .service-3-wrapper p {
  transition: all .5s ease-in-out;
}

.service-3-wrapper.active, .service-3-wrapper:hover {
  --bs-service-3-bg: var(--bs-secondary);
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
}

.service-3-wrapper.active .freight-icon, .service-3-wrapper:hover .freight-icon {
  --bs-freight-icon-bg: var(--bs-primary);
}

.service-3-wrapper.active p, .service-3-wrapper:hover p {
  color: #fff;
}

@media (max-width: 991.98px) {
  .service-3-wrapper {
    padding: 30px;
  }
}

.service-3-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 1.5px;
}

.service-3-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

textarea {
  resize: none;
}

.service-single-image {
  --bs-service-single-image-height: 500px;
  height: var(--bs-service-single-image-height);
  min-height: var(--bs-service-single-image-height);
}

.service-single-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1399.98px) {
  .service-single-image {
    --bs-service-single-image-height: 430px;
  }
}

@media (max-width: 991.98px) {
  .service-single-image {
    --bs-service-single-image-height: 380px;
  }
}

.service-inner-image {
  --bs-service-inner-image-height: 400px;
  height: var(--bs-service-inner-image-height);
  min-height: var(--bs-service-inner-image-height);
}

.service-inner-image img {
  height: 100%;
  object-fit: cover;
}

.service-inner-image-style-2 {
  --bs-service-inner-image-height: 300px;
  height: var(--bs-service-inner-image-height);
  min-height: var(--bs-service-inner-image-height);
}

.service-inner-image-style-2 img {
  height: 100%;
  object-fit: cover;
}

.service-description {
  --bs-service-description-bg: var(--bs-secondary);
  --bs-service-description-color: var(--bs-white);
  background-color: var(--bs-service-description-bg);
  color: var(--bs-service-description-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px;
}

@media (max-width: 991.98px) {
  .service-description {
    padding: 40px;
  }
}

@media (max-width: 767.98px) {
  .service-description {
    padding: 30px 16px;
  }
}

.service-description-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.service-description-list .check-icon {
  --bs-check-icon-stroke-color: var(--bs-service-description-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bs-service-description-color);
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: transparent;
}

.historical-contents:after {
  content: "";
  position: absolute;
  left: calc(50% + 6px);
  top: 5%;
  width: 5px;
  height: 90%;
  background: var(--bs-primary);
}

@media (max-width: 1399.98px) {
  .historical-contents:after {
    left: calc(50% + 14px);
  }
}

@media (max-width: 1199.98px) {
  .historical-contents:after {
    left: calc(50% + 11px);
  }
}

@media (max-width: 991.98px) {
  .historical-contents:after {
    left: auto;
    right: 57px;
    top: 0;
    height: 100%;
  }
}

@media (max-width: 767.98px) {
  .historical-contents:after {
    right: 27px;
    width: 3px;
  }
}

.historical-year-wrapper {
  --bs-heading-color: var(--bs-white);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bs-primary);
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.historical-year-wrapper .historical-year {
  margin-bottom: 0;
  font-size: 32px;
}

@media (max-width: 1199.98px) {
  .historical-year-wrapper {
    left: -10px;
  }
}

@media (max-width: 767.98px) {
  .historical-year-wrapper {
    width: 50px;
    height: 50px;
    left: -3px;
  }
  .historical-year-wrapper .historical-year {
    font-size: 16px;
  }
}

.historical-image-wrapper img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 10px;
}

.historical-image {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.project-image-style-1 {
  --bs-project-image-style-1-height: 740px;
  height: var(--bs-project-image-style-1-height);
  min-height: var(--bs-project-image-style-1-height);
}

.project-image-style-1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1199.98px) {
  .project-image-style-1 {
    --bs-project-image-style-1-height: 700px;
  }
}

@media (max-width: 991.98px) {
  .project-image-style-1 {
    --bs-project-image-style-1-height: 500px;
  }
}

.project-image-style-2 {
  --bs-project-image-style-2-height: 300px;
  height: var(--bs-project-image-style-2-height);
  min-height: var(--bs-project-image-style-2-height);
}

.project-image-style-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .project-image-style-2 {
    --bs-project-image-style-2-height: 240px;
  }
}

.project-image-style-3 {
  --bs-project-image-style-3-height: 300px;
  height: var(--bs-project-image-style-3-height);
  min-height: var(--bs-project-image-style-3-height);
}

.project-image-style-3 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .project-image-style-3 {
    --bs-project-image-style-3-height: 240px;
  }
}

.project-image-style-4 {
  --bs-project-image-style-4-height: 390px;
  height: var(--bs-project-image-style-4-height);
  min-height: var(--bs-project-image-style-4-height);
}

.project-image-style-4 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .project-image-style-4 {
    --bs-project-image-style-4-height: 260px;
  }
}

.project-info {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .project-info {
    padding: 30px;
  }
}

.project-info-title {
  border-bottom: 2px solid var(--bs-primary);
  width: fit-content;
  padding-bottom: 10px;
}

.project-info-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-info-list li {
  border-bottom: 2px solid var(--bs-body-color);
  padding-bottom: 10px;
}

.project-info-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.pagination {
  --bs-pagination-color: var(--bs-heading-color);
  --bs-pagination-bg: var(--bs-body-color-style-3);
  --bs-pagination-border-width: 0px;
  --bs-pagination-border-color: var(--bs-body-color-style-3);
  --bs-pagination-border-radius: 2px;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--bs-primary);
  --bs-pagination-active-border-color: var(--bs-primary);
  --bs-pagination-hover-color: #fff;
  --bs-pagination-hover-bg: var(--bs-primary);
  --bs-pagination-focus-color: #fff;
  --bs-pagination-focus-bg: var(--bs-primary);
  --bs-pagination-hover-border-color: var(--bs-primary);
}

.pagination .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-pagination-color);
  transition: all 0.2s ease-in-out;
}

.page-link {
  border-radius: var(--bs-pagination-border-radius);
  font-weight: 500;
}

.page-link:hover .chevron-right-icon, .page-link:focus .chevron-right-icon, .page-link:active .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-pagination-active-color);
}

.contact-details-lists {
  --bs-link-color-rgb: var(--bs-body-color-style-2-rgb);
  --bs-link-color: var(--bs-body-color-style-2);
  --bs-contact-details-lists-gap: 30px;
  gap: var(--bs-contact-details-lists-gap);
}

.contact-details-lists li {
  padding-bottom: var(--bs-contact-details-lists-gap);
  border-bottom: 2px solid var(--bs-body-color-style-3);
}

.contact-details-lists li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.submit-form-wrapper {
  --bs-submit-form-wrapper-padding: 80px;
  padding: var(--bs-submit-form-wrapper-padding);
  border-radius: 10px;
}

@media (max-width: 1399.98px) {
  .submit-form-wrapper {
    --bs-submit-form-wrapper-padding: 50px;
  }
}

@media (max-width: 575.98px) {
  .submit-form-wrapper {
    --bs-submit-form-wrapper-padding: 30px 20px;
  }
}

#map {
  height: 400px;
}

#map .leaflet-top, #map .leaflet-bottom, #map .leaflet-pane {
  z-index: 1;
}

#map .leaflet-popup-content-wrapper, #map .leaflet-popup-tip {
  background: var(--bs-primary);
  color: var(--bs-secondary);
  box-shadow: 0 3px 14px rgba(var(--bs-secondary-rgb), 0.3);
}

#map .leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 10px;
}

#map .leaflet-popup-content {
  margin: 7px 10px 7px 10px;
}

#map .leaflet-popup-content p {
  margin: 0;
}

#map .location-subtitle {
  margin-bottom: 8px !important;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  color: var(--bs-secondary);
}

.progress-circle {
  --bs-progress-circle-size: 144px;
  position: relative;
  width: var(--bs-progress-circle-size);
  height: var(--bs-progress-circle-size);
}

.progress-circle svg {
  width: var(--bs-progress-circle-size);
  height: var(--bs-progress-circle-size);
}

.progress-circle .progress-bg {
  fill: none;
  stroke-width: 4px;
  stroke: var(--bs-secondary);
}

.progress-circle .progress {
  fill: none;
  stroke-width: 10px;
  stroke: var(--bs-primary);
  stroke-linecap: round;
  stroke-dashoffset: 60;
  transform: rotate(91deg);
  transform-origin: 50% 50%;
  position: relative;
}

.progress-circle .progress:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: red;
  z-index: 1;
}

.progress-circle .text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--bs-secondary);
  position: absolute;
  left: calc(50% - 37px);
  top: calc(50% - 37px);
}

.progress-circle.circle-75 .progress {
  stroke-dasharray: 350;
  animation: progress-animation-75 1.5s ease-in-out;
}

.progress-circle.circle-80 .progress {
  stroke-dasharray: 370;
  animation: progress-animation-80 1.5s ease-in-out;
}

.progress-circle.circle-90 .progress {
  stroke-dasharray: 400;
  animation: progress-animation-90 1.5s ease-in-out;
}

.progress-info {
  max-width: 350px;
}

@keyframes progress-animation-75 {
  from {
    stroke-dashoffset: 350;
  }
  to {
    stroke-dashoffset: 60;
  }
}

@keyframes progress-animation-80 {
  from {
    stroke-dashoffset: 370;
  }
  to {
    stroke-dashoffset: 60;
  }
}

@keyframes progress-animation-90 {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: 60;
  }
}

.progress {
  --bs-progress-bg: #EFEFF7;
  --bs-progress-bar-bg: var(--bs-primary);
  --bs-progress-bar-height: 1rem;
  --bs-border-radius: .5rem;
  --bs-progress-font-size: 18px;
  display: flex;
  align-items: center;
  overflow: visible;
}

.progress-1 {
  background-color: var(--bs-progress-bg);
}

.progress-bar {
  height: var(--bs-progress-bar-height);
  border-radius: var(--bs-border-radius);
}

.progress-bar-1 {
  animation: progressAnimation-1 11s;
}

@keyframes progressAnimation-1 {
  0% {
    width: 5%;
    background-color: var(--bs-progress-bar-bg);
  }
  100% {
    width: 100%;
    background-color: var(--bs-progress-bar-bg);
  }
}

.progress-bar-circle {
  --bs-progress-bar-circle-size: 30px;
  position: relative;
}

.progress-bar-circle:after {
  content: "";
  position: absolute;
  top: -15px;
  right: 0;
  width: var(--bs-progress-bar-circle-size);
  height: var(--bs-progress-bar-circle-size);
  border-radius: 50%;
  background: var(--bs-primary);
}

.progress-bar-circle:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  top: -5px;
  left: -20px;
  z-index: 3;
  border-radius: 50%;
}

.widget-input-area {
  --bs-widget-input-area-bg: var(--bs-body-color-style-3);
  --bs-link-color-rgb: var(--bs-body-color-rgb);
  --bs-link-color: var(--bs-body-color);
  --bs-link-hover-color: var(--bs-white);
  --bs-link-hover-color-rgb: var(--bs-white-rgb);
}

.widget-input-area.widget-input-light {
  --bs-widget-input-area-bg: var(--bs-body-bg);
  box-shadow: 0 0.1rem 0.95rem rgba(0, 0, 0, 0.06);
}

.widget-input-area .form-control {
  background-color: var(--bs-widget-input-area-bg);
  padding: 14px 24px;
}

.widget-input-area .service--single-button {
  background-color: var(--bs-widget-input-area-bg);
  padding: 14px 24px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.widget-input-area .service--single-button .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-body-color);
}

.widget-input-area .service--single-button:hover {
  --bs-widget-input-area-bg: var(--bs-primary);
}

.widget-input-area .service--single-button:hover .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-white);
}

.widget-input-area .service--single-button.active {
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
  --bs-widget-input-area-bg: var(--bs-primary);
}

.widget-input-area .service--single-button.active .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-white);
}

.section-content-image {
  position: relative;
  --bs-section-content-image-radius: 10px;
  --bs-section-content-image-height: 390px;
  height: var(--bs-section-content-image-height);
  min-height: var(--bs-section-content-image-height);
  border-radius: var(--bs-section-content-image-radius);
}

.section-content-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--bs-section-content-image-radius);
}

@media (max-width: 991.98px) {
  .section-content-image {
    --bs-section-content-image-height: 240px;
  }
}

.tag-wrapper {
  border-bottom: 2px solid var(--bs-body-color-style-3);
}

.comment-author-image {
  width: 60px;
  min-width: 60px;
  height: 60px;
}

.comment-author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.comment-author-image-style-2 {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.comment-author-image-style-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.comment-lists-author {
  font-family: var(--bs-font-family-style-1);
  color: var(--bs-heading-color);
}

.comment-lists-date {
  font-size: 16px;
}

.wp-block-quote {
  padding: 60px 50px 40px 50px;
  text-align: center;
  background-color: var(--bs-body-color-style-1);
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .wp-block-quote {
    padding: 50px 35px 40px 35px;
  }
}

@media (max-width: 767.98px) {
  .wp-block-quote {
    padding: 40px 24px 30px 24px;
  }
}

.wp-block-quote p {
  font-size: 20px;
  color: var(--bs-secondary);
  font-weight: 500;
  margin-bottom: 0;
}

.quote-icon-2 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  left: calc(50% - 20px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--bs-body-color-style-1);
  border-radius: 50%;
}

.next-prev-wrapper {
  --bs-link-color: var(--bs-heading-color);
  --bs-link-color-rgb: var(--bs-heading-color-rgb);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  --bs-next-prev-wrapper-spacing: 80px;
  display: flex;
  gap: var(--bs-next-prev-wrapper-spacing);
  align-items: center;
}

.prev-content {
  position: relative;
}

.prev-content::before {
  content: "";
  position: absolute;
  top: 4px;
  right: -40px;
  height: 80%;
  width: 3px;
  background: var(--bs-primary);
}

.next-prev-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 2px;
}

.next-prev-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.recent-post-wrapper {
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}

.recent-post {
  border-bottom: 2px solid var(--bs-body-color-style-3);
  padding-bottom: 14px;
}

.cost-calculate-title {
  max-width: 424px;
}

@media (min-width: 992px) {
  .section-project .container-fluid, .section-project .container-sm, .section-project .container-md, .section-project .container-lg, .section-project .container-xl, .section-project .container-xxl {
    padding-left: 0 !important;
  }
}

.cost-calculate {
  --bs-cost-calculate-padding: 60px;
  --bs-cost-calculate-width: 808px;
  --bs-cost-calculate-bg: var(--bs-body-bg);
  background-color: var(--bs-cost-calculate-bg);
  padding: var(--bs-cost-calculate-padding);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  max-width: var(--bs-cost-calculate-width);
}

@media (max-width: 1399.98px) {
  .cost-calculate {
    --bs-cost-calculate-width: 700px;
  }
}

@media (max-width: 1199.98px) {
  .cost-calculate {
    --bs-cost-calculate-width: 650px;
  }
}

@media (max-width: 991.98px) {
  .cost-calculate {
    --bs-cost-calculate-width: 695px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .cost-calculate {
    --bs-cost-calculate-width: 540px;
    --bs-cost-calculate-padding: 40px;
  }
}

@media (max-width: 575.98px) {
  .cost-calculate {
    --bs-cost-calculate-padding: 16px;
  }
}

.project-details-image img {
  width: 100%;
}

@media (max-width: 991.98px) {
  .project-details-image {
    display: none;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1650px) {
  .cost-calculate {
    --bs-cost-calculate-width: 780px;
  }
}

.progressCounter {
  z-index: 12345;
  cursor: pointer;
}

.progressScroll {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  border-radius: 50px;
}

.progressScroll:hover {
  transform: translateY(-2px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: rgba(var(--bs-primary-rgb), 0.8) 0px 3px 20px;
}

.progress-scroll-opacity-0 {
  opacity: 0;
  transition: transform .3s ease-in-out;
}

.progress-scroll-opacity-1 {
  opacity: 1;
  transition: transform .3s ease-in-out;
}

.bx-slider-wrapper .bx-wrapper {
  --bs-slider-content-spacing: 200px;
  box-shadow: none;
  border: 0;
  margin-bottom: 0;
  padding: 70px 0;
  background-color: var(--bs-secondary);
}

.bx-slider-wrapper .bx-wrapper .bx-slider-content a:nth-child(even) {
  color: var(--bs-primary);
}

.bx-slider-wrapper .bx-wrapper .bx-slider-content a:nth-child(odd) {
  color: var(--bs-white);
}

.bx-slider-wrapper .bx-wrapper .bx-loading {
  background: none;
}

.bx-slider-wrapper .bx-wrapper .slider-separator-icon {
  --bs-slider-separator-icon-color: var(--bs-white);
  color: var(--bs-slider-separator-icon-color);
  position: absolute;
  top: 15px;
  right: calc(45% + var(--bs-slider-content-spacing));
}

@media (max-width: 1199.98px) {
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon {
    right: calc(40% + var(--bs-slider-content-spacing));
  }
}

@media (max-width: 991.98px) {
  .bx-slider-wrapper .bx-wrapper {
    --bs-slider-content-spacing: 100px;
  }
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon {
    right: calc(60% + var(--bs-slider-content-spacing));
  }
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon svg {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 767.98px) {
  .bx-slider-wrapper .bx-wrapper {
    --bs-slider-content-spacing: 80px;
    padding: 50px 0;
  }
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon {
    top: 16px;
    right: calc(70% + var(--bs-slider-content-spacing));
  }
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 575.98px) {
  .bx-slider-wrapper .bx-wrapper .slider-separator-icon {
    top: 11px;
    right: calc(66% + var(--bs-slider-content-spacing));
  }
}

.subscription-wrapper {
  position: relative;
  --bs-subscription-wrapper-bg: var(--bs-secondary);
  --bs-subscription-wrapper-width: 455px;
  --bs-heading-color: var(--bs-white);
  background-color: var(--bs-subscription-wrapper-bg);
  border-radius: 10px;
  padding: 30px 24px;
}

.subscription-wrapper .subscription-inner {
  max-width: var(--bs-subscription-wrapper-width);
}

.subscription-wrapper p {
  color: var(--bs-white);
}

@media (max-width: 1399.98px) {
  .subscription-wrapper {
    --bs-subscription-wrapper-width: 430px;
  }
}

@media (max-width: 1199.98px) {
  .subscription-wrapper {
    --bs-subscription-wrapper-width: 340px;
  }
}

.subscription-form {
  --bs-subscription-button-right-position: 20px;
  --bs-subscription-button-top-position: 12px;
  position: relative;
}

.subscription-form .subscription-button {
  position: absolute;
  top: var(--bs-subscription-button-top-position);
  right: var(--bs-subscription-button-right-position);
}

@media (max-width: 575.98px) {
  .subscription-form {
    --bs-subscription-button-right-position: 16px;
  }
  .subscription-form .form-control {
    padding: 16px 10px;
  }
  .subscription-form .btn-sm, .subscription-form .btn-group-sm > .btn {
    --bs-btn-padding-x: 10px;
  }
}

.subscription-image {
  position: absolute;
  bottom: 0;
  right: 80px;
}

@media (max-width: 1399.98px) {
  .subscription-image {
    width: 352px;
  }
}

@media (max-width: 991.98px) {
  .subscription-image {
    width: 270px;
    right: 24px;
  }
}

@media (max-width: 767.98px) {
  .subscription-image {
    right: auto;
    position: relative;
    left: 0;
    top: 30px;
  }
}

.instagram-image-wrapper {
  --bs-instagram-image-radius: 2px;
  border-radius: var(--bs-instagram-image-radius);
  overflow: hidden;
  position: relative;
}

.instagram-image-wrapper .instagram-icon-lg {
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
  opacity: 0;
  transition: opacity .4s ease-in-out;
  z-index: 2;
}

.instagram-image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity .4s ease-in-out;
  border-radius: var(--bs-instagram-image-radius);
  z-index: 1;
}

.instagram-image-wrapper .instagram-image {
  transition: transform .4s ease-in-out;
}

.instagram-image-wrapper:hover::before {
  opacity: 1;
}

.instagram-image-wrapper:hover .instagram-icon-lg {
  opacity: 1;
}

.instagram-image-wrapper:hover .instagram-image {
  transform: scale(1.06) translateZ(0);
}

.instagram-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: var(--bs-instagram-image-radius);
}

.search-box {
  display: flex;
  align-items: center;
  position: relative;
}

.search-bar {
  --bs-search-bar-width: 800px;
  --bs-btn-submit-input-bg: transparent;
  --bs-btn-submit-input-border: var(--bs-body-color-style-2);
  --bs-arrow-color: var(--bs-secondary);
  --bs-search-bar-bottom-spacing: 32px;
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
  position: absolute;
  right: 0;
  z-index: 2;
}

.search-bar .search-input {
  width: var(--bs-search-bar-width);
  padding: 16px 16px 16px 24px;
  border-radius: 4px;
  display: none;
  border: 0;
}

.search-bar .search-input::placeholder {
  font-weight: 300px;
  font-size: 16px;
}

.search-bar .btn-submit-input {
  position: absolute;
  right: 0;
  height: 100%;
  border-left: 1px solid var(--bs-btn-submit-input-border) !important;
  border: 0;
  padding: 0 22px;
  background: var(--bs-btn-submit-input-bg);
  transition: all .5s ease-in-out;
}

.search-bar .btn-submit-input.arrow-icon-2 {
  --bs-arrow-icon-2-stroke-color: var(--bs-arrow-color);
}

.search-bar.expanded {
  width: var(--bs-search-bar-width);
  border-radius: 5px;
  box-shadow: 0 0.1rem 0.95rem rgba(0, 0, 0, 0.1);
}

.search-bar.expanded .search-input {
  display: inline-block;
}

.search-bar.expanded .search-input:is(:focus-visible, :focus) {
  border: 0 !important;
  outline: none;
}

.search-bar:hover {
  --bs-btn-submit-input-bg: var(--bs-primary);
  --bs-btn-submit-input-border: var(--bs-primary);
  --bs-arrow-color: var(--bs-white);
}

@media (max-width: 1399.98px) {
  .search-bar {
    --bs-search-bar-width: 650px;
    --bs-search-bar-bottom-spacing: 20px;
  }
}

@media (max-width: 1199.98px) {
  .search-bar {
    --bs-search-bar-width: 318px;
    --bs-search-bar-bottom-spacing: 0;
    right: auto;
    left: 0;
  }
  .search-bar .search-input {
    padding: 18px 16px 18px 24px;
  }
}

.costCalculateModal.modal {
  --bs-modal-width: 705px;
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
  --bs-modal-border-radius: 10px;
  --bs-modal-border-width: 0;
  --bs-modal-padding: 10px 60px 60px 60px;
  --bs-modal-header-padding: 60px 30px 10px 60px;
}

.costCalculateModal.modal .modal-header {
  position: relative;
}

.costCalculateModal.modal .btn-close {
  position: absolute;
  top: 40px;
  right: 30px;
}

@media (max-width: 767.98px) {
  .costCalculateModal.modal {
    padding: 0 10px;
    --bs-modal-width: 600px;
  }
}

@media (max-width: 575.98px) {
  .costCalculateModal.modal {
    --bs-modal-padding: 10px 20px 30px 20px;
    --bs-modal-header-padding: 42px 20px 5px 20px;
  }
  .costCalculateModal.modal .btn-close {
    top: 17px;
    right: 17px;
  }
}

.tracking-inner {
  --bs-tracking-inner-bg: var(--bs-body-bg);
  background-color: var(--bs-tracking-inner-bg);
  border-radius: 10px;
}

.tracing-items-border {
  border-bottom: 2px solid var(--bs-body-color-style-3);
}

.shipping {
  max-width: 705px;
  margin: 0 auto;
}

.section-tracking {
  padding: 260px 0 140px 0;
}

@media (max-width: 1399.98px) {
  .section-tracking {
    padding: 190px 0 100px 0;
  }
}

@media (max-width: 991.98px) {
  .section-tracking {
    padding: 150px 0 60px 0;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .section-tracking {
    padding: 200px 0 140px 0;
  }
}

.alert {
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0;
  --bs-alert-margin-bottom: 0;
}

@media (min-width: 1200px) {
  .navbar-display {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s ease-in-out;
  }
}

.hero-author-wrapper li + li {
  margin-left: -20px;
}

.hero-author-image {
  --bs-hero-author-image-size: 50px;
  border: 3px solid #fff;
  width: var(--bs-hero-author-image-size);
  height: var(--bs-hero-author-image-size);
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 1399.98px) {
  .hero-heading-text {
    font-size: 58px;
  }
}

@media (max-width: 1199.98px) {
  .hero-heading-text {
    font-size: 55px;
  }
}

@media (max-width: 991.98px) {
  .hero-heading-text {
    font-size: 45px;
  }
}

@media (max-width: 767.98px) {
  .hero-heading-text {
    font-size: 36px;
  }
}

.hero-overlay {
  position: relative;
}

.hero-overlay::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: var(--bs-overlay-bg);
  background-color: var(--bs-overlay-color);
  width: var(--bs-overlay-width);
  height: var(--bs-overlay-height);
}

.hero-overlay-style-2 {
  --bs-overlay-width: 80%;
  --bs-overlay-height: 100%;
  --bs-overlay-bg: linear-gradient(270deg, rgba(1, 9, 16, .01) 0%, rgba(3, 18, 33, .93) 80.2%);
}

.hero-overlay-style-4 {
  --bs-overlay-width: 100%;
  --bs-overlay-height: 100%;
  --bs-overlay-bg: linear-gradient(90deg, rgba(1, 9, 16, .46) 0%, rgba(3, 18, 33, 1) 82.2%);
}

.hero-overlay-style-4::after {
  left: auto;
  right: 0;
}

.hero-overlay-style-6 {
  --bs-overlay-width: 100%;
  --bs-overlay-height: 100%;
  --bs-overlay-color: rgba(1, 40, 79, 0.8);
}

.hero-overlay-style-6::after {
  backdrop-filter: blur(8px);
}

.hero-overlay-style-7 {
  --bs-overlay-width: 100%;
  --bs-overlay-height: 100%;
  --bs-overlay-color: rgba(0, 54, 108, .84);
}

.hero-components-style-1 {
  --bs-hero-inner-width: 780px;
  --bs-hero-text-width: 570px;
  --bs-hero-inner-text-padding: 200px 0 200px 0;
}

.hero-components-style-1 .hero-inner {
  position: relative;
  max-width: var(--bs-hero-inner-width);
  padding: var(--bs-hero-inner-text-padding);
  z-index: 1;
}

.hero-components-style-1 .hero-text {
  max-width: var(--bs-hero-text-width);
}

@media (max-width: 1399.98px) {
  .hero-components-style-1 {
    --bs-hero-inner-width: 595px;
    --bs-hero-inner-text-padding: 85px 0 120px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-1 {
    --bs-hero-inner-width: 500px;
  }
}

@media (max-width: 767.98px) {
  .hero-components-style-1 {
    --bs-hero-inner-width: 100%;
    --bs-hero-inner-text-padding: 100px 0;
  }
  .hero-components-style-1 .hero-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .hero-components-style-1 .hero-wrapper::after {
    background-position: right;
  }
}

.hero-components-style-2 {
  --bs-hero-inner-text-padding: 200px 0 220px 0;
  --bs-hero-text-width: 710px;
  --bs-hero-text-2-width: 635px;
}

.hero-components-style-2 .hero-inner {
  position: relative;
  padding: var(--bs-hero-inner-text-padding);
  z-index: 1;
}

.hero-components-style-2 .hero-text {
  max-width: var(--bs-hero-text-width);
}

.hero-components-style-2 .hero-text-2 {
  max-width: var(--bs-hero-text-2-width);
}

@media (max-width: 1399.98px) {
  .hero-components-style-2 {
    --bs-hero-inner-text-padding: 120px 0 150px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-components-style-2 {
    --bs-hero-inner-text-padding: 118px 0 130px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-2 {
    --bs-hero-inner-text-padding: 100px 0 130px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-components-style-2 {
    --bs-hero-inner-text-padding: 110px 0;
  }
}

.hero-5 .hero-wrapper, .hero-6 .hero-wrapper {
  min-height: var(--bs-hero-wrapper-height);
  max-height: var(--bs-hero-wrapper-height);
}

@media (min-width: 1400px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 825px;
  }
}

@media (max-width: 1399.98px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 630px;
  }
}

@media (max-width: 1199.98px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 555px;
  }
}

@media (max-width: 991.98px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 536px;
  }
}

@media (max-width: 767.98px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 530px;
  }
}

@media (max-width: 575.98px) {
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 570px;
  }
}

.hero-components-style-3 {
  --bs-hero-wrapper-padding: 230px 0 180px 0;
  --bs-hero-inner-spacing: 40px;
  --bs-hero-text-width: 570px;
}

.hero-components-style-3 .hero-wrapper {
  padding: var(--bs-hero-wrapper-padding);
}

.hero-components-style-3 .hero-text {
  max-width: var(--bs-hero-text-width);
}

.hero-components-style-3 .hero-inner {
  top: var(--bs-hero-inner-spacing);
}

@media (max-width: 1399.98px) {
  .hero-components-style-3 {
    --bs-hero-wrapper-padding: 200px 0 130px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-3 {
    --bs-hero-wrapper-padding: 160px 0 130px 0;
    --bs-hero-inner-spacing: 0;
  }
}

@media (max-width: 767.98px) {
  .hero-components-style-3 {
    --bs-hero-wrapper-padding: 160px 0 130px 0;
  }
}

.hero-components-style-4 {
  --bs-hero-wrapper-padding: 270px 0 50px 0;
  --bs-hero-text-width: 570px;
}

.hero-components-style-4 .hero-wrapper {
  padding: var(--bs-hero-wrapper-padding);
}

.hero-components-style-4 .hero-text {
  max-width: var(--bs-hero-text-width);
}

@media (max-width: 1399.98px) {
  .hero-components-style-4 {
    --bs-hero-wrapper-padding: 255px 0 46px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-4 {
    --bs-hero-wrapper-padding: 170px 0 55px 0;
  }
}

.hero-components-style-5 {
  --bs-hero-wrapper-padding: 120px 0 60px 0;
  --bs-hero-inner-width: 780px;
  --bs-hero-text-width: 570px;
}

.hero-components-style-5 .hero-inner {
  position: relative;
  max-width: var(--bs-hero-inner-width);
  padding: var(--bs-hero-inner-text-padding);
  z-index: 2;
}

.hero-components-style-5 .hero-wrapper {
  padding: var(--bs-hero-wrapper-padding);
}

.hero-components-style-5 .hero-text {
  max-width: var(--bs-hero-text-width);
}

@media (max-width: 1399.98px) {
  .hero-components-style-5 {
    --bs-hero-wrapper-padding: 120px 0 46px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-components-style-5 {
    --bs-hero-inner-width: 550px;
    --bs-hero-text-width: 510px;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-5 {
    --bs-hero-wrapper-padding: 110px 0 450px 0;
    --bs-hero-inner-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .hero-components-style-5 {
    --bs-hero-wrapper-padding: 80px 0 450px 0;
  }
}

@media (max-width: 575.98px) {
  .hero-components-style-5 {
    --bs-hero-wrapper-padding: 70px 0 450px 0;
  }
}

.hero-components-style-6 {
  --bs-hero-inner-width: 780px;
  --bs-hero-text-width: 570px;
  --bs-hero-inner-text-padding: 200px 0 202px 0;
}

.hero-components-style-6 .hero-inner {
  position: relative;
  max-width: var(--bs-hero-inner-width);
  padding: var(--bs-hero-inner-text-padding);
  z-index: 1;
}

.hero-components-style-6 .hero-text {
  max-width: var(--bs-hero-text-width);
}

@media (max-width: 1399.98px) {
  .hero-components-style-6 {
    --bs-hero-inner-width: 595px;
    --bs-hero-inner-text-padding: 140px 0 130px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-components-style-6 {
    --bs-hero-inner-width: 595px;
    --bs-hero-inner-text-padding: 140px 0 115px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-components-style-6 {
    --bs-hero-inner-width: 500px;
    --bs-hero-inner-text-padding: 110px 0 96px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-components-style-6 {
    --bs-hero-inner-width: 100%;
    --bs-hero-inner-text-padding: 100px 0;
  }
}

@media (max-width: 575.98px) {
  .hero-components-style-6 {
    --bs-hero-inner-width: 100%;
    --bs-hero-inner-text-padding: 95px 0 148px 0;
  }
}

.hero-image-tra-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 1399.98px) {
  .hero-image-tra-1 {
    width: 620px;
  }
}

@media (max-width: 1199.98px) {
  .hero-image-tra-1 {
    width: 490px;
    top: 40px;
  }
}

@media (max-width: 1199.98px) {
  .hero-image-tra-1 {
    width: 400px;
    top: 90px;
  }
}

@media (max-width: 991.98px) {
  .hero-image-tra-1 {
    display: none;
  }
}

.hero-image-tra-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: move-container 15s infinite linear;
}

@media (max-width: 1399.98px) {
  .hero-image-tra-2 {
    width: 750px;
  }
}

@media (max-width: 1199.98px) {
  .hero-image-tra-2 {
    width: 600px;
  }
}

@media (max-width: 991.98px) {
  .hero-image-tra-2 {
    width: 450px;
  }
}

@media (max-width: 767.98px) {
  .hero-image-tra-2 {
    width: 310px;
  }
}

@keyframes fly {
  0% {
    transform: scale(0.2);
    /* Small size at the beginning */
    opacity: 0;
  }
  100% {
    transform: scale(1);
    /* Medium size in the middle */
    opacity: 1;
  }
}

@keyframes move-container {
  0% {
    transform: translateX(150px);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(150px);
  }
}

.hero-2 .hero-wrapper, .hero-3 .hero-wrapper, .hero-4 .hero-wrapper {
  min-height: var(--bs-hero-wrapper-height);
  max-height: var(--bs-hero-wrapper-height);
}

@media (min-width: 1400px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 905px;
  }
}

@media (max-width: 1399.98px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 708px;
  }
}

@media (max-width: 1199.98px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 680px;
  }
}

@media (max-width: 991.98px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 640px;
  }
}

@media (max-width: 767.98px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 672px;
  }
}

@media (max-width: 575.98px) {
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 695px;
  }
}

@media (min-width: 1400px) {
  .hero-custom-padding-left {
    padding-left: 100px !important;
  }
}

.hero-9 .hero-wrapper, .hero-10 .hero-wrapper, .hero-11 .hero-wrapper {
  min-height: var(--bs-hero-wrapper-height);
  max-height: var(--bs-hero-wrapper-height);
}

@media (min-width: 1400px) {
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 1040px;
  }
}

@media (max-width: 1399.98px) {
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 968px;
  }
}

@media (max-width: 991.98px) {
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 1325px;
  }
}

@media (max-width: 767.98px) {
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 1314px;
  }
}

@media (max-width: 575.98px) {
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 1630px;
  }
}

.hero-margin-top-style-1 {
  --bs-hero-margin-top-style-1: 120px;
  margin-top: var(--bs-hero-margin-top-style-1);
}

@media (max-width: 1399.98px) {
  .hero-margin-top-style-1 {
    --bs-hero-margin-top-style-1: 96px;
  }
}

@media (max-width: 991.98px) {
  .hero-margin-top-style-1 {
    --bs-hero-margin-top-style-1: 87px;
  }
}

.hero-margin-top-style-2 {
  --bs-hero-margin-top-style-2: 178px;
  margin-top: var(--bs-hero-margin-top-style-2);
}

@media (max-width: 1399.98px) {
  .hero-margin-top-style-2 {
    --bs-hero-margin-top-style-2: 154px;
  }
}

@media (max-width: 991.98px) {
  .hero-margin-top-style-2 {
    --bs-hero-margin-top-style-2: 87px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1650px) {
  .hero-margin-top-style-1 {
    --bs-hero-margin-top-style-1: 90px;
  }
  .hero-margin-top-style-2 {
    --bs-hero-margin-top-style-2: 148px;
  }
  .hero-image-tra-1 {
    width: 620px;
  }
  .hero-image-tra-2 {
    width: 800px;
  }
  .hero-components-style-1 {
    --bs-hero-inner-width: 630px;
    --bs-hero-inner-text-padding: 105px 0;
  }
  .hero-components-style-2 {
    --bs-hero-inner-text-padding: 144px 0 180px 0;
  }
  .hero-components-style-3 {
    --bs-hero-wrapper-padding: 200px 0 170px 0;
  }
  .hero-components-style-5 {
    --bs-hero-wrapper-padding: 80px 0 60px 0;
  }
  .hero-5, .hero-6 {
    --bs-hero-wrapper-height: 700px;
  }
  .hero-9, .hero-10, .hero-11 {
    --bs-hero-wrapper-height: 1000px;
  }
  .hero-heading-text {
    font-size: 64px;
  }
  .hero-components-style-5 {
    --bs-hero-margin-top: 148px;
  }
  .hero-components-style-6 {
    --bs-hero-inner-text-padding: 90px 0;
  }
  .hero-2, .hero-3, .hero-4 {
    --bs-hero-wrapper-height: 700px;
  }
}

@media (max-width: 380px) {
  .hero-heading-text {
    font-size: 34px !important;
  }
}

.heroSwiper {
  --swiper-theme-color: var(--bs-primary);
  --swiper-pagination-bullet-inactive-color: var(--bs-body-color-style-1);
}

.swiper-pagination {
  position: absolute;
  z-index: 1;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 90px;
  left: calc(50% - 36px);
  width: fit-content;
}

@media (max-width: 1399.98px) {
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 36px;
  }
}

@media (max-width: 991.98px) {
  .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-style-2, .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-style-2 {
    bottom: 40px;
  }
}

@media (max-width: 575.98px) {
  .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-style-2, .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-style-2 {
    bottom: 55px;
  }
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}

.swiper-slide-active .hero-2 .hero-heading-text, .swiper-slide-active .hero-3 .hero-heading-text {
  animation-name: slideInLeft;
  animation-duration: 1.7s;
}

.swiper-slide-active .hero-2 .hero-text, .swiper-slide-active .hero-3 .hero-text {
  animation-name: slideInLeft;
  animation-duration: 2s;
}

.swiper-slide-active .hero-2 .hero-bottom-components, .swiper-slide-active .hero-3 .hero-bottom-components {
  animation-name: slideInLeft;
  animation-duration: 2.3s;
}

.swiper-slide-active .hero-2 .hero-image-tra-1, .swiper-slide-active .hero-3 .hero-image-tra-1 {
  animation: fly 7s ease-in-out;
}

.swiper-slide-active .hero-4 .hero-heading-text {
  animation-name: slideInRight;
  animation-duration: 1.7s;
}

.swiper-slide-active .hero-4 .hero-text {
  animation-name: slideInRight;
  animation-duration: 2s;
}

.swiper-slide-active .hero-4 .hero-bottom-components {
  animation-name: slideInRight;
  animation-duration: 2.3s;
}

.swiper-slide-active .hero-5 .hero-heading-text, .swiper-slide-active .hero-6 .hero-heading-text {
  animation-name: fadeInUp;
  animation-duration: 1.8s;
}

.swiper-slide-active .hero-5 .hero-text, .swiper-slide-active .hero-6 .hero-text {
  animation-name: fadeInUp;
  animation-duration: 2.1s;
}

.swiper-slide-active .hero-5 .hero-button, .swiper-slide-active .hero-6 .hero-button {
  animation-name: fadeInUp;
  animation-duration: 2.5s;
}

.hero-1 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-1-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-1 .hero-heading-text {
  animation-name: slideInLeft;
  animation-duration: 1.7s;
}

.hero-1 .hero-text {
  animation-name: slideInLeft;
  animation-duration: 2s;
}

.hero-1 .hero-bottom-components {
  animation-name: slideInLeft;
  animation-duration: 2.3s;
}

.hero-2 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-2-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-3 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-3-bg.jpg);
  --bg-parallax-opacity: 1;
}

@media (max-width: 991.98px) {
  .hero-3.hero-components-style-1 {
    --bs-hero-text-width: 395px;
  }
}

@media (max-width: 767.98px) {
  .hero-3.hero-components-style-1 {
    --bs-hero-text-width: 570px;
  }
}

.hero-4 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-4-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-4 .hero-inner {
  margin-left: auto;
}

.hero-5 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-5-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-6 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-6-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-7 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-7-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-8 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-8-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-8 .hero-author-image {
  --bs-hero-author-image-size: 70px;
}

.hero-8 .hero-heading-text {
  max-width: 1060px;
}

@media (max-width: 1399.98px) {
  .hero-8 .hero-heading-text {
    max-width: 980px;
  }
}

.hero-9 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-9-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-10 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-10-bg.jpg);
  --bg-parallax-opacity: 1;
}

.hero-11 .hero-wrapper {
  --bg-parallax-image: url(../images/hero-11-bg.jpg);
  --bg-parallax-opacity: 1;
}

.banner-wrapper {
  position: relative;
  z-index: 1;
}

.section-banner {
  --bg-parallax-opacity: 1;
  padding: 263px 0 60px 0;
}

@media (max-width: 1399.98px) {
  .section-banner {
    padding: 200px 0 80px 0;
  }
}

@media (max-width: 575.98px) {
  .section-banner {
    padding: 200px 0 60px 0;
  }
}

.section-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-secondary-rgb), 0.7);
  z-index: 1;
}

.banner-style-1 {
  --bg-parallax-image: url(../images/banner-1.jpg);
}

.banner-style-2 {
  --bg-parallax-image: url(../images/banner-2.jpg);
}

.banner-style-3 {
  --bg-parallax-image: url(../images/banner-3.jpg);
}

.banner-style-4 {
  --bg-parallax-image: url(../images/banner-4.jpg);
}

.breadcrumb {
  --bs-breadcrumb-divider-color: var(--bs-primary);
}

.breadcrumb-item {
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-color: var(--bs-white);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  font-weight: 500;
}

.breadcrumb-item + .breadcrumb-item::before {
  transform: rotate(-21deg);
  position: relative;
  top: -1px;
}

.hero-swiper-wrapper {
  max-width: 670px;
  --bs-link-color-rgb: var(--bs-heading-color-rgb);
  --bs-link-color: var(--bs-heading-color);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
}

@media (max-width: 1199.98px) {
  .hero-swiper-wrapper {
    max-width: 420px;
  }
}

@media (max-width: 991.98px) {
  .hero-swiper-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .hero-swiper-wrapper p {
    font-size: 16px;
  }
}

@media (max-width: 575.98px) {
  .hero-swiper-wrapper {
    margin: 0 auto;
    max-width: 330px;
  }
}

.hero-slider-wrapper:hover {
  --bs-link-color: var(--bs-primary);
  --bs-link-color-rgb: var(--bs-primary-rgb);
}

.hero-slider-wrapper:hover .hero-slider-image img {
  transform: scale(1.04);
}

.hero-slider-image {
  width: 195px;
  height: 123px;
  border: 1px solid #fff;
  overflow: hidden;
}

.hero-slider-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .3s ease-in-out;
}

@media (max-width: 767.98px) {
  .hero-slider-image {
    width: 160px;
    height: 110px;
  }
}

@media (max-width: 575.98px) {
  .hero-slider-image {
    width: 150px;
    height: 100px;
  }
}

.hero-partial-image {
  --bs-hero-partial-image-width: 838px;
  --bs-hero-partial-image-height: 100%;
  --bs-hero-partial-image-left-spacing: 10px;
  --bs-hero-partial-image-top-spacing: 0;
  --bs-hero-partial-image-bg: var(--bs-white);
  --bs-hero-partial-image-radius: 500px 0 0 500px;
  position: absolute;
  right: 0;
  top: 0;
  width: var(--bs-hero-partial-image-width);
  height: var(--bs-hero-partial-image-height);
  z-index: 1;
  border-radius: var(--bs-hero-partial-image-radius);
  background-color: var(--bs-hero-partial-image-bg);
}

.hero-partial-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--bs-hero-partial-image-radius);
  padding-left: var(--bs-hero-partial-image-left-spacing);
  padding-top: var(--bs-hero-partial-image-top-spacing);
}

@media (max-width: 1399.98px) {
  .hero-partial-image {
    --bs-hero-partial-image-width: 550px;
  }
}

@media (max-width: 1199.98px) {
  .hero-partial-image {
    --bs-hero-partial-image-width: 480px;
  }
}

@media (max-width: 991.98px) {
  .hero-partial-image {
    bottom: 0;
    left: 0;
    top: auto;
    --bs-hero-partial-image-width: 100%;
    --bs-hero-partial-image-height: 420px;
    --bs-hero-partial-image-radius: 500px 500px 0 0;
    --bs-hero-partial-image-left-spacing: 0;
    --bs-hero-partial-image-top-spacing: 10px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1780px) {
  .hero-partial-image {
    --bs-hero-partial-image-width: 730px;
  }
}

.hero-8-contents {
  position: relative;
}

@media (min-width: 992px) {
  .hero-8-contents {
    top: -40px;
  }
}

.hero-image-rounded-1 {
  --bs-hero-image-border-radius: 150px;
  --bs-hero-image-height: 300px;
  border-radius: var(--bs-hero-image-border-radius);
  height: var(--bs-hero-image-height);
}

.hero-image-rounded-1 img {
  border-radius: var(--bs-hero-image-border-radius);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1199.98px) {
  .hero-image-rounded-1 {
    --bs-hero-image-height: 260px;
  }
}

@media (max-width: 575.98px) {
  .hero-image-rounded-1 {
    --bs-hero-image-height: 170px;
  }
}

.hero-image-rounded-2 {
  --bs-hero-image-border-radius: 116px;
  --bs-hero-image-height: 232px;
  --bs-hero-arrow-icon-spacing: -200px;
  --bs-video-popup-top-spacing: -35px;
  --bs-video-popup-right-spacing: 75px;
  border-radius: var(--bs-hero-image-border-radius);
  height: var(--bs-hero-image-height);
}

.hero-image-rounded-2 img {
  border-radius: var(--bs-hero-image-border-radius);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-image-rounded-2 .video-popup {
  top: var(--bs-video-popup-top-spacing);
  right: var(--bs-video-popup-right-spacing);
  left: auto;
}

.hero-image-rounded-2 .hero-arrow-icon {
  position: absolute;
  top: 0;
  left: var(--bs-hero-arrow-icon-spacing);
}

@media (max-width: 1399.98px) {
  .hero-image-rounded-2 {
    --bs-hero-image-height: 200px;
  }
}

@media (max-width: 1199.98px) {
  .hero-image-rounded-2 {
    --bs-hero-arrow-icon-spacing: -120px;
  }
}

@media (max-width: 991.98px) {
  .hero-image-rounded-2 .hero-arrow-icon {
    left: auto;
    right: var(--bs-hero-arrow-icon-spacing);
  }
}

@media (max-width: 575.98px) {
  .hero-image-rounded-2 {
    --bs-hero-image-height: 140px;
    --bs-video-popup-top-spacing: -25px;
    --bs-video-popup-right-spacing: 30px;
  }
  .hero-image-rounded-2 .video-popup.video-popup-style-1 {
    --bs-video-popup-area-size: 50px;
  }
  .hero-image-rounded-2 .video-popup.video-popup-style-1 svg {
    width: 18px;
  }
}

.arrow-image-2 {
  position: relative;
  top: 20px;
}

.hero-arrow-icon {
  --bs-hero-arrow-icon-color: #043544;
  --bs-hero-arrow-icon-size: 100px;
  --bs-hero-arrow-bg: var(--bs-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--bs-hero-arrow-icon-size);
  height: var(--bs-hero-arrow-icon-size);
  background-color: var(--bs-hero-arrow-bg);
  color: var(--bs-hero-arrow-icon-color);
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.hero-arrow-icon:hover {
  --bs-hero-arrow-icon-color: #fff;
}

@media (max-width: 1199.98px) {
  .hero-arrow-icon {
    --bs-hero-arrow-icon-size: 70px;
  }
  .hero-arrow-icon svg {
    width: 28px;
    height: 36px;
  }
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-prev {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-prev:hover {
  background-color: var(--bs-primary);
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-prev:hover .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-white);
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-next {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-next:hover {
  background-color: var(--bs-primary);
}

.testimonialSwiper-1-buttons .testimonialSwiper-1-button-next:hover .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-white);
}

.testimonialSwiper-1-buttons .chevron-right-icon {
  --bs-chevron-right-icon-stroke-color: var(--bs-primary);
}

.testimonial-author-image {
  height: 80px;
  width: 80px;
  opacity: .4;
  transition: all .4s ease-in-out;
}

.testimonial-author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.testimonial-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 760px;
  margin: 0 auto;
  margin-top: 1rem;
}

@media (max-width: 1199.98px) {
  .testimonial-details-wrapper {
    max-width: 580px;
    gap: 32px;
  }
}

.testimonial-author-image-wrapper {
  width: 120px;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-thumb-swiper {
  max-width: 384px;
}

.testimonial-thumb-swiper .swiper-slide-next, .testimonial-thumb-swiper .swiper-slide-prev {
  cursor: pointer;
}

.testimonial-slide-container {
  margin: auto;
}

.testimonial-thumb-swiper .swiper-slide-active .testimonial-author-image {
  height: 120px;
  width: 120px;
  opacity: 1;
}

.testimonial-thumb-swiper2 .swiper-slide-next .testimonial-author-details {
  opacity: 1;
  position: relative;
}

.testimonialSwiper-2-buttons {
  position: absolute;
  width: 100%;
  left: 70px;
  z-index: 1;
  justify-content: center;
  bottom: 60px;
}

@media (max-width: 991.98px) {
  .testimonialSwiper-2-buttons {
    left: auto;
    right: 0;
    bottom: 28%;
    justify-content: end;
    width: fit-content;
  }
}

@media (max-width: 767.98px) {
  .testimonialSwiper-2-buttons {
    bottom: 30%;
  }
}

.testimonial-2-image-wrapper {
  height: 400px;
  min-height: 400px;
}

.testimonial-2-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-2-author-image {
  width: 70px;
  height: 70px;
}

.testimonial-2-author-image img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-contents {
  position: relative;
}

@media (min-width: 992px) {
  .testimonial-contents {
    bottom: 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399.9px) {
  .testimonialSwiper-2-buttons {
    bottom: 36%;
  }
  .testimonialSwiper-2-buttons .arrow-icon-3.arrow-style-1 {
    width: 30px;
    height: 30px;
  }
  .testimonialSwiper-2-buttons .arrow-icon-3.arrow-style-1 svg {
    width: 16px;
  }
}

.testimonialSwiper-3-buttons .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-primary);
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.testimonialSwiper-3-buttons .arrow-icon-3 svg {
  stroke: var(--bs-arrow-icon-3-stroke-color);
  transition: all 0.2s ease-in-out;
}

.testimonialSwiper-3-buttons .testimonialSwiper-3-button-prev, .testimonialSwiper-3-buttons .testimonialSwiper-3-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--bs-primary);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}

.testimonialSwiper-3-buttons .testimonialSwiper-3-button-prev:hover, .testimonialSwiper-3-buttons .testimonialSwiper-3-button-next:hover {
  background-color: var(--bs-primary);
}

.testimonialSwiper-3-buttons .testimonialSwiper-3-button-prev:hover .arrow-icon-3, .testimonialSwiper-3-buttons .testimonialSwiper-3-button-next:hover .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-white);
}

.testimonialSwiper-swiper-pagination {
  position: absolute;
  bottom: -16px !important;
}

.testimonialSwiper-swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-color: var(--bs-primary);
}

.testimonialSwiper-swiper-pagination .swiper-pagination-bullet {
  --swiper-pagination-bullet-inactive-color: #D9D9D9;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.testimonial-3-wrapper {
  --bs-testimonial-3-wrapper-bg: var(--bs-body-bg);
  --bs-testimonial-author-name-color: var(--bs-primary);
  background-color: var(--bs-testimonial-3-wrapper-bg);
  box-shadow: 0 0rem 0.9rem rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all .6s ease-in-out;
}

.testimonial-3-wrapper .testimonial-3-author {
  color: var(--bs-testimonial-author-name-color);
  transition: all .6s ease-in-out;
}

.testimonial-3-wrapper .testimonial-details {
  transition: all .6s ease-in-out;
}

@media (max-width: 991.98px) {
  .testimonial-3-wrapper {
    padding: 16px;
  }
}

.swiper-slide-active .testimonial-3-wrapper.testimonial-active-style {
  --bs-testimonial-3-wrapper-bg: var(--bs-secondary);
  --bs-testimonial-author-name-color: var(--bs-white);
  box-shadow: none;
}

.swiper-slide-active .testimonial-3-wrapper.testimonial-active-style .quote-icon {
  --bs-quote-icon-bg: var(--bs-white);
  --bs-quote-icon-color: var(--bs-primary);
}

.swiper-slide-active .testimonial-3-wrapper.testimonial-active-style .testimonial-details {
  color: var(--bs-white);
}

.testimonial-5-contents {
  padding: 120px 0 180px 0;
}

@media (max-width: 1199.98px) {
  .testimonial-5-contents {
    padding: 100px 0 130px 0;
  }
}

@media (max-width: 767.98px) {
  .testimonial-5-contents {
    padding: 60px 0 100px 0;
  }
}

.testimonial-image-5 {
  --bs-testimonial-image-5-height: 430px;
  height: var(--bs-testimonial-image-5-height);
  min-height: var(--bs-testimonial-image-5-height);
  position: relative;
  z-index: 1;
}

.testimonial-image-5 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1399.98px) {
  .testimonial-image-5 {
    --bs-testimonial-image-5-height: 370px;
  }
}

@media (max-width: 1199.98px) {
  .testimonial-image-5 {
    --bs-testimonial-image-5-height: 360px;
  }
}

.testimonial-5-wrapper {
  --bs-testimonial-5-wrapper-width:  730px;
  --bs-quote-icon-lg-spacing: 140px;
  max-width: var(--bs-testimonial-5-wrapper-width);
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial-5-wrapper .quote-icon-lg {
  position: relative;
  right: var(--bs-quote-icon-lg-spacing);
}

@media (max-width: 1399.98px) {
  .testimonial-5-wrapper {
    --bs-testimonial-5-wrapper-width:  700px;
    --bs-quote-icon-lg-spacing: 200px;
  }
  .testimonial-5-wrapper .quote-icon-lg img {
    width: 200px;
  }
}

@media (max-width: 1199.98px) {
  .testimonial-5-wrapper {
    --bs-testimonial-5-wrapper-width:  640px;
  }
  .testimonial-5-wrapper .quote-icon-lg img {
    width: 170px;
  }
  .testimonial-5-wrapper .testimonial-3-wrapper {
    padding: 24px;
  }
}

@media (max-width: 991.98px) {
  .testimonial-5-wrapper {
    --bs-testimonial-5-wrapper-width: 500px;
    --bs-quote-icon-lg-spacing: 168px;
  }
  .testimonial-5-wrapper .quote-icon-lg img {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
}

@media (max-width: 767.98px) {
  .testimonial-5-wrapper {
    position: relative;
    --bs-quote-icon-lg-spacing: auto;
  }
  .testimonial-5-wrapper .quote-icon-lg img {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }
}

.testimonialSwiper-5-buttons {
  position: relative;
  top: 30px;
}

.testimonialSwiper-5-buttons .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-body-color);
  --bs-arrow-style-1-bg: transparent;
  --bs-arrow-style-1-size: 60px;
  --bs-arrow-style-1-radius: 4px;
  border: 2px solid var(--bs-body-color);
}

@media (max-width: 991.98px) {
  .testimonialSwiper-5-buttons .arrow-icon-3 {
    --bs-arrow-style-1-size: 50px;
  }
}

.testimonialSwiper-5-buttons .testimonialSwiper-2-button-next:hover .arrow-icon-3, .testimonialSwiper-5-buttons .testimonialSwiper-2-button-prev:hover .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-white);
  --bs-arrow-style-1-bg: var(--bs-primary);
  border: 2px solid var(--bs-primary);
}

.card-title, .post-list {
  --bs-link-color-rgb: var(--bs-heading-color-rgb);
  --bs-link-color: var(--bs-heading-color);
  --bs-link-hover-color: var(--bs-heading-color);
  --bs-link-hover-color-rgb: var(--bs-heading-color-rgb);
}

.card-title a, .post-list a {
  transition: all 0.2s ease-in-out;
}

.card .blog-custom-link {
  --bs-link-color: var(--bs-body-color);
  --bs-link-color-rgb: var(--bs-body-color-rgb);
  transition: all .4s ease-in-out;
}

.card:hover .blog-custom-link {
  --bs-link-color: var(--bs-primary);
  --bs-link-color-rgb: var(--bs-primary-rgb);
}

@media (max-width: 1399.98px) {
  .card .card-info {
    font-size: 16px;
  }
}

.card-style-1 {
  --bs-card-spacer-x: 0;
}

.card-style-1 .card-image-wrapper {
  height: 180.5px;
  min-height: 180.5px;
  overflow: hidden;
}

.card-style-1 .card-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

@media (max-width: 991.98px) {
  .card-style-1 .card-image-wrapper {
    width: 100%;
    height: 100%;
  }
}

.card-style-1:hover img {
  transform: scale(1.08);
}

.card-style-1 .card-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-style-2 {
  --bs-card-spacer-x: 0;
  --bs-card-bg: var(--bs-body-bg);
}

.card-style-2 .card-image-wrapper {
  height: 133px;
  min-height: 133px;
  overflow: hidden;
}

.card-style-2 .card-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

@media (max-width: 1399.98px) {
  .card-style-2 .card-image-wrapper {
    height: 159px;
    min-height: 159px;
  }
}

@media (max-width: 991.98px) {
  .card-style-2 .card-image-wrapper {
    width: 100%;
    height: 100%;
  }
}

.card-style-2 .card-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 355px;
}

@media (max-width: 1399.98px) {
  .card-style-2 .card-text {
    max-width: 310px;
  }
}

@media (max-width: 575.98px) {
  .card-style-2 .card-text {
    max-width: 282px;
  }
}

.card-style-2 .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: #9C9CA0;
  --bs-arrow-style-1-bg: var(--bs-body-color-style-3);
}

.card-style-2:hover img {
  transform: scale(1.08);
}

.card-style-2:hover .arrow-icon-3 {
  --bs-arrow-icon-3-stroke-color: var(--bs-white);
  --bs-arrow-style-1-bg: var(--bs-primary);
}

.card-style-2:hover .arrow-icon-3 svg {
  animation: toLeftFromRight .6s alternate-reverse;
}

.card-style-2:hover .card-title, .card-style-2:hover .post-list {
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
}

.card-custom-lists li {
  border-bottom: 2px solid var(--bs-body-color-style-3);
  padding-bottom: 30px;
}

.card-custom-lists li:last-child {
  border: none;
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  .card-custom-lists li {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  .card-custom-lists li:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.card-style-3 {
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-image-height: 300px;
  --bs-card-spacer-y: 20px;
  --bs-card-spacer-x: 0;
}

.card-style-3 .card-image-wrapper {
  height: var(--bs-card-image-height);
  min-height: var(--bs-card-image-height);
  overflow: hidden;
}

.card-style-3 .card-image-wrapper img {
  height: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

.card-style-3 .card-title, .card-style-3 p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-style-3.card-custom {
  --bs-card-spacer-x: 24px;
  --bs-card-image-height: 240px;
  box-shadow: 0 0.15rem 0.7rem rgba(0, 0, 0, 0.07);
}

@media (max-width: 575.98px) {
  .card-style-3.card-custom {
    --bs-card-spacer-x: 20px;
  }
}

.card-style-3.card-custom .border-10 {
  border-radius: 10px 10px 0 0;
}

.card-style-3.card-custom .border-10 img {
  border-radius: 10px 10px 0 0;
}

.card-style-3:hover img {
  transform: scale(1.08);
}

.card-style-4 {
  --bs-card-spacer-y: 30px;
  --bs-card-spacer-x: 24px;
  --bs-card-inner-border-radius: 0;
  --bs-card-img-height: 322px;
  border-radius: 10px;
}

.card-style-4 .card-image-wrapper {
  height: var(--bs-card-img-height);
  min-height: var(--bs-card-img-height);
}

.card-style-4 .card-image-wrapper img {
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.card-style-4 .card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-style-5 {
  --bs-card-spacer-y: 0;
  --bs-card-spacer-x: 0;
  --bs-card-img-height: 150px;
  padding: 10px;
}

.card-style-5 .card-image-wrapper {
  height: var(--bs-card-img-height);
  min-height: var(--bs-card-img-height);
  border-radius: 4px;
  overflow: hidden;
}

.card-style-5 .card-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: transform .5s ease-in-out;
}

.card-style-5:hover img {
  transform: scale(1.07);
}

.card-style-5 .card-title, .card-style-5 p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1399.98px) {
  .card-style-5 {
    --bs-card-img-height: 158px;
  }
}

@media (max-width: 1199.98px) {
  .card-style-5 {
    --bs-card-img-height: 177px;
  }
}

@media (max-width: 991.98px) {
  .card-style-5 {
    --bs-card-img-height: 200px;
  }
}

.card-style-6 {
  --bs-card-spacer-x: 0;
  --bs-card-spacer-y: 0;
  --bs-card-bg: var(--bs-body-bg);
}

.card-style-6 .card-image-wrapper {
  height: 80px;
  min-height: 80px;
  width: 104px;
  border-radius: 4px;
}

.card-style-6 .card-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

@media (max-width: 1399.98px) {
  .card-style-6 .card-image-wrapper {
    height: 110px;
    min-height: 110px;
    width: 84px;
  }
}

@media (max-width: 1199.98px) {
  .card-style-6 .card-image-wrapper {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .card-style-6 .card-image-wrapper {
    width: 100%;
    height: 100%;
  }
}

.card-style-7 {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-image-border: var(--bs-primary);
}

.card-style-7 .card-image-wrapper {
  height: 306px;
  min-height: 306px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.card-style-7 .card-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .5s ease-in-out;
}

.card-style-7 .card-image-border {
  border-bottom: 3px solid var(--bs-card-image-border);
}

.card-style-7 .card-title {
  --bs-link-color: var(--bs-white);
  --bs-link-color-rgb: var(--bs-white-rgb);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
}

.card-style-7 .card-body {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-style-7:hover {
  --bs-card-image-border: var(--bs-white);
}

.card-style-7:hover .btn-link-icon svg {
  transform: rotate(45deg);
}

.card-style-7:hover img {
  transform: scale(1.07);
}

.bg-custom-light .card-style-1, .bg-custom-light .card-style-2, .bg-custom-light .card-style-3 {
  --bs-card-bg: var(--bs-bg-custom-light);
}

.card-pricing {
  --bs-border-color: var(--bs-body-color-style-3);
  --bs-card-spacer-y: 30px;
  --bs-card-spacer-x: 30px;
  --bs-border-radius: 10px;
}

@media (max-width: 575.98px) {
  .card-pricing {
    --bs-card-spacer-x: 16px;
  }
}

.card-pricing.card-pricing-2 {
  --bs-card-spacer-y: 40px;
  --bs-card-spacer-x: 40px;
}

.card-pricing.card-pricing-2 .card-img-top {
  display: none;
}

.card-pricing.card-pricing-2.active .card-body {
  border-radius: 10px;
}

.card-pricing.card-pricing-2.active .card-body::before {
  background-image: none;
}

.card-pricing.card-pricing-3 {
  --bs-card-spacer-y: 60px;
  --bs-card-spacer-x: 60px;
}

.card-pricing.card-pricing-3 .pricing-list {
  padding: 0;
}

@media (max-width: 575.98px) {
  .card-pricing.card-pricing-3 {
    --bs-card-spacer-y: 40px;
    --bs-card-spacer-x: 30px;
  }
}

.card-pricing .card-img-top {
  height: 180px;
  object-fit: cover;
}

@media (max-width: 1199.98px) {
  .card-pricing .card-img-top {
    height: 250px;
  }
}

.card-pricing .pricing-amount {
  color: var(--bs-primary);
  transition: all .4s ease-in-out;
}

.card-pricing .pricing-quantity {
  font-family: "Lexend", sans-serif;
  transition: all .4s ease-in-out;
}

.card-pricing .card-body {
  transition: all .4s ease-in-out;
}

.card-pricing .pricing-list {
  position: relative;
  padding: 0 16px;
}

.card-pricing .pricing-list li {
  font-family: "Lexend", sans-serif;
}

@media (max-width: 575.98px) {
  .card-pricing .pricing-list {
    padding: 0 16px;
  }
  .card-pricing .pricing-list li {
    font-size: 16px;
  }
  .card-pricing .pricing-list .check-icon svg {
    width: 14px;
  }
}

.card-pricing.active {
  --bs-border-color: var(--bs-primary);
}

.card-pricing.active .card-body {
  background-color: rgba(var(--bs-secondary-rgb), 1);
  --bs-card-title-color: var(--bs-white);
  --bs-card-color: var(--bs-white);
  border-radius: 0 0 10px 10px;
  position: relative;
}

.card-pricing.active .card-body.card-body-bg-1 {
  --bs-card-body-bg: url('../images/pricing-bg-1.jpg');
}

.card-pricing.active .card-body.card-body-bg-2 {
  --bs-card-body-bg: url('../images/pricing-bg-2.jpg');
}

.card-pricing.active .card-body.card-body-bg-3 {
  --bs-card-body-bg: url('../images/pricing-bg-3.jpg');
}

.card-pricing.active .card-body .pricing-amount {
  color: var(--bs-white);
}

.card-pricing.active .card-body .pricing-quantity {
  color: var(--bs-primary);
}

.card-pricing.active .card-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--bs-card-body-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .1;
}

.card-pricing.active .card-body .btn-secondary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-white);
}

.card-pricing.active .btn-arrow {
  --bs-btn-arrow-bg: var(--bs-primary);
}

.card-pricing.active .btn-arrow svg {
  transform: rotate(45deg);
}

@media (max-width: 390px) {
  .card-pricing {
    --bs-card-spacer-x: 10px;
  }
  .card-pricing .pricing-list {
    padding: 0;
  }
  .card-pricing.card-pricing-2 {
    --bs-card-spacer-y: 30px;
    --bs-card-spacer-x: 16px;
  }
  .card-pricing.card-pricing-3 {
    --bs-card-spacer-y: 30px;
    --bs-card-spacer-x: 20px;
  }
}

@media (max-width: 349px) {
  .card-pricing.card-pricing-2 {
    --bs-card-spacer-y: 24px;
    --bs-card-spacer-x: 10px;
  }
  .card-pricing.card-pricing-3 {
    --bs-card-spacer-y: 24px;
    --bs-card-spacer-x: 16px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .card-pricing {
    --bs-card-spacer-x: 15px;
  }
  .card-pricing .pricing-list {
    padding: 0 6px;
  }
  .card-pricing.card-pricing-2 {
    --bs-card-spacer-x: 20px;
  }
  .card-pricing.card-pricing-3 {
    --bs-card-spacer-y: 50px;
    --bs-card-spacer-x: 24px;
  }
}

.form-control {
  --bs-border-width: 2px;
  --bs-border-color: var(--bs-body-color-style-1);
  --bs-border-radius: 4px;
  padding: 17.03px 24px;
  position: relative;
}

.form-control::placeholder {
  font-size: 16px;
}

.form-control.form-control-sm {
  padding: 4px 0;
  --bs-border-color: var(--bs-border-color-style-2);
  --bs-border-radius-sm: 0;
  border-width: 0 0 1px 0;
  border-color: var(--bs-border-color);
  border-style: solid;
  font-weight: 500;
}

.form-control.form-control-sm::placeholder {
  font-size: 16px;
  font-weight: 500;
}

.form-control.form-control-lg {
  padding: 10px 20px;
  --bs-border-color: var(--bs-border-color-style-1);
  --bs-border-radius-lg: 4px;
  font-size: 16px;
  font-weight: 300;
}

.form-control.form-control-lg::placeholder {
  font-size: 16px;
  font-weight: 300;
}

.form-control.form-control-xl {
  padding: 10px 20px 10px 44px;
  --bs-border-color: var(--bs-border-color-style-1);
  --bs-border-radius-lg: 4px;
  font-size: 16px;
  font-weight: 300;
}

.form-control.form-control-xl::placeholder {
  font-size: 16px;
  font-weight: 300;
}

.form-control.form-control-xxl {
  --bs-border-width: 0;
  padding: 22px 24px;
}

@media (max-width: 575.98px) {
  .form-control.form-control-xxl {
    padding: 22px 12px;
  }
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-control:focus + .form-icon {
  --bs-form-icon-stroke-color: var(--bs-primary);
  --bs-form-icon-color: var(--bs-primary);
}

.heading-style-label {
  font-family: var(--bs-font-family-style-1);
  font-weight: 500;
  color: var(--bs-heading-color);
}

.form-select {
  padding: 10px 20px;
  --bs-border-color: var(--bs-border-color-style-1);
  --bs-border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
}

.form-select::placeholder {
  font-size: 16px;
  font-weight: 300;
}

.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-select:focus + .form-icon {
  --bs-form-icon-stroke-color: var(--bs-primary);
}

.form-select.form-select-xl {
  padding: 10px 20px 10px 44px;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-heading {
  max-width: 288px;
}

.form-location-icon, .form-height-icon, .form-weight-icon {
  --bs-form-icon-color: var(--bs-body-color);
}

.form-location-icon svg, .form-height-icon svg, .form-weight-icon svg {
  color: var(--bs-form-icon-color);
}

.form-email-icon, .form-name-icon, .form-search-icon, .form-load-icon {
  --bs-form-icon-color: var(--bs-body-color);
}

.form-email-icon svg, .form-name-icon svg, .form-search-icon svg, .form-load-icon svg {
  stroke: var(--bs-form-icon-color);
}

.form-email-icon.form-name-icon-2, .form-name-icon.form-name-icon-2, .form-search-icon.form-name-icon-2, .form-load-icon.form-name-icon-2 {
  --bs-form-icon-color: var(--bs-primary);
}

.form-email-icon.form-name-icon-2 svg, .form-name-icon.form-name-icon-2 svg, .form-search-icon.form-name-icon-2 svg, .form-load-icon.form-name-icon-2 svg {
  position: relative;
  top: -2px;
}

.form-icon {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.form-icon svg {
  transition: all 0.2s ease-in-out;
}

.form-icon.form-icon-left {
  right: auto;
  left: 20px;
  height: 16px;
  bottom: 22px;
}

.form-check-input {
  --bs-border-color: var(--bs-body-color);
  --bs-form-check-input-size: 12px;
  width: var(--bs-form-check-input-size);
  min-width: var(--bs-form-check-input-size);
  height: var(--bs-form-check-input-size);
  margin-top: 0;
}

.form-check-input:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form-check-input:active {
  filter: brightness(100%);
}

.form-check-input.form-check-input-xl {
  --bs-form-check-input-size: 16px;
}

.form-check-input.form-check-input-xl:checked {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.form-check-input.form-check-input-xl:focus {
  border-color: var(--bs-secondary);
}

.form-check-input[type="checkbox"] {
  border-radius: 3px;
}

.units-check {
  --bs-units-check-top-spacing: 20px;
  position: relative;
  top: var(--bs-units-check-top-spacing);
}

@media (max-width: 767.98px) {
  .units-check {
    --bs-units-check-top-spacing: 0;
  }
}

.hs-code {
  color: var(--bs-primary);
  border-bottom: 1px solid var(--bs-primary);
  width: fit-content;
}

.input-group {
  --bs-border-color: var(--bs-border-color-style-1);
  border: 2px solid var(--bs-border-color);
  border-radius: 4px;
}

.input-group .chevron-right-icon {
  transform: rotate(90deg);
  --bs-chevron-right-icon-stroke-color: var(--bs-body-color);
}

.input-group .form-control {
  border: 0;
}

.input-group .form-control:focus {
  background-color: transparent;
}

.input-group .form-select-xl {
  padding: 0 !important;
}

.input-group.input-group-style-1 .form-select-xl {
  width: auto;
}

.input-group.input-group-style-2 .form-select-xl {
  width: 1%;
}

.input-group .star-separator {
  position: relative;
  top: 4px;
  left: -15px;
}

.input-group:focus-within {
  --bs-border-color: var(--bs-primary);
}

.input-group-btn {
  --bs-border-color: var(--bs-border-color-style-1);
  background-color: var(--bs-body-bg);
  border: none;
  font-weight: 300;
  color: var(--bs-body-color);
  position: relative;
}

.input-group-btn:after {
  content: "|";
  position: absolute;
  z-index: 99;
  color: red;
  background: red;
  left: 0;
  top: 0;
  width: 2px;
  height: 10px;
}

.line-separator {
  position: relative;
}

.line-separator::before {
  content: "";
  position: absolute;
  top: calc(50% - 9px);
  left: -17px;
  height: 18px;
  width: 1px;
  background: var(--bs-body-color);
  z-index: 1;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media screen and (min-width: 330px) and (max-width: 420px) {
  .input-group .form-control {
    padding: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.9px) {
  .input-group .form-control {
    padding: 10px 11px;
  }
}

.amount-type {
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 20px;
}

.form-check-custom-position {
  position: relative;
  top: 4px;
}

.map-image {
  --bg-parallax-image: url(../images/map-tile.png);
  --bg-parallax-opacity: 1;
  height: 320px;
}

.map-image.parallax::after {
  border-radius: 30px;
}

.map-image .map-marker {
  position: absolute;
  top: 60px;
  left: 42%;
  color: var(--bs-primary);
}

.map-image .map-marker.map-marker-style-1 {
  color: var(--bs-heading-color);
}

.map-image .map-marker svg {
  height: 40px;
  width: 40px;
}

.map-image.map-image-2 {
  --bg-parallax-image: url(../images/map-tile-2.jpg);
  --bg-parallax-opacity: 1;
  height: 575px;
}

@media (max-width: 767.98px) {
  .map-image.map-image-2 {
    height: 375px;
  }
}

.map-image.map-image-2 .map-popup-content, .map-image.map-image-2 .map-marker {
  display: none;
}

.map-image.map-image-2 .marker-content {
  position: absolute;
  top: 200px;
  left: 170px;
}

.map-image.map-image-2 .marker-content .map-popup-content, .map-image.map-image-2 .marker-content .map-marker {
  display: block;
  left: 0;
}

.map-image.map-image-2 .marker-content .map-marker {
  top: 0;
}

.map-image.map-image-2 .marker-content .map-popup-content {
  top: 50px;
  left: 12px;
  border: 2px solid rgba(255, 255, 255, 0.63);
  background: rgba(255, 255, 255, 0.8);
  padding: 32px 30px;
}

.map-image.map-image-2 .marker-content .map-popup-content p {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .map-image.map-image-2 .marker-content {
    top: 80px;
    left: 125px;
  }
}

@media (max-width: 575.98px) {
  .map-image.map-image-2 .marker-content {
    top: 80px;
    left: 40px;
  }
}

.map-popup-content {
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.63);
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(5px);
  width: 240px;
  height: auto;
  padding: 8px 16px 14px 12px;
  position: absolute;
  left: 47%;
  top: 110px;
}

.map-popup-content h3, .map-popup-content .h3, .map-popup-content p {
  color: var(--bs-heading-color);
  font-weight: 600;
}

.map-popup-content p {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .map-popup-content {
    left: calc(50% - 120px);
  }
}

@media screen and (min-width: 991px) and (max-width: 1399px) {
  .map-popup-content {
    left: 28%;
  }
}

.eventiva-marker-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--bs-primary);
}

.leaflet-popup-close-button {
  top: 5px !important;
  right: 6px !important;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.leaflet-popup-close-button span {
  position: relative;
  bottom: 1px;
  color: var(--bs-heading-color);
  transition: all 0.2s ease-in-out;
}

.leaflet-popup-close-button:hover span {
  color: var(--bs-primary);
}

.leaflet-pane {
  z-index: 40;
}

.leaflet-top, .leaflet-bottom {
  z-index: 50;
}

.leaflet-routing-collapse-btn {
  right: 30px;
  color: var(--bs-primary);
  cursor: pointer;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  left: 2px;
  top: -1px;
  width: 26px;
  height: 23px;
  background-repeat: no-repeat;
}

#RoutingMap {
  width: 100%;
  min-height: calc(100vh - 250px);
}

.modal#RoutingMapModal {
  --bs-modal-width: 100%;
  z-index: 999999;
}

.modal#RoutingMapModal .eventiva-destination-marker-icon {
  margin-left: -12px;
  margin-top: -35px;
}

.modal#RoutingMapModal .eventiva-destination-marker-icon svg {
  fill: var(--bs-primary);
}

.modal#RoutingMapModal .eventiva-current-marker-icon svg {
  fill: green;
}

.routing-map-modal img.leaflet-marker-icon {
  opacity: 0;
}

.leaflet-container {
  font-family: var(--bs-font-family-style-1);
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(var(--bs-primary-rgb), 0.7);
  background-clip: padding-box;
}

.leaflet-routing-container {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
}

.leaflet-popup-content {
  min-width: 184px;
  margin: 0;
}

@media (min-width: 1200px) {
  .direction-style-2 {
    position: relative;
    left: -38px;
  }
}

@media (min-width: 1400px) {
  .direction-style-2 {
    position: relative;
    left: -18px;
  }
}

@media (min-width: 1450px) {
  .direction-style-2 {
    position: relative;
    left: -60px;
  }
}

.header-landing .navbar {
  --bs-navbar-color: var(--bs-heading-color);
}

.header-landing .nav-link {
  --bs-nav-link-font-weight: 600;
}

.header-landing .nav-link:hover {
  --bs-nav-link-font-weight: 600;
}

.shadow-on-hover {
  position: relative;
  overflow: hidden;
}

.shadow-on-hover img {
  transition: transform .4s ease-in-out;
}

.shadow-on-hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bs-secondary-rgb), 0.3);
  opacity: 0;
  transition: opacity .4s ease-in-out;
}

.shadow-on-hover:hover::after {
  opacity: 1;
  transform: scale(1.02);
}

.shadow-on-hover:hover img {
  transform: scale(1.02);
}

.hero-landing {
  --bs-hero-landing-spacing: 140px 0 500px 0;
  border-bottom: 10px solid var(--bs-primary);
}

.hero-landing .hero-inner {
  position: relative;
  padding: var(--bs-hero-landing-spacing);
  z-index: 1;
}

.hero-landing .hero-wrapper {
  --bg-parallax-image: url(../images/landing-page/hero-landing-bg.jpg);
  --bg-parallax-opacity: 1;
}

@media (max-width: 1399.98px) {
  .hero-landing {
    --bs-hero-landing-spacing: 120px 0 450px 0;
  }
}

@media (max-width: 1199.98px) {
  .hero-landing {
    --bs-hero-landing-spacing: 100px 0 380px 0;
  }
}

@media (max-width: 991.98px) {
  .hero-landing {
    --bs-hero-landing-spacing: 100px 0 340px 0;
  }
}

@media (max-width: 767.98px) {
  .hero-landing {
    border-bottom: 5px solid var(--bs-primary);
  }
}

@media (max-width: 575.98px) {
  .hero-landing {
    --bs-hero-landing-spacing: 100px 0 210px 0;
  }
}

.hero-inner-image {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  max-width: 1600px;
}

@media (max-width: 991.98px) {
  .arrow-image {
    width: 130px;
  }
}

@media (max-width: 767.98px) {
  .arrow-image {
    width: 110px;
  }
}

.demo-title {
  transition: all 530ms ease-in-out;
}

.demo-image {
  --bs-demo-image-bg: var(--bs-body-color-style-3);
  background-color: var(--bs-demo-image-bg);
  position: relative;
  top: 0;
  transition: all 400ms ease-in-out;
  border-radius: 4px;
}

.demo-image img {
  border-radius: 2px;
}

.demo-wrapper {
  --bs-link-color-rgb: var(--bs-black-rgb);
  --bs-link-color: var(--bs-black);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
  width: fit-content;
}

.demo-wrapper:hover .demo-image {
  top: -12px;
}

.demo-wrapper:hover .demo-title {
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-color: var(--bs-primary);
}

.demoSwiper-wrapper {
  --swiper-theme-color: var(--bs-primary);
}

.demoSwiper-wrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -55px;
}

@media (max-width: 991.98px) {
  .demoSwiper-wrapper .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -45px;
  }
}

.feature-wrapper {
  --bs-feature-wrapper-bg: var(--bs-body-color-style-3);
  --bs-feature-inner-bg: var(--bs-body-bg);
  border-radius: 10px;
  background-color: var(--bs-feature-wrapper-bg);
  width: 410px;
  height: 360px;
  overflow: hidden;
  padding: 20px;
}

.feature-wrapper .feature-inner {
  background-color: var(--bs-feature-inner-bg);
  height: 100%;
  border-radius: 8px;
}

.feature-wrapper img {
  transition: transform .4s ease-in-out;
}

.feature-wrapper .feature-title {
  transition: all .4s ease-in-out;
}

.feature-wrapper:hover img {
  transform: scale(1.07);
}

.feature-wrapper:hover .feature-title {
  --bs-heading-color: var(--bs-primary);
}

@media (max-width: 1399.98px) {
  .feature-wrapper {
    width: 350px;
    height: 310px;
  }
}

@media (max-width: 1199.98px) {
  .feature-wrapper {
    width: 290px;
    height: 280px;
  }
  .feature-wrapper img {
    max-width: 190px;
    object-fit: cover;
  }
  .feature-wrapper .feature-title {
    font-size: 30px;
  }
}

@media (max-width: 991.98px) {
  .feature-wrapper {
    width: 332px;
    height: 282px;
  }
  .feature-wrapper img {
    max-width: 210px;
    object-fit: cover;
  }
}

@media (max-width: 767.98px) {
  .feature-wrapper {
    width: 242px;
    height: 240px;
  }
  .feature-wrapper img {
    max-width: 180px;
    max-height: 95px;
    object-fit: cover;
  }
  .feature-wrapper .feature-title {
    font-size: 26px;
  }
}

@media (max-width: 575.98px) {
  .feature-wrapper {
    width: 100%;
  }
  .feature-wrapper img {
    max-width: 100%;
  }
  .feature-wrapper .feature-title {
    font-size: 30px;
  }
}

.support-text {
  font-size: 64px;
}

@media (max-width: 1399.98px) {
  .support-text {
    font-size: 60px;
  }
}

@media (max-width: 1199.98px) {
  .support-text {
    font-size: 52px;
  }
}

@media (max-width: 991.98px) {
  .support-text {
    font-size: 45px;
  }
}

@media (max-width: 767.98px) {
  .support-text {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .support-text {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .support-image {
    width: 200px;
  }
}

@media (max-width: 575.98px) {
  .support-image {
    width: 175px;
  }
}

.faq-landing {
  border-top: 10px solid var(--bs-primary);
  border-bottom: 10px solid var(--bs-primary);
}

@media (max-width: 767.98px) {
  .faq-landing {
    border-top: 5px solid var(--bs-primary);
    border-bottom: 5px solid var(--bs-primary);
  }
}

@media (max-width: 1399.98px) {
  .landing-inner-heading {
    font-size: 52px;
  }
}

@media (max-width: 1199.98px) {
  .landing-inner-heading {
    font-size: 45px;
  }
}

@media (max-width: 991.98px) {
  .landing-inner-heading {
    font-size: 38px;
  }
}

@media (max-width: 767.98px) {
  .landing-inner-heading {
    font-size: 35px;
  }
}

@media (max-width: 575.98px) {
  .landing-inner-heading {
    font-size: 30px;
  }
}

.section-responsive {
  border-top: 10px solid var(--bs-primary);
  border-bottom: 10px solid var(--bs-primary);
}

@media (max-width: 767.98px) {
  .section-responsive {
    border-top: 5px solid var(--bs-primary);
    border-bottom: 5px solid var(--bs-primary);
  }
}

.inner-page-features {
  border-bottom: 10px solid var(--bs-primary);
}

@media (max-width: 767.98px) {
  .inner-page-features {
    border-bottom: 5px solid var(--bs-primary);
  }
}

.section-inner-pages {
  border-bottom: 10px solid var(--bs-primary);
}

@media (max-width: 767.98px) {
  .section-inner-pages {
    border-bottom: 5px solid var(--bs-primary);
  }
}

.section-footer {
  --bs-link-color-rgb: var(--bs-body-color-style-1-rgb);
  --bs-link-color: var(--bs-body-color-style-1);
  --bs-link-hover-color: var(--bs-body-color-style-1);
  --bs-link-hover-color-rgb: var(--bs-body-color-style-1-rgb);
  --bs-copyright-text-color: var(--bs-body-color-style-1);
}

.section-footer.footer-landing {
  --bs-copyright-text-color: var(--bs-secondary);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-link-color: var(--bs-secondary);
  --bs-link-hover-color: var(--bs-primary);
  --bs-link-hover-color-rgb: var(--bs-primary-rgb);
}

.footer-separator {
  border-bottom: 1px solid var(--bs-body-color);
}

@media (min-width: 1200px) {
  .footer-subscription {
    max-width: 341px;
  }
}

.copyright-text {
  font-size: 16px;
  font-weight: 300;
  color: var(--bs-copyright-text-color);
}

.copyright-text-style-2 {
  font-size: 18px;
  font-weight: 500;
}

.arrow-icon-wrapper {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--bs-primary);
  border: 0;
}

@media (min-width: 768px) {
  .footer-address {
    max-width: 230px;
  }
}

.footer-form-wrapper .arrow-icon-wrapper {
  transition: all .4s ease-in-out;
}

.footer-form-wrapper .arrow-icon-wrapper:hover {
  background-color: var(--bs-secondary);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-secondary {
  color: #fff;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-success {
  color: #fff;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-info {
  color: #000;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-warning {
  color: #000;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-danger {
  color: #fff;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-light {
  color: #000;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1));
}

.text-bg-dark {
  color: #fff;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1));
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1));
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1));
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
}

.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
}

.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1));
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75));
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.m-7 {
  margin: 0.4375rem;
}

.m-10 {
  margin: 0.625rem;
}

.m-20 {
  margin: 1.25rem;
}

.m-30 {
  margin: 1.875rem;
}

.m-35 {
  margin: 2.1875rem;
}

.m-40 {
  margin: 2.5rem;
}

.m-60 {
  margin: 3.75rem;
}

.m-70 {
  margin: 4.375rem;
}

.m-80 {
  margin: 5rem;
}

.m-90 {
  margin: 5.625rem;
}

.m-100 {
  margin: 6.25rem;
}

.m-120 {
  margin: 7.5rem;
}

.m-130 {
  margin: 8.125rem;
}

.m-140 {
  margin: 8.75rem;
}

.m-150 {
  margin: 9.375rem;
}

.m-160 {
  margin: 10rem;
}

.m-200 {
  margin: 12.5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-3 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-4 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-5 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mx-7 {
  margin-right: 0.4375rem;
  margin-left: 0.4375rem;
}

.mx-10 {
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}

.mx-20 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.mx-30 {
  margin-right: 1.875rem;
  margin-left: 1.875rem;
}

.mx-35 {
  margin-right: 2.1875rem;
  margin-left: 2.1875rem;
}

.mx-40 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.mx-60 {
  margin-right: 3.75rem;
  margin-left: 3.75rem;
}

.mx-70 {
  margin-right: 4.375rem;
  margin-left: 4.375rem;
}

.mx-80 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-90 {
  margin-right: 5.625rem;
  margin-left: 5.625rem;
}

.mx-100 {
  margin-right: 6.25rem;
  margin-left: 6.25rem;
}

.mx-120 {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}

.mx-130 {
  margin-right: 8.125rem;
  margin-left: 8.125rem;
}

.mx-140 {
  margin-right: 8.75rem;
  margin-left: 8.75rem;
}

.mx-150 {
  margin-right: 9.375rem;
  margin-left: 9.375rem;
}

.mx-160 {
  margin-right: 10rem;
  margin-left: 10rem;
}

.mx-200 {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-7 {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}

.my-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.my-35 {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
}

.my-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-60 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.my-70 {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
}

.my-80 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-90 {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem;
}

.my-100 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.my-120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.my-130 {
  margin-top: 8.125rem;
  margin-bottom: 8.125rem;
}

.my-140 {
  margin-top: 8.75rem;
  margin-bottom: 8.75rem;
}

.my-150 {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
}

.my-160 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-200 {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 0.4375rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-30 {
  margin-top: 1.875rem;
}

.mt-35 {
  margin-top: 2.1875rem;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mt-60 {
  margin-top: 3.75rem;
}

.mt-70 {
  margin-top: 4.375rem;
}

.mt-80 {
  margin-top: 5rem;
}

.mt-90 {
  margin-top: 5.625rem;
}

.mt-100 {
  margin-top: 6.25rem;
}

.mt-120 {
  margin-top: 7.5rem;
}

.mt-130 {
  margin-top: 8.125rem;
}

.mt-140 {
  margin-top: 8.75rem;
}

.mt-150 {
  margin-top: 9.375rem;
}

.mt-160 {
  margin-top: 10rem;
}

.mt-200 {
  margin-top: 12.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 0.25rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.me-3 {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 1.5rem;
}

.me-5 {
  margin-right: 3rem;
}

.me-7 {
  margin-right: 0.4375rem;
}

.me-10 {
  margin-right: 0.625rem;
}

.me-20 {
  margin-right: 1.25rem;
}

.me-30 {
  margin-right: 1.875rem;
}

.me-35 {
  margin-right: 2.1875rem;
}

.me-40 {
  margin-right: 2.5rem;
}

.me-60 {
  margin-right: 3.75rem;
}

.me-70 {
  margin-right: 4.375rem;
}

.me-80 {
  margin-right: 5rem;
}

.me-90 {
  margin-right: 5.625rem;
}

.me-100 {
  margin-right: 6.25rem;
}

.me-120 {
  margin-right: 7.5rem;
}

.me-130 {
  margin-right: 8.125rem;
}

.me-140 {
  margin-right: 8.75rem;
}

.me-150 {
  margin-right: 9.375rem;
}

.me-160 {
  margin-right: 10rem;
}

.me-200 {
  margin-right: 12.5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 0.4375rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.mb-35 {
  margin-bottom: 2.1875rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.mb-60 {
  margin-bottom: 3.75rem;
}

.mb-70 {
  margin-bottom: 4.375rem;
}

.mb-80 {
  margin-bottom: 5rem;
}

.mb-90 {
  margin-bottom: 5.625rem;
}

.mb-100 {
  margin-bottom: 6.25rem;
}

.mb-120 {
  margin-bottom: 7.5rem;
}

.mb-130 {
  margin-bottom: 8.125rem;
}

.mb-140 {
  margin-bottom: 8.75rem;
}

.mb-150 {
  margin-bottom: 9.375rem;
}

.mb-160 {
  margin-bottom: 10rem;
}

.mb-200 {
  margin-bottom: 12.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 0.25rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.ms-5 {
  margin-left: 3rem;
}

.ms-7 {
  margin-left: 0.4375rem;
}

.ms-10 {
  margin-left: 0.625rem;
}

.ms-20 {
  margin-left: 1.25rem;
}

.ms-30 {
  margin-left: 1.875rem;
}

.ms-35 {
  margin-left: 2.1875rem;
}

.ms-40 {
  margin-left: 2.5rem;
}

.ms-60 {
  margin-left: 3.75rem;
}

.ms-70 {
  margin-left: 4.375rem;
}

.ms-80 {
  margin-left: 5rem;
}

.ms-90 {
  margin-left: 5.625rem;
}

.ms-100 {
  margin-left: 6.25rem;
}

.ms-120 {
  margin-left: 7.5rem;
}

.ms-130 {
  margin-left: 8.125rem;
}

.ms-140 {
  margin-left: 8.75rem;
}

.ms-150 {
  margin-left: 9.375rem;
}

.ms-160 {
  margin-left: 10rem;
}

.ms-200 {
  margin-left: 12.5rem;
}

.ms-auto {
  margin-left: auto;
}

.m-n1 {
  margin: -0.25rem;
}

.m-n2 {
  margin: -0.5rem;
}

.m-n3 {
  margin: -1rem;
}

.m-n4 {
  margin: -1.5rem;
}

.m-n5 {
  margin: -3rem;
}

.m-n7 {
  margin: -0.4375rem;
}

.m-n10 {
  margin: -0.625rem;
}

.m-n20 {
  margin: -1.25rem;
}

.m-n30 {
  margin: -1.875rem;
}

.m-n35 {
  margin: -2.1875rem;
}

.m-n40 {
  margin: -2.5rem;
}

.m-n60 {
  margin: -3.75rem;
}

.m-n70 {
  margin: -4.375rem;
}

.m-n80 {
  margin: -5rem;
}

.m-n90 {
  margin: -5.625rem;
}

.m-n100 {
  margin: -6.25rem;
}

.m-n120 {
  margin: -7.5rem;
}

.m-n130 {
  margin: -8.125rem;
}

.m-n140 {
  margin: -8.75rem;
}

.m-n150 {
  margin: -9.375rem;
}

.m-n160 {
  margin: -10rem;
}

.m-n200 {
  margin: -12.5rem;
}

.mx-n1 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.mx-n2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mx-n3 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.mx-n4 {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.mx-n5 {
  margin-right: -3rem;
  margin-left: -3rem;
}

.mx-n7 {
  margin-right: -0.4375rem;
  margin-left: -0.4375rem;
}

.mx-n10 {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.mx-n20 {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.mx-n30 {
  margin-right: -1.875rem;
  margin-left: -1.875rem;
}

.mx-n35 {
  margin-right: -2.1875rem;
  margin-left: -2.1875rem;
}

.mx-n40 {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.mx-n60 {
  margin-right: -3.75rem;
  margin-left: -3.75rem;
}

.mx-n70 {
  margin-right: -4.375rem;
  margin-left: -4.375rem;
}

.mx-n80 {
  margin-right: -5rem;
  margin-left: -5rem;
}

.mx-n90 {
  margin-right: -5.625rem;
  margin-left: -5.625rem;
}

.mx-n100 {
  margin-right: -6.25rem;
  margin-left: -6.25rem;
}

.mx-n120 {
  margin-right: -7.5rem;
  margin-left: -7.5rem;
}

.mx-n130 {
  margin-right: -8.125rem;
  margin-left: -8.125rem;
}

.mx-n140 {
  margin-right: -8.75rem;
  margin-left: -8.75rem;
}

.mx-n150 {
  margin-right: -9.375rem;
  margin-left: -9.375rem;
}

.mx-n160 {
  margin-right: -10rem;
  margin-left: -10rem;
}

.mx-n200 {
  margin-right: -12.5rem;
  margin-left: -12.5rem;
}

.my-n1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.my-n2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.my-n3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.my-n4 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.my-n5 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.my-n7 {
  margin-top: -0.4375rem;
  margin-bottom: -0.4375rem;
}

.my-n10 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.my-n20 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.my-n30 {
  margin-top: -1.875rem;
  margin-bottom: -1.875rem;
}

.my-n35 {
  margin-top: -2.1875rem;
  margin-bottom: -2.1875rem;
}

.my-n40 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.my-n60 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.my-n70 {
  margin-top: -4.375rem;
  margin-bottom: -4.375rem;
}

.my-n80 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.my-n90 {
  margin-top: -5.625rem;
  margin-bottom: -5.625rem;
}

.my-n100 {
  margin-top: -6.25rem;
  margin-bottom: -6.25rem;
}

.my-n120 {
  margin-top: -7.5rem;
  margin-bottom: -7.5rem;
}

.my-n130 {
  margin-top: -8.125rem;
  margin-bottom: -8.125rem;
}

.my-n140 {
  margin-top: -8.75rem;
  margin-bottom: -8.75rem;
}

.my-n150 {
  margin-top: -9.375rem;
  margin-bottom: -9.375rem;
}

.my-n160 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.my-n200 {
  margin-top: -12.5rem;
  margin-bottom: -12.5rem;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mt-n7 {
  margin-top: -0.4375rem;
}

.mt-n10 {
  margin-top: -0.625rem;
}

.mt-n20 {
  margin-top: -1.25rem;
}

.mt-n30 {
  margin-top: -1.875rem;
}

.mt-n35 {
  margin-top: -2.1875rem;
}

.mt-n40 {
  margin-top: -2.5rem;
}

.mt-n60 {
  margin-top: -3.75rem;
}

.mt-n70 {
  margin-top: -4.375rem;
}

.mt-n80 {
  margin-top: -5rem;
}

.mt-n90 {
  margin-top: -5.625rem;
}

.mt-n100 {
  margin-top: -6.25rem;
}

.mt-n120 {
  margin-top: -7.5rem;
}

.mt-n130 {
  margin-top: -8.125rem;
}

.mt-n140 {
  margin-top: -8.75rem;
}

.mt-n150 {
  margin-top: -9.375rem;
}

.mt-n160 {
  margin-top: -10rem;
}

.mt-n200 {
  margin-top: -12.5rem;
}

.me-n1 {
  margin-right: -0.25rem;
}

.me-n2 {
  margin-right: -0.5rem;
}

.me-n3 {
  margin-right: -1rem;
}

.me-n4 {
  margin-right: -1.5rem;
}

.me-n5 {
  margin-right: -3rem;
}

.me-n7 {
  margin-right: -0.4375rem;
}

.me-n10 {
  margin-right: -0.625rem;
}

.me-n20 {
  margin-right: -1.25rem;
}

.me-n30 {
  margin-right: -1.875rem;
}

.me-n35 {
  margin-right: -2.1875rem;
}

.me-n40 {
  margin-right: -2.5rem;
}

.me-n60 {
  margin-right: -3.75rem;
}

.me-n70 {
  margin-right: -4.375rem;
}

.me-n80 {
  margin-right: -5rem;
}

.me-n90 {
  margin-right: -5.625rem;
}

.me-n100 {
  margin-right: -6.25rem;
}

.me-n120 {
  margin-right: -7.5rem;
}

.me-n130 {
  margin-right: -8.125rem;
}

.me-n140 {
  margin-right: -8.75rem;
}

.me-n150 {
  margin-right: -9.375rem;
}

.me-n160 {
  margin-right: -10rem;
}

.me-n200 {
  margin-right: -12.5rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-n7 {
  margin-bottom: -0.4375rem;
}

.mb-n10 {
  margin-bottom: -0.625rem;
}

.mb-n20 {
  margin-bottom: -1.25rem;
}

.mb-n30 {
  margin-bottom: -1.875rem;
}

.mb-n35 {
  margin-bottom: -2.1875rem;
}

.mb-n40 {
  margin-bottom: -2.5rem;
}

.mb-n60 {
  margin-bottom: -3.75rem;
}

.mb-n70 {
  margin-bottom: -4.375rem;
}

.mb-n80 {
  margin-bottom: -5rem;
}

.mb-n90 {
  margin-bottom: -5.625rem;
}

.mb-n100 {
  margin-bottom: -6.25rem;
}

.mb-n120 {
  margin-bottom: -7.5rem;
}

.mb-n130 {
  margin-bottom: -8.125rem;
}

.mb-n140 {
  margin-bottom: -8.75rem;
}

.mb-n150 {
  margin-bottom: -9.375rem;
}

.mb-n160 {
  margin-bottom: -10rem;
}

.mb-n200 {
  margin-bottom: -12.5rem;
}

.ms-n1 {
  margin-left: -0.25rem;
}

.ms-n2 {
  margin-left: -0.5rem;
}

.ms-n3 {
  margin-left: -1rem;
}

.ms-n4 {
  margin-left: -1.5rem;
}

.ms-n5 {
  margin-left: -3rem;
}

.ms-n7 {
  margin-left: -0.4375rem;
}

.ms-n10 {
  margin-left: -0.625rem;
}

.ms-n20 {
  margin-left: -1.25rem;
}

.ms-n30 {
  margin-left: -1.875rem;
}

.ms-n35 {
  margin-left: -2.1875rem;
}

.ms-n40 {
  margin-left: -2.5rem;
}

.ms-n60 {
  margin-left: -3.75rem;
}

.ms-n70 {
  margin-left: -4.375rem;
}

.ms-n80 {
  margin-left: -5rem;
}

.ms-n90 {
  margin-left: -5.625rem;
}

.ms-n100 {
  margin-left: -6.25rem;
}

.ms-n120 {
  margin-left: -7.5rem;
}

.ms-n130 {
  margin-left: -8.125rem;
}

.ms-n140 {
  margin-left: -8.75rem;
}

.ms-n150 {
  margin-left: -9.375rem;
}

.ms-n160 {
  margin-left: -10rem;
}

.ms-n200 {
  margin-left: -12.5rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.p-7 {
  padding: 0.4375rem;
}

.p-10 {
  padding: 0.625rem;
}

.p-20 {
  padding: 1.25rem;
}

.p-30 {
  padding: 1.875rem;
}

.p-35 {
  padding: 2.1875rem;
}

.p-40 {
  padding: 2.5rem;
}

.p-60 {
  padding: 3.75rem;
}

.p-70 {
  padding: 4.375rem;
}

.p-80 {
  padding: 5rem;
}

.p-90 {
  padding: 5.625rem;
}

.p-100 {
  padding: 6.25rem;
}

.p-120 {
  padding: 7.5rem;
}

.p-130 {
  padding: 8.125rem;
}

.p-140 {
  padding: 8.75rem;
}

.p-150 {
  padding: 9.375rem;
}

.p-160 {
  padding: 10rem;
}

.p-200 {
  padding: 12.5rem;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-3 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-5 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-7 {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
}

.px-10 {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.px-20 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-30 {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.px-35 {
  padding-right: 2.1875rem;
  padding-left: 2.1875rem;
}

.px-40 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-60 {
  padding-right: 3.75rem;
  padding-left: 3.75rem;
}

.px-70 {
  padding-right: 4.375rem;
  padding-left: 4.375rem;
}

.px-80 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-90 {
  padding-right: 5.625rem;
  padding-left: 5.625rem;
}

.px-100 {
  padding-right: 6.25rem;
  padding-left: 6.25rem;
}

.px-120 {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}

.px-130 {
  padding-right: 8.125rem;
  padding-left: 8.125rem;
}

.px-140 {
  padding-right: 8.75rem;
  padding-left: 8.75rem;
}

.px-150 {
  padding-right: 9.375rem;
  padding-left: 9.375rem;
}

.px-160 {
  padding-right: 10rem;
  padding-left: 10rem;
}

.px-200 {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-7 {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.py-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.py-35 {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}

.py-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-60 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.py-70 {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

.py-80 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-90 {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
}

.py-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.py-120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.py-130 {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
}

.py-140 {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
}

.py-150 {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
}

.py-160 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-200 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pt-7 {
  padding-top: 0.4375rem;
}

.pt-10 {
  padding-top: 0.625rem;
}

.pt-20 {
  padding-top: 1.25rem;
}

.pt-30 {
  padding-top: 1.875rem;
}

.pt-35 {
  padding-top: 2.1875rem;
}

.pt-40 {
  padding-top: 2.5rem;
}

.pt-60 {
  padding-top: 3.75rem;
}

.pt-70 {
  padding-top: 4.375rem;
}

.pt-80 {
  padding-top: 5rem;
}

.pt-90 {
  padding-top: 5.625rem;
}

.pt-100 {
  padding-top: 6.25rem;
}

.pt-120 {
  padding-top: 7.5rem;
}

.pt-130 {
  padding-top: 8.125rem;
}

.pt-140 {
  padding-top: 8.75rem;
}

.pt-150 {
  padding-top: 9.375rem;
}

.pt-160 {
  padding-top: 10rem;
}

.pt-200 {
  padding-top: 12.5rem;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 0.25rem;
}

.pe-2 {
  padding-right: 0.5rem;
}

.pe-3 {
  padding-right: 1rem;
}

.pe-4 {
  padding-right: 1.5rem;
}

.pe-5 {
  padding-right: 3rem;
}

.pe-7 {
  padding-right: 0.4375rem;
}

.pe-10 {
  padding-right: 0.625rem;
}

.pe-20 {
  padding-right: 1.25rem;
}

.pe-30 {
  padding-right: 1.875rem;
}

.pe-35 {
  padding-right: 2.1875rem;
}

.pe-40 {
  padding-right: 2.5rem;
}

.pe-60 {
  padding-right: 3.75rem;
}

.pe-70 {
  padding-right: 4.375rem;
}

.pe-80 {
  padding-right: 5rem;
}

.pe-90 {
  padding-right: 5.625rem;
}

.pe-100 {
  padding-right: 6.25rem;
}

.pe-120 {
  padding-right: 7.5rem;
}

.pe-130 {
  padding-right: 8.125rem;
}

.pe-140 {
  padding-right: 8.75rem;
}

.pe-150 {
  padding-right: 9.375rem;
}

.pe-160 {
  padding-right: 10rem;
}

.pe-200 {
  padding-right: 12.5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.pb-7 {
  padding-bottom: 0.4375rem;
}

.pb-10 {
  padding-bottom: 0.625rem;
}

.pb-20 {
  padding-bottom: 1.25rem;
}

.pb-30 {
  padding-bottom: 1.875rem;
}

.pb-35 {
  padding-bottom: 2.1875rem;
}

.pb-40 {
  padding-bottom: 2.5rem;
}

.pb-60 {
  padding-bottom: 3.75rem;
}

.pb-70 {
  padding-bottom: 4.375rem;
}

.pb-80 {
  padding-bottom: 5rem;
}

.pb-90 {
  padding-bottom: 5.625rem;
}

.pb-100 {
  padding-bottom: 6.25rem;
}

.pb-120 {
  padding-bottom: 7.5rem;
}

.pb-130 {
  padding-bottom: 8.125rem;
}

.pb-140 {
  padding-bottom: 8.75rem;
}

.pb-150 {
  padding-bottom: 9.375rem;
}

.pb-160 {
  padding-bottom: 10rem;
}

.pb-200 {
  padding-bottom: 12.5rem;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 0.25rem;
}

.ps-2 {
  padding-left: 0.5rem;
}

.ps-3 {
  padding-left: 1rem;
}

.ps-4 {
  padding-left: 1.5rem;
}

.ps-5 {
  padding-left: 3rem;
}

.ps-7 {
  padding-left: 0.4375rem;
}

.ps-10 {
  padding-left: 0.625rem;
}

.ps-20 {
  padding-left: 1.25rem;
}

.ps-30 {
  padding-left: 1.875rem;
}

.ps-35 {
  padding-left: 2.1875rem;
}

.ps-40 {
  padding-left: 2.5rem;
}

.ps-60 {
  padding-left: 3.75rem;
}

.ps-70 {
  padding-left: 4.375rem;
}

.ps-80 {
  padding-left: 5rem;
}

.ps-90 {
  padding-left: 5.625rem;
}

.ps-100 {
  padding-left: 6.25rem;
}

.ps-120 {
  padding-left: 7.5rem;
}

.ps-130 {
  padding-left: 8.125rem;
}

.ps-140 {
  padding-left: 8.75rem;
}

.ps-150 {
  padding-left: 9.375rem;
}

.ps-160 {
  padding-left: 10rem;
}

.ps-200 {
  padding-left: 12.5rem;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.gap-7 {
  gap: 0.4375rem;
}

.gap-10 {
  gap: 0.625rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-30 {
  gap: 1.875rem;
}

.gap-35 {
  gap: 2.1875rem;
}

.gap-40 {
  gap: 2.5rem;
}

.gap-60 {
  gap: 3.75rem;
}

.gap-70 {
  gap: 4.375rem;
}

.gap-80 {
  gap: 5rem;
}

.gap-90 {
  gap: 5.625rem;
}

.gap-100 {
  gap: 6.25rem;
}

.gap-120 {
  gap: 7.5rem;
}

.gap-130 {
  gap: 8.125rem;
}

.gap-140 {
  gap: 8.75rem;
}

.gap-150 {
  gap: 9.375rem;
}

.gap-160 {
  gap: 10rem;
}

.gap-200 {
  gap: 12.5rem;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 0.25rem;
}

.row-gap-2 {
  row-gap: 0.5rem;
}

.row-gap-3 {
  row-gap: 1rem;
}

.row-gap-4 {
  row-gap: 1.5rem;
}

.row-gap-5 {
  row-gap: 3rem;
}

.row-gap-7 {
  row-gap: 0.4375rem;
}

.row-gap-10 {
  row-gap: 0.625rem;
}

.row-gap-20 {
  row-gap: 1.25rem;
}

.row-gap-30 {
  row-gap: 1.875rem;
}

.row-gap-35 {
  row-gap: 2.1875rem;
}

.row-gap-40 {
  row-gap: 2.5rem;
}

.row-gap-60 {
  row-gap: 3.75rem;
}

.row-gap-70 {
  row-gap: 4.375rem;
}

.row-gap-80 {
  row-gap: 5rem;
}

.row-gap-90 {
  row-gap: 5.625rem;
}

.row-gap-100 {
  row-gap: 6.25rem;
}

.row-gap-120 {
  row-gap: 7.5rem;
}

.row-gap-130 {
  row-gap: 8.125rem;
}

.row-gap-140 {
  row-gap: 8.75rem;
}

.row-gap-150 {
  row-gap: 9.375rem;
}

.row-gap-160 {
  row-gap: 10rem;
}

.row-gap-200 {
  row-gap: 12.5rem;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 0.25rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.column-gap-3 {
  column-gap: 1rem;
}

.column-gap-4 {
  column-gap: 1.5rem;
}

.column-gap-5 {
  column-gap: 3rem;
}

.column-gap-7 {
  column-gap: 0.4375rem;
}

.column-gap-10 {
  column-gap: 0.625rem;
}

.column-gap-20 {
  column-gap: 1.25rem;
}

.column-gap-30 {
  column-gap: 1.875rem;
}

.column-gap-35 {
  column-gap: 2.1875rem;
}

.column-gap-40 {
  column-gap: 2.5rem;
}

.column-gap-60 {
  column-gap: 3.75rem;
}

.column-gap-70 {
  column-gap: 4.375rem;
}

.column-gap-80 {
  column-gap: 5rem;
}

.column-gap-90 {
  column-gap: 5.625rem;
}

.column-gap-100 {
  column-gap: 6.25rem;
}

.column-gap-120 {
  column-gap: 7.5rem;
}

.column-gap-130 {
  column-gap: 8.125rem;
}

.column-gap-140 {
  column-gap: 8.75rem;
}

.column-gap-150 {
  column-gap: 9.375rem;
}

.column-gap-160 {
  column-gap: 10rem;
}

.column-gap-200 {
  column-gap: 12.5rem;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.59375rem + 4.125vw);
}

.fs-2 {
  font-size: calc(1.525rem + 3.3vw);
}

.fs-3 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-4 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-5 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-6 {
  font-size: 1.125rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.444444;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color);
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis);
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}

.link-offset-2 {
  text-underline-offset: 0.25em;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}

.link-offset-3 {
  text-underline-offset: 0.375em;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity));
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity));
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity));
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity));
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity));
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity));
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity));
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm);
  border-top-right-radius: var(--bs-border-radius-sm);
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl);
  border-top-right-radius: var(--bs-border-radius-xxl);
}

.rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill);
  border-top-right-radius: var(--bs-border-radius-pill);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm);
  border-bottom-right-radius: var(--bs-border-radius-sm);
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl);
  border-bottom-right-radius: var(--bs-border-radius-xxl);
}

.rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill);
  border-bottom-right-radius: var(--bs-border-radius-pill);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm);
  border-bottom-left-radius: var(--bs-border-radius-sm);
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-bottom-left-radius: var(--bs-border-radius-xl);
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl);
  border-bottom-left-radius: var(--bs-border-radius-xxl);
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill);
  border-bottom-left-radius: var(--bs-border-radius-pill);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm);
  border-top-left-radius: var(--bs-border-radius-sm);
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl);
  border-top-left-radius: var(--bs-border-radius-xxl);
}

.rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill);
  border-top-left-radius: var(--bs-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-5 {
  z-index: 5;
}

.z-9 {
  z-index: 9;
}

.z-99 {
  z-index: 99;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .object-fit-sm-contain {
    object-fit: contain;
  }
  .object-fit-sm-cover {
    object-fit: cover;
  }
  .object-fit-sm-fill {
    object-fit: fill;
  }
  .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .object-fit-sm-none {
    object-fit: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-inline-grid {
    display: inline-grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 0.25rem;
  }
  .m-sm-2 {
    margin: 0.5rem;
  }
  .m-sm-3 {
    margin: 1rem;
  }
  .m-sm-4 {
    margin: 1.5rem;
  }
  .m-sm-5 {
    margin: 3rem;
  }
  .m-sm-7 {
    margin: 0.4375rem;
  }
  .m-sm-10 {
    margin: 0.625rem;
  }
  .m-sm-20 {
    margin: 1.25rem;
  }
  .m-sm-30 {
    margin: 1.875rem;
  }
  .m-sm-35 {
    margin: 2.1875rem;
  }
  .m-sm-40 {
    margin: 2.5rem;
  }
  .m-sm-60 {
    margin: 3.75rem;
  }
  .m-sm-70 {
    margin: 4.375rem;
  }
  .m-sm-80 {
    margin: 5rem;
  }
  .m-sm-90 {
    margin: 5.625rem;
  }
  .m-sm-100 {
    margin: 6.25rem;
  }
  .m-sm-120 {
    margin: 7.5rem;
  }
  .m-sm-130 {
    margin: 8.125rem;
  }
  .m-sm-140 {
    margin: 8.75rem;
  }
  .m-sm-150 {
    margin: 9.375rem;
  }
  .m-sm-160 {
    margin: 10rem;
  }
  .m-sm-200 {
    margin: 12.5rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-sm-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-sm-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-sm-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-sm-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-sm-7 {
    margin-right: 0.4375rem;
    margin-left: 0.4375rem;
  }
  .mx-sm-10 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-sm-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-sm-30 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-sm-35 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-sm-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-sm-60 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-sm-70 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-sm-80 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-sm-90 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-sm-100 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-sm-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-sm-130 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-sm-140 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-sm-150 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-sm-160 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-sm-200 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-sm-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-sm-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-sm-7 {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }
  .my-sm-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-sm-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-sm-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-sm-35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-sm-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-sm-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-sm-70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-sm-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-sm-90 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-sm-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-sm-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-sm-130 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-sm-140 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-sm-150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-sm-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-sm-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 0.25rem;
  }
  .mt-sm-2 {
    margin-top: 0.5rem;
  }
  .mt-sm-3 {
    margin-top: 1rem;
  }
  .mt-sm-4 {
    margin-top: 1.5rem;
  }
  .mt-sm-5 {
    margin-top: 3rem;
  }
  .mt-sm-7 {
    margin-top: 0.4375rem;
  }
  .mt-sm-10 {
    margin-top: 0.625rem;
  }
  .mt-sm-20 {
    margin-top: 1.25rem;
  }
  .mt-sm-30 {
    margin-top: 1.875rem;
  }
  .mt-sm-35 {
    margin-top: 2.1875rem;
  }
  .mt-sm-40 {
    margin-top: 2.5rem;
  }
  .mt-sm-60 {
    margin-top: 3.75rem;
  }
  .mt-sm-70 {
    margin-top: 4.375rem;
  }
  .mt-sm-80 {
    margin-top: 5rem;
  }
  .mt-sm-90 {
    margin-top: 5.625rem;
  }
  .mt-sm-100 {
    margin-top: 6.25rem;
  }
  .mt-sm-120 {
    margin-top: 7.5rem;
  }
  .mt-sm-130 {
    margin-top: 8.125rem;
  }
  .mt-sm-140 {
    margin-top: 8.75rem;
  }
  .mt-sm-150 {
    margin-top: 9.375rem;
  }
  .mt-sm-160 {
    margin-top: 10rem;
  }
  .mt-sm-200 {
    margin-top: 12.5rem;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-1 {
    margin-right: 0.25rem;
  }
  .me-sm-2 {
    margin-right: 0.5rem;
  }
  .me-sm-3 {
    margin-right: 1rem;
  }
  .me-sm-4 {
    margin-right: 1.5rem;
  }
  .me-sm-5 {
    margin-right: 3rem;
  }
  .me-sm-7 {
    margin-right: 0.4375rem;
  }
  .me-sm-10 {
    margin-right: 0.625rem;
  }
  .me-sm-20 {
    margin-right: 1.25rem;
  }
  .me-sm-30 {
    margin-right: 1.875rem;
  }
  .me-sm-35 {
    margin-right: 2.1875rem;
  }
  .me-sm-40 {
    margin-right: 2.5rem;
  }
  .me-sm-60 {
    margin-right: 3.75rem;
  }
  .me-sm-70 {
    margin-right: 4.375rem;
  }
  .me-sm-80 {
    margin-right: 5rem;
  }
  .me-sm-90 {
    margin-right: 5.625rem;
  }
  .me-sm-100 {
    margin-right: 6.25rem;
  }
  .me-sm-120 {
    margin-right: 7.5rem;
  }
  .me-sm-130 {
    margin-right: 8.125rem;
  }
  .me-sm-140 {
    margin-right: 8.75rem;
  }
  .me-sm-150 {
    margin-right: 9.375rem;
  }
  .me-sm-160 {
    margin-right: 10rem;
  }
  .me-sm-200 {
    margin-right: 12.5rem;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem;
  }
  .mb-sm-5 {
    margin-bottom: 3rem;
  }
  .mb-sm-7 {
    margin-bottom: 0.4375rem;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem;
  }
  .mb-sm-30 {
    margin-bottom: 1.875rem;
  }
  .mb-sm-35 {
    margin-bottom: 2.1875rem;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-60 {
    margin-bottom: 3.75rem;
  }
  .mb-sm-70 {
    margin-bottom: 4.375rem;
  }
  .mb-sm-80 {
    margin-bottom: 5rem;
  }
  .mb-sm-90 {
    margin-bottom: 5.625rem;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem;
  }
  .mb-sm-120 {
    margin-bottom: 7.5rem;
  }
  .mb-sm-130 {
    margin-bottom: 8.125rem;
  }
  .mb-sm-140 {
    margin-bottom: 8.75rem;
  }
  .mb-sm-150 {
    margin-bottom: 9.375rem;
  }
  .mb-sm-160 {
    margin-bottom: 10rem;
  }
  .mb-sm-200 {
    margin-bottom: 12.5rem;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-1 {
    margin-left: 0.25rem;
  }
  .ms-sm-2 {
    margin-left: 0.5rem;
  }
  .ms-sm-3 {
    margin-left: 1rem;
  }
  .ms-sm-4 {
    margin-left: 1.5rem;
  }
  .ms-sm-5 {
    margin-left: 3rem;
  }
  .ms-sm-7 {
    margin-left: 0.4375rem;
  }
  .ms-sm-10 {
    margin-left: 0.625rem;
  }
  .ms-sm-20 {
    margin-left: 1.25rem;
  }
  .ms-sm-30 {
    margin-left: 1.875rem;
  }
  .ms-sm-35 {
    margin-left: 2.1875rem;
  }
  .ms-sm-40 {
    margin-left: 2.5rem;
  }
  .ms-sm-60 {
    margin-left: 3.75rem;
  }
  .ms-sm-70 {
    margin-left: 4.375rem;
  }
  .ms-sm-80 {
    margin-left: 5rem;
  }
  .ms-sm-90 {
    margin-left: 5.625rem;
  }
  .ms-sm-100 {
    margin-left: 6.25rem;
  }
  .ms-sm-120 {
    margin-left: 7.5rem;
  }
  .ms-sm-130 {
    margin-left: 8.125rem;
  }
  .ms-sm-140 {
    margin-left: 8.75rem;
  }
  .ms-sm-150 {
    margin-left: 9.375rem;
  }
  .ms-sm-160 {
    margin-left: 10rem;
  }
  .ms-sm-200 {
    margin-left: 12.5rem;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .m-sm-n1 {
    margin: -0.25rem;
  }
  .m-sm-n2 {
    margin: -0.5rem;
  }
  .m-sm-n3 {
    margin: -1rem;
  }
  .m-sm-n4 {
    margin: -1.5rem;
  }
  .m-sm-n5 {
    margin: -3rem;
  }
  .m-sm-n7 {
    margin: -0.4375rem;
  }
  .m-sm-n10 {
    margin: -0.625rem;
  }
  .m-sm-n20 {
    margin: -1.25rem;
  }
  .m-sm-n30 {
    margin: -1.875rem;
  }
  .m-sm-n35 {
    margin: -2.1875rem;
  }
  .m-sm-n40 {
    margin: -2.5rem;
  }
  .m-sm-n60 {
    margin: -3.75rem;
  }
  .m-sm-n70 {
    margin: -4.375rem;
  }
  .m-sm-n80 {
    margin: -5rem;
  }
  .m-sm-n90 {
    margin: -5.625rem;
  }
  .m-sm-n100 {
    margin: -6.25rem;
  }
  .m-sm-n120 {
    margin: -7.5rem;
  }
  .m-sm-n130 {
    margin: -8.125rem;
  }
  .m-sm-n140 {
    margin: -8.75rem;
  }
  .m-sm-n150 {
    margin: -9.375rem;
  }
  .m-sm-n160 {
    margin: -10rem;
  }
  .m-sm-n200 {
    margin: -12.5rem;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-sm-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-sm-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-sm-n7 {
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
  }
  .mx-sm-n10 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-sm-n30 {
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .mx-sm-n35 {
    margin-right: -2.1875rem;
    margin-left: -2.1875rem;
  }
  .mx-sm-n40 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-sm-n60 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-sm-n70 {
    margin-right: -4.375rem;
    margin-left: -4.375rem;
  }
  .mx-sm-n80 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-sm-n90 {
    margin-right: -5.625rem;
    margin-left: -5.625rem;
  }
  .mx-sm-n100 {
    margin-right: -6.25rem;
    margin-left: -6.25rem;
  }
  .mx-sm-n120 {
    margin-right: -7.5rem;
    margin-left: -7.5rem;
  }
  .mx-sm-n130 {
    margin-right: -8.125rem;
    margin-left: -8.125rem;
  }
  .mx-sm-n140 {
    margin-right: -8.75rem;
    margin-left: -8.75rem;
  }
  .mx-sm-n150 {
    margin-right: -9.375rem;
    margin-left: -9.375rem;
  }
  .mx-sm-n160 {
    margin-right: -10rem;
    margin-left: -10rem;
  }
  .mx-sm-n200 {
    margin-right: -12.5rem;
    margin-left: -12.5rem;
  }
  .my-sm-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-sm-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-sm-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-sm-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-sm-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-sm-n7 {
    margin-top: -0.4375rem;
    margin-bottom: -0.4375rem;
  }
  .my-sm-n10 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .my-sm-n20 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-sm-n30 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .my-sm-n35 {
    margin-top: -2.1875rem;
    margin-bottom: -2.1875rem;
  }
  .my-sm-n40 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-sm-n60 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-sm-n70 {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
  .my-sm-n80 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-sm-n90 {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem;
  }
  .my-sm-n100 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
  .my-sm-n120 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .my-sm-n130 {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem;
  }
  .my-sm-n140 {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem;
  }
  .my-sm-n150 {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem;
  }
  .my-sm-n160 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .my-sm-n200 {
    margin-top: -12.5rem;
    margin-bottom: -12.5rem;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem;
  }
  .mt-sm-n3 {
    margin-top: -1rem;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem;
  }
  .mt-sm-n5 {
    margin-top: -3rem;
  }
  .mt-sm-n7 {
    margin-top: -0.4375rem;
  }
  .mt-sm-n10 {
    margin-top: -0.625rem;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem;
  }
  .mt-sm-n30 {
    margin-top: -1.875rem;
  }
  .mt-sm-n35 {
    margin-top: -2.1875rem;
  }
  .mt-sm-n40 {
    margin-top: -2.5rem;
  }
  .mt-sm-n60 {
    margin-top: -3.75rem;
  }
  .mt-sm-n70 {
    margin-top: -4.375rem;
  }
  .mt-sm-n80 {
    margin-top: -5rem;
  }
  .mt-sm-n90 {
    margin-top: -5.625rem;
  }
  .mt-sm-n100 {
    margin-top: -6.25rem;
  }
  .mt-sm-n120 {
    margin-top: -7.5rem;
  }
  .mt-sm-n130 {
    margin-top: -8.125rem;
  }
  .mt-sm-n140 {
    margin-top: -8.75rem;
  }
  .mt-sm-n150 {
    margin-top: -9.375rem;
  }
  .mt-sm-n160 {
    margin-top: -10rem;
  }
  .mt-sm-n200 {
    margin-top: -12.5rem;
  }
  .me-sm-n1 {
    margin-right: -0.25rem;
  }
  .me-sm-n2 {
    margin-right: -0.5rem;
  }
  .me-sm-n3 {
    margin-right: -1rem;
  }
  .me-sm-n4 {
    margin-right: -1.5rem;
  }
  .me-sm-n5 {
    margin-right: -3rem;
  }
  .me-sm-n7 {
    margin-right: -0.4375rem;
  }
  .me-sm-n10 {
    margin-right: -0.625rem;
  }
  .me-sm-n20 {
    margin-right: -1.25rem;
  }
  .me-sm-n30 {
    margin-right: -1.875rem;
  }
  .me-sm-n35 {
    margin-right: -2.1875rem;
  }
  .me-sm-n40 {
    margin-right: -2.5rem;
  }
  .me-sm-n60 {
    margin-right: -3.75rem;
  }
  .me-sm-n70 {
    margin-right: -4.375rem;
  }
  .me-sm-n80 {
    margin-right: -5rem;
  }
  .me-sm-n90 {
    margin-right: -5.625rem;
  }
  .me-sm-n100 {
    margin-right: -6.25rem;
  }
  .me-sm-n120 {
    margin-right: -7.5rem;
  }
  .me-sm-n130 {
    margin-right: -8.125rem;
  }
  .me-sm-n140 {
    margin-right: -8.75rem;
  }
  .me-sm-n150 {
    margin-right: -9.375rem;
  }
  .me-sm-n160 {
    margin-right: -10rem;
  }
  .me-sm-n200 {
    margin-right: -12.5rem;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem;
  }
  .mb-sm-n7 {
    margin-bottom: -0.4375rem;
  }
  .mb-sm-n10 {
    margin-bottom: -0.625rem;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem;
  }
  .mb-sm-n30 {
    margin-bottom: -1.875rem;
  }
  .mb-sm-n35 {
    margin-bottom: -2.1875rem;
  }
  .mb-sm-n40 {
    margin-bottom: -2.5rem;
  }
  .mb-sm-n60 {
    margin-bottom: -3.75rem;
  }
  .mb-sm-n70 {
    margin-bottom: -4.375rem;
  }
  .mb-sm-n80 {
    margin-bottom: -5rem;
  }
  .mb-sm-n90 {
    margin-bottom: -5.625rem;
  }
  .mb-sm-n100 {
    margin-bottom: -6.25rem;
  }
  .mb-sm-n120 {
    margin-bottom: -7.5rem;
  }
  .mb-sm-n130 {
    margin-bottom: -8.125rem;
  }
  .mb-sm-n140 {
    margin-bottom: -8.75rem;
  }
  .mb-sm-n150 {
    margin-bottom: -9.375rem;
  }
  .mb-sm-n160 {
    margin-bottom: -10rem;
  }
  .mb-sm-n200 {
    margin-bottom: -12.5rem;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem;
  }
  .ms-sm-n3 {
    margin-left: -1rem;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem;
  }
  .ms-sm-n5 {
    margin-left: -3rem;
  }
  .ms-sm-n7 {
    margin-left: -0.4375rem;
  }
  .ms-sm-n10 {
    margin-left: -0.625rem;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem;
  }
  .ms-sm-n30 {
    margin-left: -1.875rem;
  }
  .ms-sm-n35 {
    margin-left: -2.1875rem;
  }
  .ms-sm-n40 {
    margin-left: -2.5rem;
  }
  .ms-sm-n60 {
    margin-left: -3.75rem;
  }
  .ms-sm-n70 {
    margin-left: -4.375rem;
  }
  .ms-sm-n80 {
    margin-left: -5rem;
  }
  .ms-sm-n90 {
    margin-left: -5.625rem;
  }
  .ms-sm-n100 {
    margin-left: -6.25rem;
  }
  .ms-sm-n120 {
    margin-left: -7.5rem;
  }
  .ms-sm-n130 {
    margin-left: -8.125rem;
  }
  .ms-sm-n140 {
    margin-left: -8.75rem;
  }
  .ms-sm-n150 {
    margin-left: -9.375rem;
  }
  .ms-sm-n160 {
    margin-left: -10rem;
  }
  .ms-sm-n200 {
    margin-left: -12.5rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-1 {
    padding: 0.25rem;
  }
  .p-sm-2 {
    padding: 0.5rem;
  }
  .p-sm-3 {
    padding: 1rem;
  }
  .p-sm-4 {
    padding: 1.5rem;
  }
  .p-sm-5 {
    padding: 3rem;
  }
  .p-sm-7 {
    padding: 0.4375rem;
  }
  .p-sm-10 {
    padding: 0.625rem;
  }
  .p-sm-20 {
    padding: 1.25rem;
  }
  .p-sm-30 {
    padding: 1.875rem;
  }
  .p-sm-35 {
    padding: 2.1875rem;
  }
  .p-sm-40 {
    padding: 2.5rem;
  }
  .p-sm-60 {
    padding: 3.75rem;
  }
  .p-sm-70 {
    padding: 4.375rem;
  }
  .p-sm-80 {
    padding: 5rem;
  }
  .p-sm-90 {
    padding: 5.625rem;
  }
  .p-sm-100 {
    padding: 6.25rem;
  }
  .p-sm-120 {
    padding: 7.5rem;
  }
  .p-sm-130 {
    padding: 8.125rem;
  }
  .p-sm-140 {
    padding: 8.75rem;
  }
  .p-sm-150 {
    padding: 9.375rem;
  }
  .p-sm-160 {
    padding: 10rem;
  }
  .p-sm-200 {
    padding: 12.5rem;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-sm-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-sm-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-sm-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-sm-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-sm-7 {
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
  }
  .px-sm-10 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-sm-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-sm-30 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-sm-35 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-sm-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-sm-60 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-sm-70 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-sm-80 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-sm-90 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-sm-100 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-sm-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-sm-130 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-sm-140 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-sm-150 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-sm-160 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-sm-200 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-sm-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-sm-7 {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .py-sm-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-sm-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-sm-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-sm-35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-sm-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-sm-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-sm-70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-sm-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-sm-90 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-sm-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-sm-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-sm-130 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-sm-140 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-sm-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-sm-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-sm-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-1 {
    padding-top: 0.25rem;
  }
  .pt-sm-2 {
    padding-top: 0.5rem;
  }
  .pt-sm-3 {
    padding-top: 1rem;
  }
  .pt-sm-4 {
    padding-top: 1.5rem;
  }
  .pt-sm-5 {
    padding-top: 3rem;
  }
  .pt-sm-7 {
    padding-top: 0.4375rem;
  }
  .pt-sm-10 {
    padding-top: 0.625rem;
  }
  .pt-sm-20 {
    padding-top: 1.25rem;
  }
  .pt-sm-30 {
    padding-top: 1.875rem;
  }
  .pt-sm-35 {
    padding-top: 2.1875rem;
  }
  .pt-sm-40 {
    padding-top: 2.5rem;
  }
  .pt-sm-60 {
    padding-top: 3.75rem;
  }
  .pt-sm-70 {
    padding-top: 4.375rem;
  }
  .pt-sm-80 {
    padding-top: 5rem;
  }
  .pt-sm-90 {
    padding-top: 5.625rem;
  }
  .pt-sm-100 {
    padding-top: 6.25rem;
  }
  .pt-sm-120 {
    padding-top: 7.5rem;
  }
  .pt-sm-130 {
    padding-top: 8.125rem;
  }
  .pt-sm-140 {
    padding-top: 8.75rem;
  }
  .pt-sm-150 {
    padding-top: 9.375rem;
  }
  .pt-sm-160 {
    padding-top: 10rem;
  }
  .pt-sm-200 {
    padding-top: 12.5rem;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-1 {
    padding-right: 0.25rem;
  }
  .pe-sm-2 {
    padding-right: 0.5rem;
  }
  .pe-sm-3 {
    padding-right: 1rem;
  }
  .pe-sm-4 {
    padding-right: 1.5rem;
  }
  .pe-sm-5 {
    padding-right: 3rem;
  }
  .pe-sm-7 {
    padding-right: 0.4375rem;
  }
  .pe-sm-10 {
    padding-right: 0.625rem;
  }
  .pe-sm-20 {
    padding-right: 1.25rem;
  }
  .pe-sm-30 {
    padding-right: 1.875rem;
  }
  .pe-sm-35 {
    padding-right: 2.1875rem;
  }
  .pe-sm-40 {
    padding-right: 2.5rem;
  }
  .pe-sm-60 {
    padding-right: 3.75rem;
  }
  .pe-sm-70 {
    padding-right: 4.375rem;
  }
  .pe-sm-80 {
    padding-right: 5rem;
  }
  .pe-sm-90 {
    padding-right: 5.625rem;
  }
  .pe-sm-100 {
    padding-right: 6.25rem;
  }
  .pe-sm-120 {
    padding-right: 7.5rem;
  }
  .pe-sm-130 {
    padding-right: 8.125rem;
  }
  .pe-sm-140 {
    padding-right: 8.75rem;
  }
  .pe-sm-150 {
    padding-right: 9.375rem;
  }
  .pe-sm-160 {
    padding-right: 10rem;
  }
  .pe-sm-200 {
    padding-right: 12.5rem;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
  }
  .pb-sm-3 {
    padding-bottom: 1rem;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem;
  }
  .pb-sm-5 {
    padding-bottom: 3rem;
  }
  .pb-sm-7 {
    padding-bottom: 0.4375rem;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem;
  }
  .pb-sm-30 {
    padding-bottom: 1.875rem;
  }
  .pb-sm-35 {
    padding-bottom: 2.1875rem;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-60 {
    padding-bottom: 3.75rem;
  }
  .pb-sm-70 {
    padding-bottom: 4.375rem;
  }
  .pb-sm-80 {
    padding-bottom: 5rem;
  }
  .pb-sm-90 {
    padding-bottom: 5.625rem;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem;
  }
  .pb-sm-120 {
    padding-bottom: 7.5rem;
  }
  .pb-sm-130 {
    padding-bottom: 8.125rem;
  }
  .pb-sm-140 {
    padding-bottom: 8.75rem;
  }
  .pb-sm-150 {
    padding-bottom: 9.375rem;
  }
  .pb-sm-160 {
    padding-bottom: 10rem;
  }
  .pb-sm-200 {
    padding-bottom: 12.5rem;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-1 {
    padding-left: 0.25rem;
  }
  .ps-sm-2 {
    padding-left: 0.5rem;
  }
  .ps-sm-3 {
    padding-left: 1rem;
  }
  .ps-sm-4 {
    padding-left: 1.5rem;
  }
  .ps-sm-5 {
    padding-left: 3rem;
  }
  .ps-sm-7 {
    padding-left: 0.4375rem;
  }
  .ps-sm-10 {
    padding-left: 0.625rem;
  }
  .ps-sm-20 {
    padding-left: 1.25rem;
  }
  .ps-sm-30 {
    padding-left: 1.875rem;
  }
  .ps-sm-35 {
    padding-left: 2.1875rem;
  }
  .ps-sm-40 {
    padding-left: 2.5rem;
  }
  .ps-sm-60 {
    padding-left: 3.75rem;
  }
  .ps-sm-70 {
    padding-left: 4.375rem;
  }
  .ps-sm-80 {
    padding-left: 5rem;
  }
  .ps-sm-90 {
    padding-left: 5.625rem;
  }
  .ps-sm-100 {
    padding-left: 6.25rem;
  }
  .ps-sm-120 {
    padding-left: 7.5rem;
  }
  .ps-sm-130 {
    padding-left: 8.125rem;
  }
  .ps-sm-140 {
    padding-left: 8.75rem;
  }
  .ps-sm-150 {
    padding-left: 9.375rem;
  }
  .ps-sm-160 {
    padding-left: 10rem;
  }
  .ps-sm-200 {
    padding-left: 12.5rem;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-1 {
    gap: 0.25rem;
  }
  .gap-sm-2 {
    gap: 0.5rem;
  }
  .gap-sm-3 {
    gap: 1rem;
  }
  .gap-sm-4 {
    gap: 1.5rem;
  }
  .gap-sm-5 {
    gap: 3rem;
  }
  .gap-sm-7 {
    gap: 0.4375rem;
  }
  .gap-sm-10 {
    gap: 0.625rem;
  }
  .gap-sm-20 {
    gap: 1.25rem;
  }
  .gap-sm-30 {
    gap: 1.875rem;
  }
  .gap-sm-35 {
    gap: 2.1875rem;
  }
  .gap-sm-40 {
    gap: 2.5rem;
  }
  .gap-sm-60 {
    gap: 3.75rem;
  }
  .gap-sm-70 {
    gap: 4.375rem;
  }
  .gap-sm-80 {
    gap: 5rem;
  }
  .gap-sm-90 {
    gap: 5.625rem;
  }
  .gap-sm-100 {
    gap: 6.25rem;
  }
  .gap-sm-120 {
    gap: 7.5rem;
  }
  .gap-sm-130 {
    gap: 8.125rem;
  }
  .gap-sm-140 {
    gap: 8.75rem;
  }
  .gap-sm-150 {
    gap: 9.375rem;
  }
  .gap-sm-160 {
    gap: 10rem;
  }
  .gap-sm-200 {
    gap: 12.5rem;
  }
  .row-gap-sm-0 {
    row-gap: 0;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem;
  }
  .row-gap-sm-3 {
    row-gap: 1rem;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem;
  }
  .row-gap-sm-5 {
    row-gap: 3rem;
  }
  .row-gap-sm-7 {
    row-gap: 0.4375rem;
  }
  .row-gap-sm-10 {
    row-gap: 0.625rem;
  }
  .row-gap-sm-20 {
    row-gap: 1.25rem;
  }
  .row-gap-sm-30 {
    row-gap: 1.875rem;
  }
  .row-gap-sm-35 {
    row-gap: 2.1875rem;
  }
  .row-gap-sm-40 {
    row-gap: 2.5rem;
  }
  .row-gap-sm-60 {
    row-gap: 3.75rem;
  }
  .row-gap-sm-70 {
    row-gap: 4.375rem;
  }
  .row-gap-sm-80 {
    row-gap: 5rem;
  }
  .row-gap-sm-90 {
    row-gap: 5.625rem;
  }
  .row-gap-sm-100 {
    row-gap: 6.25rem;
  }
  .row-gap-sm-120 {
    row-gap: 7.5rem;
  }
  .row-gap-sm-130 {
    row-gap: 8.125rem;
  }
  .row-gap-sm-140 {
    row-gap: 8.75rem;
  }
  .row-gap-sm-150 {
    row-gap: 9.375rem;
  }
  .row-gap-sm-160 {
    row-gap: 10rem;
  }
  .row-gap-sm-200 {
    row-gap: 12.5rem;
  }
  .column-gap-sm-0 {
    column-gap: 0;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem;
  }
  .column-gap-sm-3 {
    column-gap: 1rem;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem;
  }
  .column-gap-sm-5 {
    column-gap: 3rem;
  }
  .column-gap-sm-7 {
    column-gap: 0.4375rem;
  }
  .column-gap-sm-10 {
    column-gap: 0.625rem;
  }
  .column-gap-sm-20 {
    column-gap: 1.25rem;
  }
  .column-gap-sm-30 {
    column-gap: 1.875rem;
  }
  .column-gap-sm-35 {
    column-gap: 2.1875rem;
  }
  .column-gap-sm-40 {
    column-gap: 2.5rem;
  }
  .column-gap-sm-60 {
    column-gap: 3.75rem;
  }
  .column-gap-sm-70 {
    column-gap: 4.375rem;
  }
  .column-gap-sm-80 {
    column-gap: 5rem;
  }
  .column-gap-sm-90 {
    column-gap: 5.625rem;
  }
  .column-gap-sm-100 {
    column-gap: 6.25rem;
  }
  .column-gap-sm-120 {
    column-gap: 7.5rem;
  }
  .column-gap-sm-130 {
    column-gap: 8.125rem;
  }
  .column-gap-sm-140 {
    column-gap: 8.75rem;
  }
  .column-gap-sm-150 {
    column-gap: 9.375rem;
  }
  .column-gap-sm-160 {
    column-gap: 10rem;
  }
  .column-gap-sm-200 {
    column-gap: 12.5rem;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .object-fit-md-contain {
    object-fit: contain;
  }
  .object-fit-md-cover {
    object-fit: cover;
  }
  .object-fit-md-fill {
    object-fit: fill;
  }
  .object-fit-md-scale {
    object-fit: scale-down;
  }
  .object-fit-md-none {
    object-fit: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-inline-grid {
    display: inline-grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 0.25rem;
  }
  .m-md-2 {
    margin: 0.5rem;
  }
  .m-md-3 {
    margin: 1rem;
  }
  .m-md-4 {
    margin: 1.5rem;
  }
  .m-md-5 {
    margin: 3rem;
  }
  .m-md-7 {
    margin: 0.4375rem;
  }
  .m-md-10 {
    margin: 0.625rem;
  }
  .m-md-20 {
    margin: 1.25rem;
  }
  .m-md-30 {
    margin: 1.875rem;
  }
  .m-md-35 {
    margin: 2.1875rem;
  }
  .m-md-40 {
    margin: 2.5rem;
  }
  .m-md-60 {
    margin: 3.75rem;
  }
  .m-md-70 {
    margin: 4.375rem;
  }
  .m-md-80 {
    margin: 5rem;
  }
  .m-md-90 {
    margin: 5.625rem;
  }
  .m-md-100 {
    margin: 6.25rem;
  }
  .m-md-120 {
    margin: 7.5rem;
  }
  .m-md-130 {
    margin: 8.125rem;
  }
  .m-md-140 {
    margin: 8.75rem;
  }
  .m-md-150 {
    margin: 9.375rem;
  }
  .m-md-160 {
    margin: 10rem;
  }
  .m-md-200 {
    margin: 12.5rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-md-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-md-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-md-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-md-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-md-7 {
    margin-right: 0.4375rem;
    margin-left: 0.4375rem;
  }
  .mx-md-10 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-md-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-md-30 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-md-35 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-md-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-md-60 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-md-70 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-md-80 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-md-90 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-md-100 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-md-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-md-130 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-md-140 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-md-150 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-md-160 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-md-200 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-md-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-md-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-md-7 {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }
  .my-md-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-md-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-md-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-md-35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-md-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-md-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-md-70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-md-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-md-90 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-md-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-md-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-md-130 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-md-140 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-md-150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-md-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-md-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 0.25rem;
  }
  .mt-md-2 {
    margin-top: 0.5rem;
  }
  .mt-md-3 {
    margin-top: 1rem;
  }
  .mt-md-4 {
    margin-top: 1.5rem;
  }
  .mt-md-5 {
    margin-top: 3rem;
  }
  .mt-md-7 {
    margin-top: 0.4375rem;
  }
  .mt-md-10 {
    margin-top: 0.625rem;
  }
  .mt-md-20 {
    margin-top: 1.25rem;
  }
  .mt-md-30 {
    margin-top: 1.875rem;
  }
  .mt-md-35 {
    margin-top: 2.1875rem;
  }
  .mt-md-40 {
    margin-top: 2.5rem;
  }
  .mt-md-60 {
    margin-top: 3.75rem;
  }
  .mt-md-70 {
    margin-top: 4.375rem;
  }
  .mt-md-80 {
    margin-top: 5rem;
  }
  .mt-md-90 {
    margin-top: 5.625rem;
  }
  .mt-md-100 {
    margin-top: 6.25rem;
  }
  .mt-md-120 {
    margin-top: 7.5rem;
  }
  .mt-md-130 {
    margin-top: 8.125rem;
  }
  .mt-md-140 {
    margin-top: 8.75rem;
  }
  .mt-md-150 {
    margin-top: 9.375rem;
  }
  .mt-md-160 {
    margin-top: 10rem;
  }
  .mt-md-200 {
    margin-top: 12.5rem;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-1 {
    margin-right: 0.25rem;
  }
  .me-md-2 {
    margin-right: 0.5rem;
  }
  .me-md-3 {
    margin-right: 1rem;
  }
  .me-md-4 {
    margin-right: 1.5rem;
  }
  .me-md-5 {
    margin-right: 3rem;
  }
  .me-md-7 {
    margin-right: 0.4375rem;
  }
  .me-md-10 {
    margin-right: 0.625rem;
  }
  .me-md-20 {
    margin-right: 1.25rem;
  }
  .me-md-30 {
    margin-right: 1.875rem;
  }
  .me-md-35 {
    margin-right: 2.1875rem;
  }
  .me-md-40 {
    margin-right: 2.5rem;
  }
  .me-md-60 {
    margin-right: 3.75rem;
  }
  .me-md-70 {
    margin-right: 4.375rem;
  }
  .me-md-80 {
    margin-right: 5rem;
  }
  .me-md-90 {
    margin-right: 5.625rem;
  }
  .me-md-100 {
    margin-right: 6.25rem;
  }
  .me-md-120 {
    margin-right: 7.5rem;
  }
  .me-md-130 {
    margin-right: 8.125rem;
  }
  .me-md-140 {
    margin-right: 8.75rem;
  }
  .me-md-150 {
    margin-right: 9.375rem;
  }
  .me-md-160 {
    margin-right: 10rem;
  }
  .me-md-200 {
    margin-right: 12.5rem;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem;
  }
  .mb-md-3 {
    margin-bottom: 1rem;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem;
  }
  .mb-md-5 {
    margin-bottom: 3rem;
  }
  .mb-md-7 {
    margin-bottom: 0.4375rem;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem;
  }
  .mb-md-30 {
    margin-bottom: 1.875rem;
  }
  .mb-md-35 {
    margin-bottom: 2.1875rem;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem;
  }
  .mb-md-60 {
    margin-bottom: 3.75rem;
  }
  .mb-md-70 {
    margin-bottom: 4.375rem;
  }
  .mb-md-80 {
    margin-bottom: 5rem;
  }
  .mb-md-90 {
    margin-bottom: 5.625rem;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem;
  }
  .mb-md-120 {
    margin-bottom: 7.5rem;
  }
  .mb-md-130 {
    margin-bottom: 8.125rem;
  }
  .mb-md-140 {
    margin-bottom: 8.75rem;
  }
  .mb-md-150 {
    margin-bottom: 9.375rem;
  }
  .mb-md-160 {
    margin-bottom: 10rem;
  }
  .mb-md-200 {
    margin-bottom: 12.5rem;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-1 {
    margin-left: 0.25rem;
  }
  .ms-md-2 {
    margin-left: 0.5rem;
  }
  .ms-md-3 {
    margin-left: 1rem;
  }
  .ms-md-4 {
    margin-left: 1.5rem;
  }
  .ms-md-5 {
    margin-left: 3rem;
  }
  .ms-md-7 {
    margin-left: 0.4375rem;
  }
  .ms-md-10 {
    margin-left: 0.625rem;
  }
  .ms-md-20 {
    margin-left: 1.25rem;
  }
  .ms-md-30 {
    margin-left: 1.875rem;
  }
  .ms-md-35 {
    margin-left: 2.1875rem;
  }
  .ms-md-40 {
    margin-left: 2.5rem;
  }
  .ms-md-60 {
    margin-left: 3.75rem;
  }
  .ms-md-70 {
    margin-left: 4.375rem;
  }
  .ms-md-80 {
    margin-left: 5rem;
  }
  .ms-md-90 {
    margin-left: 5.625rem;
  }
  .ms-md-100 {
    margin-left: 6.25rem;
  }
  .ms-md-120 {
    margin-left: 7.5rem;
  }
  .ms-md-130 {
    margin-left: 8.125rem;
  }
  .ms-md-140 {
    margin-left: 8.75rem;
  }
  .ms-md-150 {
    margin-left: 9.375rem;
  }
  .ms-md-160 {
    margin-left: 10rem;
  }
  .ms-md-200 {
    margin-left: 12.5rem;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .m-md-n1 {
    margin: -0.25rem;
  }
  .m-md-n2 {
    margin: -0.5rem;
  }
  .m-md-n3 {
    margin: -1rem;
  }
  .m-md-n4 {
    margin: -1.5rem;
  }
  .m-md-n5 {
    margin: -3rem;
  }
  .m-md-n7 {
    margin: -0.4375rem;
  }
  .m-md-n10 {
    margin: -0.625rem;
  }
  .m-md-n20 {
    margin: -1.25rem;
  }
  .m-md-n30 {
    margin: -1.875rem;
  }
  .m-md-n35 {
    margin: -2.1875rem;
  }
  .m-md-n40 {
    margin: -2.5rem;
  }
  .m-md-n60 {
    margin: -3.75rem;
  }
  .m-md-n70 {
    margin: -4.375rem;
  }
  .m-md-n80 {
    margin: -5rem;
  }
  .m-md-n90 {
    margin: -5.625rem;
  }
  .m-md-n100 {
    margin: -6.25rem;
  }
  .m-md-n120 {
    margin: -7.5rem;
  }
  .m-md-n130 {
    margin: -8.125rem;
  }
  .m-md-n140 {
    margin: -8.75rem;
  }
  .m-md-n150 {
    margin: -9.375rem;
  }
  .m-md-n160 {
    margin: -10rem;
  }
  .m-md-n200 {
    margin: -12.5rem;
  }
  .mx-md-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-md-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-md-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-md-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-md-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-md-n7 {
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
  }
  .mx-md-n10 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .mx-md-n20 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-md-n30 {
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .mx-md-n35 {
    margin-right: -2.1875rem;
    margin-left: -2.1875rem;
  }
  .mx-md-n40 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-md-n60 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-md-n70 {
    margin-right: -4.375rem;
    margin-left: -4.375rem;
  }
  .mx-md-n80 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-md-n90 {
    margin-right: -5.625rem;
    margin-left: -5.625rem;
  }
  .mx-md-n100 {
    margin-right: -6.25rem;
    margin-left: -6.25rem;
  }
  .mx-md-n120 {
    margin-right: -7.5rem;
    margin-left: -7.5rem;
  }
  .mx-md-n130 {
    margin-right: -8.125rem;
    margin-left: -8.125rem;
  }
  .mx-md-n140 {
    margin-right: -8.75rem;
    margin-left: -8.75rem;
  }
  .mx-md-n150 {
    margin-right: -9.375rem;
    margin-left: -9.375rem;
  }
  .mx-md-n160 {
    margin-right: -10rem;
    margin-left: -10rem;
  }
  .mx-md-n200 {
    margin-right: -12.5rem;
    margin-left: -12.5rem;
  }
  .my-md-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-md-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-md-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-md-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-md-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-md-n7 {
    margin-top: -0.4375rem;
    margin-bottom: -0.4375rem;
  }
  .my-md-n10 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .my-md-n20 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-md-n30 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .my-md-n35 {
    margin-top: -2.1875rem;
    margin-bottom: -2.1875rem;
  }
  .my-md-n40 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-md-n60 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-md-n70 {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
  .my-md-n80 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-md-n90 {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem;
  }
  .my-md-n100 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
  .my-md-n120 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .my-md-n130 {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem;
  }
  .my-md-n140 {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem;
  }
  .my-md-n150 {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem;
  }
  .my-md-n160 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .my-md-n200 {
    margin-top: -12.5rem;
    margin-bottom: -12.5rem;
  }
  .mt-md-n1 {
    margin-top: -0.25rem;
  }
  .mt-md-n2 {
    margin-top: -0.5rem;
  }
  .mt-md-n3 {
    margin-top: -1rem;
  }
  .mt-md-n4 {
    margin-top: -1.5rem;
  }
  .mt-md-n5 {
    margin-top: -3rem;
  }
  .mt-md-n7 {
    margin-top: -0.4375rem;
  }
  .mt-md-n10 {
    margin-top: -0.625rem;
  }
  .mt-md-n20 {
    margin-top: -1.25rem;
  }
  .mt-md-n30 {
    margin-top: -1.875rem;
  }
  .mt-md-n35 {
    margin-top: -2.1875rem;
  }
  .mt-md-n40 {
    margin-top: -2.5rem;
  }
  .mt-md-n60 {
    margin-top: -3.75rem;
  }
  .mt-md-n70 {
    margin-top: -4.375rem;
  }
  .mt-md-n80 {
    margin-top: -5rem;
  }
  .mt-md-n90 {
    margin-top: -5.625rem;
  }
  .mt-md-n100 {
    margin-top: -6.25rem;
  }
  .mt-md-n120 {
    margin-top: -7.5rem;
  }
  .mt-md-n130 {
    margin-top: -8.125rem;
  }
  .mt-md-n140 {
    margin-top: -8.75rem;
  }
  .mt-md-n150 {
    margin-top: -9.375rem;
  }
  .mt-md-n160 {
    margin-top: -10rem;
  }
  .mt-md-n200 {
    margin-top: -12.5rem;
  }
  .me-md-n1 {
    margin-right: -0.25rem;
  }
  .me-md-n2 {
    margin-right: -0.5rem;
  }
  .me-md-n3 {
    margin-right: -1rem;
  }
  .me-md-n4 {
    margin-right: -1.5rem;
  }
  .me-md-n5 {
    margin-right: -3rem;
  }
  .me-md-n7 {
    margin-right: -0.4375rem;
  }
  .me-md-n10 {
    margin-right: -0.625rem;
  }
  .me-md-n20 {
    margin-right: -1.25rem;
  }
  .me-md-n30 {
    margin-right: -1.875rem;
  }
  .me-md-n35 {
    margin-right: -2.1875rem;
  }
  .me-md-n40 {
    margin-right: -2.5rem;
  }
  .me-md-n60 {
    margin-right: -3.75rem;
  }
  .me-md-n70 {
    margin-right: -4.375rem;
  }
  .me-md-n80 {
    margin-right: -5rem;
  }
  .me-md-n90 {
    margin-right: -5.625rem;
  }
  .me-md-n100 {
    margin-right: -6.25rem;
  }
  .me-md-n120 {
    margin-right: -7.5rem;
  }
  .me-md-n130 {
    margin-right: -8.125rem;
  }
  .me-md-n140 {
    margin-right: -8.75rem;
  }
  .me-md-n150 {
    margin-right: -9.375rem;
  }
  .me-md-n160 {
    margin-right: -10rem;
  }
  .me-md-n200 {
    margin-right: -12.5rem;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-md-n3 {
    margin-bottom: -1rem;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-md-n5 {
    margin-bottom: -3rem;
  }
  .mb-md-n7 {
    margin-bottom: -0.4375rem;
  }
  .mb-md-n10 {
    margin-bottom: -0.625rem;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem;
  }
  .mb-md-n30 {
    margin-bottom: -1.875rem;
  }
  .mb-md-n35 {
    margin-bottom: -2.1875rem;
  }
  .mb-md-n40 {
    margin-bottom: -2.5rem;
  }
  .mb-md-n60 {
    margin-bottom: -3.75rem;
  }
  .mb-md-n70 {
    margin-bottom: -4.375rem;
  }
  .mb-md-n80 {
    margin-bottom: -5rem;
  }
  .mb-md-n90 {
    margin-bottom: -5.625rem;
  }
  .mb-md-n100 {
    margin-bottom: -6.25rem;
  }
  .mb-md-n120 {
    margin-bottom: -7.5rem;
  }
  .mb-md-n130 {
    margin-bottom: -8.125rem;
  }
  .mb-md-n140 {
    margin-bottom: -8.75rem;
  }
  .mb-md-n150 {
    margin-bottom: -9.375rem;
  }
  .mb-md-n160 {
    margin-bottom: -10rem;
  }
  .mb-md-n200 {
    margin-bottom: -12.5rem;
  }
  .ms-md-n1 {
    margin-left: -0.25rem;
  }
  .ms-md-n2 {
    margin-left: -0.5rem;
  }
  .ms-md-n3 {
    margin-left: -1rem;
  }
  .ms-md-n4 {
    margin-left: -1.5rem;
  }
  .ms-md-n5 {
    margin-left: -3rem;
  }
  .ms-md-n7 {
    margin-left: -0.4375rem;
  }
  .ms-md-n10 {
    margin-left: -0.625rem;
  }
  .ms-md-n20 {
    margin-left: -1.25rem;
  }
  .ms-md-n30 {
    margin-left: -1.875rem;
  }
  .ms-md-n35 {
    margin-left: -2.1875rem;
  }
  .ms-md-n40 {
    margin-left: -2.5rem;
  }
  .ms-md-n60 {
    margin-left: -3.75rem;
  }
  .ms-md-n70 {
    margin-left: -4.375rem;
  }
  .ms-md-n80 {
    margin-left: -5rem;
  }
  .ms-md-n90 {
    margin-left: -5.625rem;
  }
  .ms-md-n100 {
    margin-left: -6.25rem;
  }
  .ms-md-n120 {
    margin-left: -7.5rem;
  }
  .ms-md-n130 {
    margin-left: -8.125rem;
  }
  .ms-md-n140 {
    margin-left: -8.75rem;
  }
  .ms-md-n150 {
    margin-left: -9.375rem;
  }
  .ms-md-n160 {
    margin-left: -10rem;
  }
  .ms-md-n200 {
    margin-left: -12.5rem;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-1 {
    padding: 0.25rem;
  }
  .p-md-2 {
    padding: 0.5rem;
  }
  .p-md-3 {
    padding: 1rem;
  }
  .p-md-4 {
    padding: 1.5rem;
  }
  .p-md-5 {
    padding: 3rem;
  }
  .p-md-7 {
    padding: 0.4375rem;
  }
  .p-md-10 {
    padding: 0.625rem;
  }
  .p-md-20 {
    padding: 1.25rem;
  }
  .p-md-30 {
    padding: 1.875rem;
  }
  .p-md-35 {
    padding: 2.1875rem;
  }
  .p-md-40 {
    padding: 2.5rem;
  }
  .p-md-60 {
    padding: 3.75rem;
  }
  .p-md-70 {
    padding: 4.375rem;
  }
  .p-md-80 {
    padding: 5rem;
  }
  .p-md-90 {
    padding: 5.625rem;
  }
  .p-md-100 {
    padding: 6.25rem;
  }
  .p-md-120 {
    padding: 7.5rem;
  }
  .p-md-130 {
    padding: 8.125rem;
  }
  .p-md-140 {
    padding: 8.75rem;
  }
  .p-md-150 {
    padding: 9.375rem;
  }
  .p-md-160 {
    padding: 10rem;
  }
  .p-md-200 {
    padding: 12.5rem;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-md-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-md-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-md-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-md-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-md-7 {
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
  }
  .px-md-10 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-md-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-md-30 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-md-35 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-md-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-md-60 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-md-70 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-md-80 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-md-90 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-md-100 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-md-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-md-130 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-md-140 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-md-150 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-md-160 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-md-200 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-md-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-md-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-md-7 {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .py-md-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-md-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-md-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-md-35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-md-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-md-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-md-70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-md-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-md-90 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-md-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-md-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-md-130 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-md-140 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-md-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-md-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-md-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-1 {
    padding-top: 0.25rem;
  }
  .pt-md-2 {
    padding-top: 0.5rem;
  }
  .pt-md-3 {
    padding-top: 1rem;
  }
  .pt-md-4 {
    padding-top: 1.5rem;
  }
  .pt-md-5 {
    padding-top: 3rem;
  }
  .pt-md-7 {
    padding-top: 0.4375rem;
  }
  .pt-md-10 {
    padding-top: 0.625rem;
  }
  .pt-md-20 {
    padding-top: 1.25rem;
  }
  .pt-md-30 {
    padding-top: 1.875rem;
  }
  .pt-md-35 {
    padding-top: 2.1875rem;
  }
  .pt-md-40 {
    padding-top: 2.5rem;
  }
  .pt-md-60 {
    padding-top: 3.75rem;
  }
  .pt-md-70 {
    padding-top: 4.375rem;
  }
  .pt-md-80 {
    padding-top: 5rem;
  }
  .pt-md-90 {
    padding-top: 5.625rem;
  }
  .pt-md-100 {
    padding-top: 6.25rem;
  }
  .pt-md-120 {
    padding-top: 7.5rem;
  }
  .pt-md-130 {
    padding-top: 8.125rem;
  }
  .pt-md-140 {
    padding-top: 8.75rem;
  }
  .pt-md-150 {
    padding-top: 9.375rem;
  }
  .pt-md-160 {
    padding-top: 10rem;
  }
  .pt-md-200 {
    padding-top: 12.5rem;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-1 {
    padding-right: 0.25rem;
  }
  .pe-md-2 {
    padding-right: 0.5rem;
  }
  .pe-md-3 {
    padding-right: 1rem;
  }
  .pe-md-4 {
    padding-right: 1.5rem;
  }
  .pe-md-5 {
    padding-right: 3rem;
  }
  .pe-md-7 {
    padding-right: 0.4375rem;
  }
  .pe-md-10 {
    padding-right: 0.625rem;
  }
  .pe-md-20 {
    padding-right: 1.25rem;
  }
  .pe-md-30 {
    padding-right: 1.875rem;
  }
  .pe-md-35 {
    padding-right: 2.1875rem;
  }
  .pe-md-40 {
    padding-right: 2.5rem;
  }
  .pe-md-60 {
    padding-right: 3.75rem;
  }
  .pe-md-70 {
    padding-right: 4.375rem;
  }
  .pe-md-80 {
    padding-right: 5rem;
  }
  .pe-md-90 {
    padding-right: 5.625rem;
  }
  .pe-md-100 {
    padding-right: 6.25rem;
  }
  .pe-md-120 {
    padding-right: 7.5rem;
  }
  .pe-md-130 {
    padding-right: 8.125rem;
  }
  .pe-md-140 {
    padding-right: 8.75rem;
  }
  .pe-md-150 {
    padding-right: 9.375rem;
  }
  .pe-md-160 {
    padding-right: 10rem;
  }
  .pe-md-200 {
    padding-right: 12.5rem;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem;
  }
  .pb-md-3 {
    padding-bottom: 1rem;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem;
  }
  .pb-md-5 {
    padding-bottom: 3rem;
  }
  .pb-md-7 {
    padding-bottom: 0.4375rem;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem;
  }
  .pb-md-30 {
    padding-bottom: 1.875rem;
  }
  .pb-md-35 {
    padding-bottom: 2.1875rem;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem;
  }
  .pb-md-60 {
    padding-bottom: 3.75rem;
  }
  .pb-md-70 {
    padding-bottom: 4.375rem;
  }
  .pb-md-80 {
    padding-bottom: 5rem;
  }
  .pb-md-90 {
    padding-bottom: 5.625rem;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem;
  }
  .pb-md-120 {
    padding-bottom: 7.5rem;
  }
  .pb-md-130 {
    padding-bottom: 8.125rem;
  }
  .pb-md-140 {
    padding-bottom: 8.75rem;
  }
  .pb-md-150 {
    padding-bottom: 9.375rem;
  }
  .pb-md-160 {
    padding-bottom: 10rem;
  }
  .pb-md-200 {
    padding-bottom: 12.5rem;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-1 {
    padding-left: 0.25rem;
  }
  .ps-md-2 {
    padding-left: 0.5rem;
  }
  .ps-md-3 {
    padding-left: 1rem;
  }
  .ps-md-4 {
    padding-left: 1.5rem;
  }
  .ps-md-5 {
    padding-left: 3rem;
  }
  .ps-md-7 {
    padding-left: 0.4375rem;
  }
  .ps-md-10 {
    padding-left: 0.625rem;
  }
  .ps-md-20 {
    padding-left: 1.25rem;
  }
  .ps-md-30 {
    padding-left: 1.875rem;
  }
  .ps-md-35 {
    padding-left: 2.1875rem;
  }
  .ps-md-40 {
    padding-left: 2.5rem;
  }
  .ps-md-60 {
    padding-left: 3.75rem;
  }
  .ps-md-70 {
    padding-left: 4.375rem;
  }
  .ps-md-80 {
    padding-left: 5rem;
  }
  .ps-md-90 {
    padding-left: 5.625rem;
  }
  .ps-md-100 {
    padding-left: 6.25rem;
  }
  .ps-md-120 {
    padding-left: 7.5rem;
  }
  .ps-md-130 {
    padding-left: 8.125rem;
  }
  .ps-md-140 {
    padding-left: 8.75rem;
  }
  .ps-md-150 {
    padding-left: 9.375rem;
  }
  .ps-md-160 {
    padding-left: 10rem;
  }
  .ps-md-200 {
    padding-left: 12.5rem;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-1 {
    gap: 0.25rem;
  }
  .gap-md-2 {
    gap: 0.5rem;
  }
  .gap-md-3 {
    gap: 1rem;
  }
  .gap-md-4 {
    gap: 1.5rem;
  }
  .gap-md-5 {
    gap: 3rem;
  }
  .gap-md-7 {
    gap: 0.4375rem;
  }
  .gap-md-10 {
    gap: 0.625rem;
  }
  .gap-md-20 {
    gap: 1.25rem;
  }
  .gap-md-30 {
    gap: 1.875rem;
  }
  .gap-md-35 {
    gap: 2.1875rem;
  }
  .gap-md-40 {
    gap: 2.5rem;
  }
  .gap-md-60 {
    gap: 3.75rem;
  }
  .gap-md-70 {
    gap: 4.375rem;
  }
  .gap-md-80 {
    gap: 5rem;
  }
  .gap-md-90 {
    gap: 5.625rem;
  }
  .gap-md-100 {
    gap: 6.25rem;
  }
  .gap-md-120 {
    gap: 7.5rem;
  }
  .gap-md-130 {
    gap: 8.125rem;
  }
  .gap-md-140 {
    gap: 8.75rem;
  }
  .gap-md-150 {
    gap: 9.375rem;
  }
  .gap-md-160 {
    gap: 10rem;
  }
  .gap-md-200 {
    gap: 12.5rem;
  }
  .row-gap-md-0 {
    row-gap: 0;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem;
  }
  .row-gap-md-3 {
    row-gap: 1rem;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem;
  }
  .row-gap-md-5 {
    row-gap: 3rem;
  }
  .row-gap-md-7 {
    row-gap: 0.4375rem;
  }
  .row-gap-md-10 {
    row-gap: 0.625rem;
  }
  .row-gap-md-20 {
    row-gap: 1.25rem;
  }
  .row-gap-md-30 {
    row-gap: 1.875rem;
  }
  .row-gap-md-35 {
    row-gap: 2.1875rem;
  }
  .row-gap-md-40 {
    row-gap: 2.5rem;
  }
  .row-gap-md-60 {
    row-gap: 3.75rem;
  }
  .row-gap-md-70 {
    row-gap: 4.375rem;
  }
  .row-gap-md-80 {
    row-gap: 5rem;
  }
  .row-gap-md-90 {
    row-gap: 5.625rem;
  }
  .row-gap-md-100 {
    row-gap: 6.25rem;
  }
  .row-gap-md-120 {
    row-gap: 7.5rem;
  }
  .row-gap-md-130 {
    row-gap: 8.125rem;
  }
  .row-gap-md-140 {
    row-gap: 8.75rem;
  }
  .row-gap-md-150 {
    row-gap: 9.375rem;
  }
  .row-gap-md-160 {
    row-gap: 10rem;
  }
  .row-gap-md-200 {
    row-gap: 12.5rem;
  }
  .column-gap-md-0 {
    column-gap: 0;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem;
  }
  .column-gap-md-3 {
    column-gap: 1rem;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem;
  }
  .column-gap-md-5 {
    column-gap: 3rem;
  }
  .column-gap-md-7 {
    column-gap: 0.4375rem;
  }
  .column-gap-md-10 {
    column-gap: 0.625rem;
  }
  .column-gap-md-20 {
    column-gap: 1.25rem;
  }
  .column-gap-md-30 {
    column-gap: 1.875rem;
  }
  .column-gap-md-35 {
    column-gap: 2.1875rem;
  }
  .column-gap-md-40 {
    column-gap: 2.5rem;
  }
  .column-gap-md-60 {
    column-gap: 3.75rem;
  }
  .column-gap-md-70 {
    column-gap: 4.375rem;
  }
  .column-gap-md-80 {
    column-gap: 5rem;
  }
  .column-gap-md-90 {
    column-gap: 5.625rem;
  }
  .column-gap-md-100 {
    column-gap: 6.25rem;
  }
  .column-gap-md-120 {
    column-gap: 7.5rem;
  }
  .column-gap-md-130 {
    column-gap: 8.125rem;
  }
  .column-gap-md-140 {
    column-gap: 8.75rem;
  }
  .column-gap-md-150 {
    column-gap: 9.375rem;
  }
  .column-gap-md-160 {
    column-gap: 10rem;
  }
  .column-gap-md-200 {
    column-gap: 12.5rem;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .object-fit-lg-contain {
    object-fit: contain;
  }
  .object-fit-lg-cover {
    object-fit: cover;
  }
  .object-fit-lg-fill {
    object-fit: fill;
  }
  .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .object-fit-lg-none {
    object-fit: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-inline-grid {
    display: inline-grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-1 {
    margin: 0.25rem;
  }
  .m-lg-2 {
    margin: 0.5rem;
  }
  .m-lg-3 {
    margin: 1rem;
  }
  .m-lg-4 {
    margin: 1.5rem;
  }
  .m-lg-5 {
    margin: 3rem;
  }
  .m-lg-7 {
    margin: 0.4375rem;
  }
  .m-lg-10 {
    margin: 0.625rem;
  }
  .m-lg-20 {
    margin: 1.25rem;
  }
  .m-lg-30 {
    margin: 1.875rem;
  }
  .m-lg-35 {
    margin: 2.1875rem;
  }
  .m-lg-40 {
    margin: 2.5rem;
  }
  .m-lg-60 {
    margin: 3.75rem;
  }
  .m-lg-70 {
    margin: 4.375rem;
  }
  .m-lg-80 {
    margin: 5rem;
  }
  .m-lg-90 {
    margin: 5.625rem;
  }
  .m-lg-100 {
    margin: 6.25rem;
  }
  .m-lg-120 {
    margin: 7.5rem;
  }
  .m-lg-130 {
    margin: 8.125rem;
  }
  .m-lg-140 {
    margin: 8.75rem;
  }
  .m-lg-150 {
    margin: 9.375rem;
  }
  .m-lg-160 {
    margin: 10rem;
  }
  .m-lg-200 {
    margin: 12.5rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-lg-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-lg-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-lg-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-lg-7 {
    margin-right: 0.4375rem;
    margin-left: 0.4375rem;
  }
  .mx-lg-10 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-lg-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-lg-30 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-lg-35 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-lg-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-lg-60 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-lg-70 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-lg-80 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-lg-90 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-lg-100 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-lg-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-lg-130 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-lg-140 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-lg-150 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-lg-160 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-lg-200 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-lg-7 {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }
  .my-lg-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-lg-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-lg-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-lg-35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-lg-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-lg-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-lg-70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-lg-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-lg-90 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-lg-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-lg-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-lg-130 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-lg-140 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-lg-150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-lg-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-lg-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-1 {
    margin-top: 0.25rem;
  }
  .mt-lg-2 {
    margin-top: 0.5rem;
  }
  .mt-lg-3 {
    margin-top: 1rem;
  }
  .mt-lg-4 {
    margin-top: 1.5rem;
  }
  .mt-lg-5 {
    margin-top: 3rem;
  }
  .mt-lg-7 {
    margin-top: 0.4375rem;
  }
  .mt-lg-10 {
    margin-top: 0.625rem;
  }
  .mt-lg-20 {
    margin-top: 1.25rem;
  }
  .mt-lg-30 {
    margin-top: 1.875rem;
  }
  .mt-lg-35 {
    margin-top: 2.1875rem;
  }
  .mt-lg-40 {
    margin-top: 2.5rem;
  }
  .mt-lg-60 {
    margin-top: 3.75rem;
  }
  .mt-lg-70 {
    margin-top: 4.375rem;
  }
  .mt-lg-80 {
    margin-top: 5rem;
  }
  .mt-lg-90 {
    margin-top: 5.625rem;
  }
  .mt-lg-100 {
    margin-top: 6.25rem;
  }
  .mt-lg-120 {
    margin-top: 7.5rem;
  }
  .mt-lg-130 {
    margin-top: 8.125rem;
  }
  .mt-lg-140 {
    margin-top: 8.75rem;
  }
  .mt-lg-150 {
    margin-top: 9.375rem;
  }
  .mt-lg-160 {
    margin-top: 10rem;
  }
  .mt-lg-200 {
    margin-top: 12.5rem;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-1 {
    margin-right: 0.25rem;
  }
  .me-lg-2 {
    margin-right: 0.5rem;
  }
  .me-lg-3 {
    margin-right: 1rem;
  }
  .me-lg-4 {
    margin-right: 1.5rem;
  }
  .me-lg-5 {
    margin-right: 3rem;
  }
  .me-lg-7 {
    margin-right: 0.4375rem;
  }
  .me-lg-10 {
    margin-right: 0.625rem;
  }
  .me-lg-20 {
    margin-right: 1.25rem;
  }
  .me-lg-30 {
    margin-right: 1.875rem;
  }
  .me-lg-35 {
    margin-right: 2.1875rem;
  }
  .me-lg-40 {
    margin-right: 2.5rem;
  }
  .me-lg-60 {
    margin-right: 3.75rem;
  }
  .me-lg-70 {
    margin-right: 4.375rem;
  }
  .me-lg-80 {
    margin-right: 5rem;
  }
  .me-lg-90 {
    margin-right: 5.625rem;
  }
  .me-lg-100 {
    margin-right: 6.25rem;
  }
  .me-lg-120 {
    margin-right: 7.5rem;
  }
  .me-lg-130 {
    margin-right: 8.125rem;
  }
  .me-lg-140 {
    margin-right: 8.75rem;
  }
  .me-lg-150 {
    margin-right: 9.375rem;
  }
  .me-lg-160 {
    margin-right: 10rem;
  }
  .me-lg-200 {
    margin-right: 12.5rem;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
  }
  .mb-lg-3 {
    margin-bottom: 1rem;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem;
  }
  .mb-lg-5 {
    margin-bottom: 3rem;
  }
  .mb-lg-7 {
    margin-bottom: 0.4375rem;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem;
  }
  .mb-lg-30 {
    margin-bottom: 1.875rem;
  }
  .mb-lg-35 {
    margin-bottom: 2.1875rem;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-60 {
    margin-bottom: 3.75rem;
  }
  .mb-lg-70 {
    margin-bottom: 4.375rem;
  }
  .mb-lg-80 {
    margin-bottom: 5rem;
  }
  .mb-lg-90 {
    margin-bottom: 5.625rem;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem;
  }
  .mb-lg-120 {
    margin-bottom: 7.5rem;
  }
  .mb-lg-130 {
    margin-bottom: 8.125rem;
  }
  .mb-lg-140 {
    margin-bottom: 8.75rem;
  }
  .mb-lg-150 {
    margin-bottom: 9.375rem;
  }
  .mb-lg-160 {
    margin-bottom: 10rem;
  }
  .mb-lg-200 {
    margin-bottom: 12.5rem;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-1 {
    margin-left: 0.25rem;
  }
  .ms-lg-2 {
    margin-left: 0.5rem;
  }
  .ms-lg-3 {
    margin-left: 1rem;
  }
  .ms-lg-4 {
    margin-left: 1.5rem;
  }
  .ms-lg-5 {
    margin-left: 3rem;
  }
  .ms-lg-7 {
    margin-left: 0.4375rem;
  }
  .ms-lg-10 {
    margin-left: 0.625rem;
  }
  .ms-lg-20 {
    margin-left: 1.25rem;
  }
  .ms-lg-30 {
    margin-left: 1.875rem;
  }
  .ms-lg-35 {
    margin-left: 2.1875rem;
  }
  .ms-lg-40 {
    margin-left: 2.5rem;
  }
  .ms-lg-60 {
    margin-left: 3.75rem;
  }
  .ms-lg-70 {
    margin-left: 4.375rem;
  }
  .ms-lg-80 {
    margin-left: 5rem;
  }
  .ms-lg-90 {
    margin-left: 5.625rem;
  }
  .ms-lg-100 {
    margin-left: 6.25rem;
  }
  .ms-lg-120 {
    margin-left: 7.5rem;
  }
  .ms-lg-130 {
    margin-left: 8.125rem;
  }
  .ms-lg-140 {
    margin-left: 8.75rem;
  }
  .ms-lg-150 {
    margin-left: 9.375rem;
  }
  .ms-lg-160 {
    margin-left: 10rem;
  }
  .ms-lg-200 {
    margin-left: 12.5rem;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .m-lg-n1 {
    margin: -0.25rem;
  }
  .m-lg-n2 {
    margin: -0.5rem;
  }
  .m-lg-n3 {
    margin: -1rem;
  }
  .m-lg-n4 {
    margin: -1.5rem;
  }
  .m-lg-n5 {
    margin: -3rem;
  }
  .m-lg-n7 {
    margin: -0.4375rem;
  }
  .m-lg-n10 {
    margin: -0.625rem;
  }
  .m-lg-n20 {
    margin: -1.25rem;
  }
  .m-lg-n30 {
    margin: -1.875rem;
  }
  .m-lg-n35 {
    margin: -2.1875rem;
  }
  .m-lg-n40 {
    margin: -2.5rem;
  }
  .m-lg-n60 {
    margin: -3.75rem;
  }
  .m-lg-n70 {
    margin: -4.375rem;
  }
  .m-lg-n80 {
    margin: -5rem;
  }
  .m-lg-n90 {
    margin: -5.625rem;
  }
  .m-lg-n100 {
    margin: -6.25rem;
  }
  .m-lg-n120 {
    margin: -7.5rem;
  }
  .m-lg-n130 {
    margin: -8.125rem;
  }
  .m-lg-n140 {
    margin: -8.75rem;
  }
  .m-lg-n150 {
    margin: -9.375rem;
  }
  .m-lg-n160 {
    margin: -10rem;
  }
  .m-lg-n200 {
    margin: -12.5rem;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-lg-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-lg-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-lg-n7 {
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
  }
  .mx-lg-n10 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-lg-n30 {
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .mx-lg-n35 {
    margin-right: -2.1875rem;
    margin-left: -2.1875rem;
  }
  .mx-lg-n40 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-lg-n60 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-lg-n70 {
    margin-right: -4.375rem;
    margin-left: -4.375rem;
  }
  .mx-lg-n80 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-lg-n90 {
    margin-right: -5.625rem;
    margin-left: -5.625rem;
  }
  .mx-lg-n100 {
    margin-right: -6.25rem;
    margin-left: -6.25rem;
  }
  .mx-lg-n120 {
    margin-right: -7.5rem;
    margin-left: -7.5rem;
  }
  .mx-lg-n130 {
    margin-right: -8.125rem;
    margin-left: -8.125rem;
  }
  .mx-lg-n140 {
    margin-right: -8.75rem;
    margin-left: -8.75rem;
  }
  .mx-lg-n150 {
    margin-right: -9.375rem;
    margin-left: -9.375rem;
  }
  .mx-lg-n160 {
    margin-right: -10rem;
    margin-left: -10rem;
  }
  .mx-lg-n200 {
    margin-right: -12.5rem;
    margin-left: -12.5rem;
  }
  .my-lg-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-lg-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-lg-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-lg-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-lg-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-lg-n7 {
    margin-top: -0.4375rem;
    margin-bottom: -0.4375rem;
  }
  .my-lg-n10 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .my-lg-n20 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-lg-n30 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .my-lg-n35 {
    margin-top: -2.1875rem;
    margin-bottom: -2.1875rem;
  }
  .my-lg-n40 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-lg-n60 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-lg-n70 {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
  .my-lg-n80 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-lg-n90 {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem;
  }
  .my-lg-n100 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
  .my-lg-n120 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .my-lg-n130 {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem;
  }
  .my-lg-n140 {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem;
  }
  .my-lg-n150 {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem;
  }
  .my-lg-n160 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .my-lg-n200 {
    margin-top: -12.5rem;
    margin-bottom: -12.5rem;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem;
  }
  .mt-lg-n3 {
    margin-top: -1rem;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem;
  }
  .mt-lg-n5 {
    margin-top: -3rem;
  }
  .mt-lg-n7 {
    margin-top: -0.4375rem;
  }
  .mt-lg-n10 {
    margin-top: -0.625rem;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem;
  }
  .mt-lg-n30 {
    margin-top: -1.875rem;
  }
  .mt-lg-n35 {
    margin-top: -2.1875rem;
  }
  .mt-lg-n40 {
    margin-top: -2.5rem;
  }
  .mt-lg-n60 {
    margin-top: -3.75rem;
  }
  .mt-lg-n70 {
    margin-top: -4.375rem;
  }
  .mt-lg-n80 {
    margin-top: -5rem;
  }
  .mt-lg-n90 {
    margin-top: -5.625rem;
  }
  .mt-lg-n100 {
    margin-top: -6.25rem;
  }
  .mt-lg-n120 {
    margin-top: -7.5rem;
  }
  .mt-lg-n130 {
    margin-top: -8.125rem;
  }
  .mt-lg-n140 {
    margin-top: -8.75rem;
  }
  .mt-lg-n150 {
    margin-top: -9.375rem;
  }
  .mt-lg-n160 {
    margin-top: -10rem;
  }
  .mt-lg-n200 {
    margin-top: -12.5rem;
  }
  .me-lg-n1 {
    margin-right: -0.25rem;
  }
  .me-lg-n2 {
    margin-right: -0.5rem;
  }
  .me-lg-n3 {
    margin-right: -1rem;
  }
  .me-lg-n4 {
    margin-right: -1.5rem;
  }
  .me-lg-n5 {
    margin-right: -3rem;
  }
  .me-lg-n7 {
    margin-right: -0.4375rem;
  }
  .me-lg-n10 {
    margin-right: -0.625rem;
  }
  .me-lg-n20 {
    margin-right: -1.25rem;
  }
  .me-lg-n30 {
    margin-right: -1.875rem;
  }
  .me-lg-n35 {
    margin-right: -2.1875rem;
  }
  .me-lg-n40 {
    margin-right: -2.5rem;
  }
  .me-lg-n60 {
    margin-right: -3.75rem;
  }
  .me-lg-n70 {
    margin-right: -4.375rem;
  }
  .me-lg-n80 {
    margin-right: -5rem;
  }
  .me-lg-n90 {
    margin-right: -5.625rem;
  }
  .me-lg-n100 {
    margin-right: -6.25rem;
  }
  .me-lg-n120 {
    margin-right: -7.5rem;
  }
  .me-lg-n130 {
    margin-right: -8.125rem;
  }
  .me-lg-n140 {
    margin-right: -8.75rem;
  }
  .me-lg-n150 {
    margin-right: -9.375rem;
  }
  .me-lg-n160 {
    margin-right: -10rem;
  }
  .me-lg-n200 {
    margin-right: -12.5rem;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem;
  }
  .mb-lg-n7 {
    margin-bottom: -0.4375rem;
  }
  .mb-lg-n10 {
    margin-bottom: -0.625rem;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem;
  }
  .mb-lg-n30 {
    margin-bottom: -1.875rem;
  }
  .mb-lg-n35 {
    margin-bottom: -2.1875rem;
  }
  .mb-lg-n40 {
    margin-bottom: -2.5rem;
  }
  .mb-lg-n60 {
    margin-bottom: -3.75rem;
  }
  .mb-lg-n70 {
    margin-bottom: -4.375rem;
  }
  .mb-lg-n80 {
    margin-bottom: -5rem;
  }
  .mb-lg-n90 {
    margin-bottom: -5.625rem;
  }
  .mb-lg-n100 {
    margin-bottom: -6.25rem;
  }
  .mb-lg-n120 {
    margin-bottom: -7.5rem;
  }
  .mb-lg-n130 {
    margin-bottom: -8.125rem;
  }
  .mb-lg-n140 {
    margin-bottom: -8.75rem;
  }
  .mb-lg-n150 {
    margin-bottom: -9.375rem;
  }
  .mb-lg-n160 {
    margin-bottom: -10rem;
  }
  .mb-lg-n200 {
    margin-bottom: -12.5rem;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem;
  }
  .ms-lg-n3 {
    margin-left: -1rem;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem;
  }
  .ms-lg-n5 {
    margin-left: -3rem;
  }
  .ms-lg-n7 {
    margin-left: -0.4375rem;
  }
  .ms-lg-n10 {
    margin-left: -0.625rem;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem;
  }
  .ms-lg-n30 {
    margin-left: -1.875rem;
  }
  .ms-lg-n35 {
    margin-left: -2.1875rem;
  }
  .ms-lg-n40 {
    margin-left: -2.5rem;
  }
  .ms-lg-n60 {
    margin-left: -3.75rem;
  }
  .ms-lg-n70 {
    margin-left: -4.375rem;
  }
  .ms-lg-n80 {
    margin-left: -5rem;
  }
  .ms-lg-n90 {
    margin-left: -5.625rem;
  }
  .ms-lg-n100 {
    margin-left: -6.25rem;
  }
  .ms-lg-n120 {
    margin-left: -7.5rem;
  }
  .ms-lg-n130 {
    margin-left: -8.125rem;
  }
  .ms-lg-n140 {
    margin-left: -8.75rem;
  }
  .ms-lg-n150 {
    margin-left: -9.375rem;
  }
  .ms-lg-n160 {
    margin-left: -10rem;
  }
  .ms-lg-n200 {
    margin-left: -12.5rem;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-1 {
    padding: 0.25rem;
  }
  .p-lg-2 {
    padding: 0.5rem;
  }
  .p-lg-3 {
    padding: 1rem;
  }
  .p-lg-4 {
    padding: 1.5rem;
  }
  .p-lg-5 {
    padding: 3rem;
  }
  .p-lg-7 {
    padding: 0.4375rem;
  }
  .p-lg-10 {
    padding: 0.625rem;
  }
  .p-lg-20 {
    padding: 1.25rem;
  }
  .p-lg-30 {
    padding: 1.875rem;
  }
  .p-lg-35 {
    padding: 2.1875rem;
  }
  .p-lg-40 {
    padding: 2.5rem;
  }
  .p-lg-60 {
    padding: 3.75rem;
  }
  .p-lg-70 {
    padding: 4.375rem;
  }
  .p-lg-80 {
    padding: 5rem;
  }
  .p-lg-90 {
    padding: 5.625rem;
  }
  .p-lg-100 {
    padding: 6.25rem;
  }
  .p-lg-120 {
    padding: 7.5rem;
  }
  .p-lg-130 {
    padding: 8.125rem;
  }
  .p-lg-140 {
    padding: 8.75rem;
  }
  .p-lg-150 {
    padding: 9.375rem;
  }
  .p-lg-160 {
    padding: 10rem;
  }
  .p-lg-200 {
    padding: 12.5rem;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-lg-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-lg-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-lg-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-lg-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-lg-7 {
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
  }
  .px-lg-10 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-lg-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-lg-30 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-lg-35 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-lg-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-lg-60 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-lg-70 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-lg-80 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-lg-90 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-lg-100 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-lg-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-lg-130 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-lg-140 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-lg-150 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-lg-160 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-lg-200 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-lg-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-lg-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-lg-7 {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .py-lg-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-lg-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-lg-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-lg-35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-lg-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-lg-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-lg-70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-lg-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-lg-90 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-lg-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-lg-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-lg-130 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-lg-140 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-lg-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-lg-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-lg-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-1 {
    padding-top: 0.25rem;
  }
  .pt-lg-2 {
    padding-top: 0.5rem;
  }
  .pt-lg-3 {
    padding-top: 1rem;
  }
  .pt-lg-4 {
    padding-top: 1.5rem;
  }
  .pt-lg-5 {
    padding-top: 3rem;
  }
  .pt-lg-7 {
    padding-top: 0.4375rem;
  }
  .pt-lg-10 {
    padding-top: 0.625rem;
  }
  .pt-lg-20 {
    padding-top: 1.25rem;
  }
  .pt-lg-30 {
    padding-top: 1.875rem;
  }
  .pt-lg-35 {
    padding-top: 2.1875rem;
  }
  .pt-lg-40 {
    padding-top: 2.5rem;
  }
  .pt-lg-60 {
    padding-top: 3.75rem;
  }
  .pt-lg-70 {
    padding-top: 4.375rem;
  }
  .pt-lg-80 {
    padding-top: 5rem;
  }
  .pt-lg-90 {
    padding-top: 5.625rem;
  }
  .pt-lg-100 {
    padding-top: 6.25rem;
  }
  .pt-lg-120 {
    padding-top: 7.5rem;
  }
  .pt-lg-130 {
    padding-top: 8.125rem;
  }
  .pt-lg-140 {
    padding-top: 8.75rem;
  }
  .pt-lg-150 {
    padding-top: 9.375rem;
  }
  .pt-lg-160 {
    padding-top: 10rem;
  }
  .pt-lg-200 {
    padding-top: 12.5rem;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-1 {
    padding-right: 0.25rem;
  }
  .pe-lg-2 {
    padding-right: 0.5rem;
  }
  .pe-lg-3 {
    padding-right: 1rem;
  }
  .pe-lg-4 {
    padding-right: 1.5rem;
  }
  .pe-lg-5 {
    padding-right: 3rem;
  }
  .pe-lg-7 {
    padding-right: 0.4375rem;
  }
  .pe-lg-10 {
    padding-right: 0.625rem;
  }
  .pe-lg-20 {
    padding-right: 1.25rem;
  }
  .pe-lg-30 {
    padding-right: 1.875rem;
  }
  .pe-lg-35 {
    padding-right: 2.1875rem;
  }
  .pe-lg-40 {
    padding-right: 2.5rem;
  }
  .pe-lg-60 {
    padding-right: 3.75rem;
  }
  .pe-lg-70 {
    padding-right: 4.375rem;
  }
  .pe-lg-80 {
    padding-right: 5rem;
  }
  .pe-lg-90 {
    padding-right: 5.625rem;
  }
  .pe-lg-100 {
    padding-right: 6.25rem;
  }
  .pe-lg-120 {
    padding-right: 7.5rem;
  }
  .pe-lg-130 {
    padding-right: 8.125rem;
  }
  .pe-lg-140 {
    padding-right: 8.75rem;
  }
  .pe-lg-150 {
    padding-right: 9.375rem;
  }
  .pe-lg-160 {
    padding-right: 10rem;
  }
  .pe-lg-200 {
    padding-right: 12.5rem;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
  }
  .pb-lg-3 {
    padding-bottom: 1rem;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem;
  }
  .pb-lg-5 {
    padding-bottom: 3rem;
  }
  .pb-lg-7 {
    padding-bottom: 0.4375rem;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem;
  }
  .pb-lg-30 {
    padding-bottom: 1.875rem;
  }
  .pb-lg-35 {
    padding-bottom: 2.1875rem;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-60 {
    padding-bottom: 3.75rem;
  }
  .pb-lg-70 {
    padding-bottom: 4.375rem;
  }
  .pb-lg-80 {
    padding-bottom: 5rem;
  }
  .pb-lg-90 {
    padding-bottom: 5.625rem;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem;
  }
  .pb-lg-120 {
    padding-bottom: 7.5rem;
  }
  .pb-lg-130 {
    padding-bottom: 8.125rem;
  }
  .pb-lg-140 {
    padding-bottom: 8.75rem;
  }
  .pb-lg-150 {
    padding-bottom: 9.375rem;
  }
  .pb-lg-160 {
    padding-bottom: 10rem;
  }
  .pb-lg-200 {
    padding-bottom: 12.5rem;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-1 {
    padding-left: 0.25rem;
  }
  .ps-lg-2 {
    padding-left: 0.5rem;
  }
  .ps-lg-3 {
    padding-left: 1rem;
  }
  .ps-lg-4 {
    padding-left: 1.5rem;
  }
  .ps-lg-5 {
    padding-left: 3rem;
  }
  .ps-lg-7 {
    padding-left: 0.4375rem;
  }
  .ps-lg-10 {
    padding-left: 0.625rem;
  }
  .ps-lg-20 {
    padding-left: 1.25rem;
  }
  .ps-lg-30 {
    padding-left: 1.875rem;
  }
  .ps-lg-35 {
    padding-left: 2.1875rem;
  }
  .ps-lg-40 {
    padding-left: 2.5rem;
  }
  .ps-lg-60 {
    padding-left: 3.75rem;
  }
  .ps-lg-70 {
    padding-left: 4.375rem;
  }
  .ps-lg-80 {
    padding-left: 5rem;
  }
  .ps-lg-90 {
    padding-left: 5.625rem;
  }
  .ps-lg-100 {
    padding-left: 6.25rem;
  }
  .ps-lg-120 {
    padding-left: 7.5rem;
  }
  .ps-lg-130 {
    padding-left: 8.125rem;
  }
  .ps-lg-140 {
    padding-left: 8.75rem;
  }
  .ps-lg-150 {
    padding-left: 9.375rem;
  }
  .ps-lg-160 {
    padding-left: 10rem;
  }
  .ps-lg-200 {
    padding-left: 12.5rem;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-1 {
    gap: 0.25rem;
  }
  .gap-lg-2 {
    gap: 0.5rem;
  }
  .gap-lg-3 {
    gap: 1rem;
  }
  .gap-lg-4 {
    gap: 1.5rem;
  }
  .gap-lg-5 {
    gap: 3rem;
  }
  .gap-lg-7 {
    gap: 0.4375rem;
  }
  .gap-lg-10 {
    gap: 0.625rem;
  }
  .gap-lg-20 {
    gap: 1.25rem;
  }
  .gap-lg-30 {
    gap: 1.875rem;
  }
  .gap-lg-35 {
    gap: 2.1875rem;
  }
  .gap-lg-40 {
    gap: 2.5rem;
  }
  .gap-lg-60 {
    gap: 3.75rem;
  }
  .gap-lg-70 {
    gap: 4.375rem;
  }
  .gap-lg-80 {
    gap: 5rem;
  }
  .gap-lg-90 {
    gap: 5.625rem;
  }
  .gap-lg-100 {
    gap: 6.25rem;
  }
  .gap-lg-120 {
    gap: 7.5rem;
  }
  .gap-lg-130 {
    gap: 8.125rem;
  }
  .gap-lg-140 {
    gap: 8.75rem;
  }
  .gap-lg-150 {
    gap: 9.375rem;
  }
  .gap-lg-160 {
    gap: 10rem;
  }
  .gap-lg-200 {
    gap: 12.5rem;
  }
  .row-gap-lg-0 {
    row-gap: 0;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem;
  }
  .row-gap-lg-3 {
    row-gap: 1rem;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem;
  }
  .row-gap-lg-5 {
    row-gap: 3rem;
  }
  .row-gap-lg-7 {
    row-gap: 0.4375rem;
  }
  .row-gap-lg-10 {
    row-gap: 0.625rem;
  }
  .row-gap-lg-20 {
    row-gap: 1.25rem;
  }
  .row-gap-lg-30 {
    row-gap: 1.875rem;
  }
  .row-gap-lg-35 {
    row-gap: 2.1875rem;
  }
  .row-gap-lg-40 {
    row-gap: 2.5rem;
  }
  .row-gap-lg-60 {
    row-gap: 3.75rem;
  }
  .row-gap-lg-70 {
    row-gap: 4.375rem;
  }
  .row-gap-lg-80 {
    row-gap: 5rem;
  }
  .row-gap-lg-90 {
    row-gap: 5.625rem;
  }
  .row-gap-lg-100 {
    row-gap: 6.25rem;
  }
  .row-gap-lg-120 {
    row-gap: 7.5rem;
  }
  .row-gap-lg-130 {
    row-gap: 8.125rem;
  }
  .row-gap-lg-140 {
    row-gap: 8.75rem;
  }
  .row-gap-lg-150 {
    row-gap: 9.375rem;
  }
  .row-gap-lg-160 {
    row-gap: 10rem;
  }
  .row-gap-lg-200 {
    row-gap: 12.5rem;
  }
  .column-gap-lg-0 {
    column-gap: 0;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem;
  }
  .column-gap-lg-3 {
    column-gap: 1rem;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem;
  }
  .column-gap-lg-5 {
    column-gap: 3rem;
  }
  .column-gap-lg-7 {
    column-gap: 0.4375rem;
  }
  .column-gap-lg-10 {
    column-gap: 0.625rem;
  }
  .column-gap-lg-20 {
    column-gap: 1.25rem;
  }
  .column-gap-lg-30 {
    column-gap: 1.875rem;
  }
  .column-gap-lg-35 {
    column-gap: 2.1875rem;
  }
  .column-gap-lg-40 {
    column-gap: 2.5rem;
  }
  .column-gap-lg-60 {
    column-gap: 3.75rem;
  }
  .column-gap-lg-70 {
    column-gap: 4.375rem;
  }
  .column-gap-lg-80 {
    column-gap: 5rem;
  }
  .column-gap-lg-90 {
    column-gap: 5.625rem;
  }
  .column-gap-lg-100 {
    column-gap: 6.25rem;
  }
  .column-gap-lg-120 {
    column-gap: 7.5rem;
  }
  .column-gap-lg-130 {
    column-gap: 8.125rem;
  }
  .column-gap-lg-140 {
    column-gap: 8.75rem;
  }
  .column-gap-lg-150 {
    column-gap: 9.375rem;
  }
  .column-gap-lg-160 {
    column-gap: 10rem;
  }
  .column-gap-lg-200 {
    column-gap: 12.5rem;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .object-fit-xl-contain {
    object-fit: contain;
  }
  .object-fit-xl-cover {
    object-fit: cover;
  }
  .object-fit-xl-fill {
    object-fit: fill;
  }
  .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .object-fit-xl-none {
    object-fit: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-inline-grid {
    display: inline-grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-1 {
    margin: 0.25rem;
  }
  .m-xl-2 {
    margin: 0.5rem;
  }
  .m-xl-3 {
    margin: 1rem;
  }
  .m-xl-4 {
    margin: 1.5rem;
  }
  .m-xl-5 {
    margin: 3rem;
  }
  .m-xl-7 {
    margin: 0.4375rem;
  }
  .m-xl-10 {
    margin: 0.625rem;
  }
  .m-xl-20 {
    margin: 1.25rem;
  }
  .m-xl-30 {
    margin: 1.875rem;
  }
  .m-xl-35 {
    margin: 2.1875rem;
  }
  .m-xl-40 {
    margin: 2.5rem;
  }
  .m-xl-60 {
    margin: 3.75rem;
  }
  .m-xl-70 {
    margin: 4.375rem;
  }
  .m-xl-80 {
    margin: 5rem;
  }
  .m-xl-90 {
    margin: 5.625rem;
  }
  .m-xl-100 {
    margin: 6.25rem;
  }
  .m-xl-120 {
    margin: 7.5rem;
  }
  .m-xl-130 {
    margin: 8.125rem;
  }
  .m-xl-140 {
    margin: 8.75rem;
  }
  .m-xl-150 {
    margin: 9.375rem;
  }
  .m-xl-160 {
    margin: 10rem;
  }
  .m-xl-200 {
    margin: 12.5rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xl-7 {
    margin-right: 0.4375rem;
    margin-left: 0.4375rem;
  }
  .mx-xl-10 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-xl-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xl-30 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-xl-35 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-xl-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xl-60 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xl-70 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-xl-80 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xl-90 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-xl-100 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-xl-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-xl-130 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-xl-140 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-xl-150 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-xl-160 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-xl-200 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xl-7 {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }
  .my-xl-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-xl-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xl-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-xl-35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-xl-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xl-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xl-70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-xl-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xl-90 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-xl-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-xl-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-xl-130 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-xl-140 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-xl-150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-xl-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-xl-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-1 {
    margin-top: 0.25rem;
  }
  .mt-xl-2 {
    margin-top: 0.5rem;
  }
  .mt-xl-3 {
    margin-top: 1rem;
  }
  .mt-xl-4 {
    margin-top: 1.5rem;
  }
  .mt-xl-5 {
    margin-top: 3rem;
  }
  .mt-xl-7 {
    margin-top: 0.4375rem;
  }
  .mt-xl-10 {
    margin-top: 0.625rem;
  }
  .mt-xl-20 {
    margin-top: 1.25rem;
  }
  .mt-xl-30 {
    margin-top: 1.875rem;
  }
  .mt-xl-35 {
    margin-top: 2.1875rem;
  }
  .mt-xl-40 {
    margin-top: 2.5rem;
  }
  .mt-xl-60 {
    margin-top: 3.75rem;
  }
  .mt-xl-70 {
    margin-top: 4.375rem;
  }
  .mt-xl-80 {
    margin-top: 5rem;
  }
  .mt-xl-90 {
    margin-top: 5.625rem;
  }
  .mt-xl-100 {
    margin-top: 6.25rem;
  }
  .mt-xl-120 {
    margin-top: 7.5rem;
  }
  .mt-xl-130 {
    margin-top: 8.125rem;
  }
  .mt-xl-140 {
    margin-top: 8.75rem;
  }
  .mt-xl-150 {
    margin-top: 9.375rem;
  }
  .mt-xl-160 {
    margin-top: 10rem;
  }
  .mt-xl-200 {
    margin-top: 12.5rem;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-1 {
    margin-right: 0.25rem;
  }
  .me-xl-2 {
    margin-right: 0.5rem;
  }
  .me-xl-3 {
    margin-right: 1rem;
  }
  .me-xl-4 {
    margin-right: 1.5rem;
  }
  .me-xl-5 {
    margin-right: 3rem;
  }
  .me-xl-7 {
    margin-right: 0.4375rem;
  }
  .me-xl-10 {
    margin-right: 0.625rem;
  }
  .me-xl-20 {
    margin-right: 1.25rem;
  }
  .me-xl-30 {
    margin-right: 1.875rem;
  }
  .me-xl-35 {
    margin-right: 2.1875rem;
  }
  .me-xl-40 {
    margin-right: 2.5rem;
  }
  .me-xl-60 {
    margin-right: 3.75rem;
  }
  .me-xl-70 {
    margin-right: 4.375rem;
  }
  .me-xl-80 {
    margin-right: 5rem;
  }
  .me-xl-90 {
    margin-right: 5.625rem;
  }
  .me-xl-100 {
    margin-right: 6.25rem;
  }
  .me-xl-120 {
    margin-right: 7.5rem;
  }
  .me-xl-130 {
    margin-right: 8.125rem;
  }
  .me-xl-140 {
    margin-right: 8.75rem;
  }
  .me-xl-150 {
    margin-right: 9.375rem;
  }
  .me-xl-160 {
    margin-right: 10rem;
  }
  .me-xl-200 {
    margin-right: 12.5rem;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
  }
  .mb-xl-3 {
    margin-bottom: 1rem;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem;
  }
  .mb-xl-5 {
    margin-bottom: 3rem;
  }
  .mb-xl-7 {
    margin-bottom: 0.4375rem;
  }
  .mb-xl-10 {
    margin-bottom: 0.625rem;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem;
  }
  .mb-xl-30 {
    margin-bottom: 1.875rem;
  }
  .mb-xl-35 {
    margin-bottom: 2.1875rem;
  }
  .mb-xl-40 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-60 {
    margin-bottom: 3.75rem;
  }
  .mb-xl-70 {
    margin-bottom: 4.375rem;
  }
  .mb-xl-80 {
    margin-bottom: 5rem;
  }
  .mb-xl-90 {
    margin-bottom: 5.625rem;
  }
  .mb-xl-100 {
    margin-bottom: 6.25rem;
  }
  .mb-xl-120 {
    margin-bottom: 7.5rem;
  }
  .mb-xl-130 {
    margin-bottom: 8.125rem;
  }
  .mb-xl-140 {
    margin-bottom: 8.75rem;
  }
  .mb-xl-150 {
    margin-bottom: 9.375rem;
  }
  .mb-xl-160 {
    margin-bottom: 10rem;
  }
  .mb-xl-200 {
    margin-bottom: 12.5rem;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-1 {
    margin-left: 0.25rem;
  }
  .ms-xl-2 {
    margin-left: 0.5rem;
  }
  .ms-xl-3 {
    margin-left: 1rem;
  }
  .ms-xl-4 {
    margin-left: 1.5rem;
  }
  .ms-xl-5 {
    margin-left: 3rem;
  }
  .ms-xl-7 {
    margin-left: 0.4375rem;
  }
  .ms-xl-10 {
    margin-left: 0.625rem;
  }
  .ms-xl-20 {
    margin-left: 1.25rem;
  }
  .ms-xl-30 {
    margin-left: 1.875rem;
  }
  .ms-xl-35 {
    margin-left: 2.1875rem;
  }
  .ms-xl-40 {
    margin-left: 2.5rem;
  }
  .ms-xl-60 {
    margin-left: 3.75rem;
  }
  .ms-xl-70 {
    margin-left: 4.375rem;
  }
  .ms-xl-80 {
    margin-left: 5rem;
  }
  .ms-xl-90 {
    margin-left: 5.625rem;
  }
  .ms-xl-100 {
    margin-left: 6.25rem;
  }
  .ms-xl-120 {
    margin-left: 7.5rem;
  }
  .ms-xl-130 {
    margin-left: 8.125rem;
  }
  .ms-xl-140 {
    margin-left: 8.75rem;
  }
  .ms-xl-150 {
    margin-left: 9.375rem;
  }
  .ms-xl-160 {
    margin-left: 10rem;
  }
  .ms-xl-200 {
    margin-left: 12.5rem;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .m-xl-n1 {
    margin: -0.25rem;
  }
  .m-xl-n2 {
    margin: -0.5rem;
  }
  .m-xl-n3 {
    margin: -1rem;
  }
  .m-xl-n4 {
    margin: -1.5rem;
  }
  .m-xl-n5 {
    margin: -3rem;
  }
  .m-xl-n7 {
    margin: -0.4375rem;
  }
  .m-xl-n10 {
    margin: -0.625rem;
  }
  .m-xl-n20 {
    margin: -1.25rem;
  }
  .m-xl-n30 {
    margin: -1.875rem;
  }
  .m-xl-n35 {
    margin: -2.1875rem;
  }
  .m-xl-n40 {
    margin: -2.5rem;
  }
  .m-xl-n60 {
    margin: -3.75rem;
  }
  .m-xl-n70 {
    margin: -4.375rem;
  }
  .m-xl-n80 {
    margin: -5rem;
  }
  .m-xl-n90 {
    margin: -5.625rem;
  }
  .m-xl-n100 {
    margin: -6.25rem;
  }
  .m-xl-n120 {
    margin: -7.5rem;
  }
  .m-xl-n130 {
    margin: -8.125rem;
  }
  .m-xl-n140 {
    margin: -8.75rem;
  }
  .m-xl-n150 {
    margin: -9.375rem;
  }
  .m-xl-n160 {
    margin: -10rem;
  }
  .m-xl-n200 {
    margin: -12.5rem;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xl-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xl-n7 {
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
  }
  .mx-xl-n10 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .mx-xl-n20 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xl-n30 {
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .mx-xl-n35 {
    margin-right: -2.1875rem;
    margin-left: -2.1875rem;
  }
  .mx-xl-n40 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xl-n60 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xl-n70 {
    margin-right: -4.375rem;
    margin-left: -4.375rem;
  }
  .mx-xl-n80 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xl-n90 {
    margin-right: -5.625rem;
    margin-left: -5.625rem;
  }
  .mx-xl-n100 {
    margin-right: -6.25rem;
    margin-left: -6.25rem;
  }
  .mx-xl-n120 {
    margin-right: -7.5rem;
    margin-left: -7.5rem;
  }
  .mx-xl-n130 {
    margin-right: -8.125rem;
    margin-left: -8.125rem;
  }
  .mx-xl-n140 {
    margin-right: -8.75rem;
    margin-left: -8.75rem;
  }
  .mx-xl-n150 {
    margin-right: -9.375rem;
    margin-left: -9.375rem;
  }
  .mx-xl-n160 {
    margin-right: -10rem;
    margin-left: -10rem;
  }
  .mx-xl-n200 {
    margin-right: -12.5rem;
    margin-left: -12.5rem;
  }
  .my-xl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xl-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xl-n7 {
    margin-top: -0.4375rem;
    margin-bottom: -0.4375rem;
  }
  .my-xl-n10 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .my-xl-n20 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xl-n30 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .my-xl-n35 {
    margin-top: -2.1875rem;
    margin-bottom: -2.1875rem;
  }
  .my-xl-n40 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xl-n60 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xl-n70 {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
  .my-xl-n80 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xl-n90 {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem;
  }
  .my-xl-n100 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
  .my-xl-n120 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .my-xl-n130 {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem;
  }
  .my-xl-n140 {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem;
  }
  .my-xl-n150 {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem;
  }
  .my-xl-n160 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .my-xl-n200 {
    margin-top: -12.5rem;
    margin-bottom: -12.5rem;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem;
  }
  .mt-xl-n3 {
    margin-top: -1rem;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem;
  }
  .mt-xl-n5 {
    margin-top: -3rem;
  }
  .mt-xl-n7 {
    margin-top: -0.4375rem;
  }
  .mt-xl-n10 {
    margin-top: -0.625rem;
  }
  .mt-xl-n20 {
    margin-top: -1.25rem;
  }
  .mt-xl-n30 {
    margin-top: -1.875rem;
  }
  .mt-xl-n35 {
    margin-top: -2.1875rem;
  }
  .mt-xl-n40 {
    margin-top: -2.5rem;
  }
  .mt-xl-n60 {
    margin-top: -3.75rem;
  }
  .mt-xl-n70 {
    margin-top: -4.375rem;
  }
  .mt-xl-n80 {
    margin-top: -5rem;
  }
  .mt-xl-n90 {
    margin-top: -5.625rem;
  }
  .mt-xl-n100 {
    margin-top: -6.25rem;
  }
  .mt-xl-n120 {
    margin-top: -7.5rem;
  }
  .mt-xl-n130 {
    margin-top: -8.125rem;
  }
  .mt-xl-n140 {
    margin-top: -8.75rem;
  }
  .mt-xl-n150 {
    margin-top: -9.375rem;
  }
  .mt-xl-n160 {
    margin-top: -10rem;
  }
  .mt-xl-n200 {
    margin-top: -12.5rem;
  }
  .me-xl-n1 {
    margin-right: -0.25rem;
  }
  .me-xl-n2 {
    margin-right: -0.5rem;
  }
  .me-xl-n3 {
    margin-right: -1rem;
  }
  .me-xl-n4 {
    margin-right: -1.5rem;
  }
  .me-xl-n5 {
    margin-right: -3rem;
  }
  .me-xl-n7 {
    margin-right: -0.4375rem;
  }
  .me-xl-n10 {
    margin-right: -0.625rem;
  }
  .me-xl-n20 {
    margin-right: -1.25rem;
  }
  .me-xl-n30 {
    margin-right: -1.875rem;
  }
  .me-xl-n35 {
    margin-right: -2.1875rem;
  }
  .me-xl-n40 {
    margin-right: -2.5rem;
  }
  .me-xl-n60 {
    margin-right: -3.75rem;
  }
  .me-xl-n70 {
    margin-right: -4.375rem;
  }
  .me-xl-n80 {
    margin-right: -5rem;
  }
  .me-xl-n90 {
    margin-right: -5.625rem;
  }
  .me-xl-n100 {
    margin-right: -6.25rem;
  }
  .me-xl-n120 {
    margin-right: -7.5rem;
  }
  .me-xl-n130 {
    margin-right: -8.125rem;
  }
  .me-xl-n140 {
    margin-right: -8.75rem;
  }
  .me-xl-n150 {
    margin-right: -9.375rem;
  }
  .me-xl-n160 {
    margin-right: -10rem;
  }
  .me-xl-n200 {
    margin-right: -12.5rem;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem;
  }
  .mb-xl-n7 {
    margin-bottom: -0.4375rem;
  }
  .mb-xl-n10 {
    margin-bottom: -0.625rem;
  }
  .mb-xl-n20 {
    margin-bottom: -1.25rem;
  }
  .mb-xl-n30 {
    margin-bottom: -1.875rem;
  }
  .mb-xl-n35 {
    margin-bottom: -2.1875rem;
  }
  .mb-xl-n40 {
    margin-bottom: -2.5rem;
  }
  .mb-xl-n60 {
    margin-bottom: -3.75rem;
  }
  .mb-xl-n70 {
    margin-bottom: -4.375rem;
  }
  .mb-xl-n80 {
    margin-bottom: -5rem;
  }
  .mb-xl-n90 {
    margin-bottom: -5.625rem;
  }
  .mb-xl-n100 {
    margin-bottom: -6.25rem;
  }
  .mb-xl-n120 {
    margin-bottom: -7.5rem;
  }
  .mb-xl-n130 {
    margin-bottom: -8.125rem;
  }
  .mb-xl-n140 {
    margin-bottom: -8.75rem;
  }
  .mb-xl-n150 {
    margin-bottom: -9.375rem;
  }
  .mb-xl-n160 {
    margin-bottom: -10rem;
  }
  .mb-xl-n200 {
    margin-bottom: -12.5rem;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem;
  }
  .ms-xl-n3 {
    margin-left: -1rem;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem;
  }
  .ms-xl-n5 {
    margin-left: -3rem;
  }
  .ms-xl-n7 {
    margin-left: -0.4375rem;
  }
  .ms-xl-n10 {
    margin-left: -0.625rem;
  }
  .ms-xl-n20 {
    margin-left: -1.25rem;
  }
  .ms-xl-n30 {
    margin-left: -1.875rem;
  }
  .ms-xl-n35 {
    margin-left: -2.1875rem;
  }
  .ms-xl-n40 {
    margin-left: -2.5rem;
  }
  .ms-xl-n60 {
    margin-left: -3.75rem;
  }
  .ms-xl-n70 {
    margin-left: -4.375rem;
  }
  .ms-xl-n80 {
    margin-left: -5rem;
  }
  .ms-xl-n90 {
    margin-left: -5.625rem;
  }
  .ms-xl-n100 {
    margin-left: -6.25rem;
  }
  .ms-xl-n120 {
    margin-left: -7.5rem;
  }
  .ms-xl-n130 {
    margin-left: -8.125rem;
  }
  .ms-xl-n140 {
    margin-left: -8.75rem;
  }
  .ms-xl-n150 {
    margin-left: -9.375rem;
  }
  .ms-xl-n160 {
    margin-left: -10rem;
  }
  .ms-xl-n200 {
    margin-left: -12.5rem;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-1 {
    padding: 0.25rem;
  }
  .p-xl-2 {
    padding: 0.5rem;
  }
  .p-xl-3 {
    padding: 1rem;
  }
  .p-xl-4 {
    padding: 1.5rem;
  }
  .p-xl-5 {
    padding: 3rem;
  }
  .p-xl-7 {
    padding: 0.4375rem;
  }
  .p-xl-10 {
    padding: 0.625rem;
  }
  .p-xl-20 {
    padding: 1.25rem;
  }
  .p-xl-30 {
    padding: 1.875rem;
  }
  .p-xl-35 {
    padding: 2.1875rem;
  }
  .p-xl-40 {
    padding: 2.5rem;
  }
  .p-xl-60 {
    padding: 3.75rem;
  }
  .p-xl-70 {
    padding: 4.375rem;
  }
  .p-xl-80 {
    padding: 5rem;
  }
  .p-xl-90 {
    padding: 5.625rem;
  }
  .p-xl-100 {
    padding: 6.25rem;
  }
  .p-xl-120 {
    padding: 7.5rem;
  }
  .p-xl-130 {
    padding: 8.125rem;
  }
  .p-xl-140 {
    padding: 8.75rem;
  }
  .p-xl-150 {
    padding: 9.375rem;
  }
  .p-xl-160 {
    padding: 10rem;
  }
  .p-xl-200 {
    padding: 12.5rem;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xl-7 {
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
  }
  .px-xl-10 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-xl-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xl-30 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-xl-35 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-xl-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xl-60 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xl-70 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-xl-80 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xl-90 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-xl-100 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-xl-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-xl-130 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-xl-140 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-xl-150 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-xl-160 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-xl-200 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xl-7 {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .py-xl-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-xl-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xl-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-xl-35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-xl-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xl-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xl-70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-xl-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xl-90 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-xl-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-xl-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-xl-130 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-xl-140 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-xl-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-xl-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-xl-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-1 {
    padding-top: 0.25rem;
  }
  .pt-xl-2 {
    padding-top: 0.5rem;
  }
  .pt-xl-3 {
    padding-top: 1rem;
  }
  .pt-xl-4 {
    padding-top: 1.5rem;
  }
  .pt-xl-5 {
    padding-top: 3rem;
  }
  .pt-xl-7 {
    padding-top: 0.4375rem;
  }
  .pt-xl-10 {
    padding-top: 0.625rem;
  }
  .pt-xl-20 {
    padding-top: 1.25rem;
  }
  .pt-xl-30 {
    padding-top: 1.875rem;
  }
  .pt-xl-35 {
    padding-top: 2.1875rem;
  }
  .pt-xl-40 {
    padding-top: 2.5rem;
  }
  .pt-xl-60 {
    padding-top: 3.75rem;
  }
  .pt-xl-70 {
    padding-top: 4.375rem;
  }
  .pt-xl-80 {
    padding-top: 5rem;
  }
  .pt-xl-90 {
    padding-top: 5.625rem;
  }
  .pt-xl-100 {
    padding-top: 6.25rem;
  }
  .pt-xl-120 {
    padding-top: 7.5rem;
  }
  .pt-xl-130 {
    padding-top: 8.125rem;
  }
  .pt-xl-140 {
    padding-top: 8.75rem;
  }
  .pt-xl-150 {
    padding-top: 9.375rem;
  }
  .pt-xl-160 {
    padding-top: 10rem;
  }
  .pt-xl-200 {
    padding-top: 12.5rem;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-1 {
    padding-right: 0.25rem;
  }
  .pe-xl-2 {
    padding-right: 0.5rem;
  }
  .pe-xl-3 {
    padding-right: 1rem;
  }
  .pe-xl-4 {
    padding-right: 1.5rem;
  }
  .pe-xl-5 {
    padding-right: 3rem;
  }
  .pe-xl-7 {
    padding-right: 0.4375rem;
  }
  .pe-xl-10 {
    padding-right: 0.625rem;
  }
  .pe-xl-20 {
    padding-right: 1.25rem;
  }
  .pe-xl-30 {
    padding-right: 1.875rem;
  }
  .pe-xl-35 {
    padding-right: 2.1875rem;
  }
  .pe-xl-40 {
    padding-right: 2.5rem;
  }
  .pe-xl-60 {
    padding-right: 3.75rem;
  }
  .pe-xl-70 {
    padding-right: 4.375rem;
  }
  .pe-xl-80 {
    padding-right: 5rem;
  }
  .pe-xl-90 {
    padding-right: 5.625rem;
  }
  .pe-xl-100 {
    padding-right: 6.25rem;
  }
  .pe-xl-120 {
    padding-right: 7.5rem;
  }
  .pe-xl-130 {
    padding-right: 8.125rem;
  }
  .pe-xl-140 {
    padding-right: 8.75rem;
  }
  .pe-xl-150 {
    padding-right: 9.375rem;
  }
  .pe-xl-160 {
    padding-right: 10rem;
  }
  .pe-xl-200 {
    padding-right: 12.5rem;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
  }
  .pb-xl-3 {
    padding-bottom: 1rem;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem;
  }
  .pb-xl-5 {
    padding-bottom: 3rem;
  }
  .pb-xl-7 {
    padding-bottom: 0.4375rem;
  }
  .pb-xl-10 {
    padding-bottom: 0.625rem;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem;
  }
  .pb-xl-30 {
    padding-bottom: 1.875rem;
  }
  .pb-xl-35 {
    padding-bottom: 2.1875rem;
  }
  .pb-xl-40 {
    padding-bottom: 2.5rem;
  }
  .pb-xl-60 {
    padding-bottom: 3.75rem;
  }
  .pb-xl-70 {
    padding-bottom: 4.375rem;
  }
  .pb-xl-80 {
    padding-bottom: 5rem;
  }
  .pb-xl-90 {
    padding-bottom: 5.625rem;
  }
  .pb-xl-100 {
    padding-bottom: 6.25rem;
  }
  .pb-xl-120 {
    padding-bottom: 7.5rem;
  }
  .pb-xl-130 {
    padding-bottom: 8.125rem;
  }
  .pb-xl-140 {
    padding-bottom: 8.75rem;
  }
  .pb-xl-150 {
    padding-bottom: 9.375rem;
  }
  .pb-xl-160 {
    padding-bottom: 10rem;
  }
  .pb-xl-200 {
    padding-bottom: 12.5rem;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-1 {
    padding-left: 0.25rem;
  }
  .ps-xl-2 {
    padding-left: 0.5rem;
  }
  .ps-xl-3 {
    padding-left: 1rem;
  }
  .ps-xl-4 {
    padding-left: 1.5rem;
  }
  .ps-xl-5 {
    padding-left: 3rem;
  }
  .ps-xl-7 {
    padding-left: 0.4375rem;
  }
  .ps-xl-10 {
    padding-left: 0.625rem;
  }
  .ps-xl-20 {
    padding-left: 1.25rem;
  }
  .ps-xl-30 {
    padding-left: 1.875rem;
  }
  .ps-xl-35 {
    padding-left: 2.1875rem;
  }
  .ps-xl-40 {
    padding-left: 2.5rem;
  }
  .ps-xl-60 {
    padding-left: 3.75rem;
  }
  .ps-xl-70 {
    padding-left: 4.375rem;
  }
  .ps-xl-80 {
    padding-left: 5rem;
  }
  .ps-xl-90 {
    padding-left: 5.625rem;
  }
  .ps-xl-100 {
    padding-left: 6.25rem;
  }
  .ps-xl-120 {
    padding-left: 7.5rem;
  }
  .ps-xl-130 {
    padding-left: 8.125rem;
  }
  .ps-xl-140 {
    padding-left: 8.75rem;
  }
  .ps-xl-150 {
    padding-left: 9.375rem;
  }
  .ps-xl-160 {
    padding-left: 10rem;
  }
  .ps-xl-200 {
    padding-left: 12.5rem;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-1 {
    gap: 0.25rem;
  }
  .gap-xl-2 {
    gap: 0.5rem;
  }
  .gap-xl-3 {
    gap: 1rem;
  }
  .gap-xl-4 {
    gap: 1.5rem;
  }
  .gap-xl-5 {
    gap: 3rem;
  }
  .gap-xl-7 {
    gap: 0.4375rem;
  }
  .gap-xl-10 {
    gap: 0.625rem;
  }
  .gap-xl-20 {
    gap: 1.25rem;
  }
  .gap-xl-30 {
    gap: 1.875rem;
  }
  .gap-xl-35 {
    gap: 2.1875rem;
  }
  .gap-xl-40 {
    gap: 2.5rem;
  }
  .gap-xl-60 {
    gap: 3.75rem;
  }
  .gap-xl-70 {
    gap: 4.375rem;
  }
  .gap-xl-80 {
    gap: 5rem;
  }
  .gap-xl-90 {
    gap: 5.625rem;
  }
  .gap-xl-100 {
    gap: 6.25rem;
  }
  .gap-xl-120 {
    gap: 7.5rem;
  }
  .gap-xl-130 {
    gap: 8.125rem;
  }
  .gap-xl-140 {
    gap: 8.75rem;
  }
  .gap-xl-150 {
    gap: 9.375rem;
  }
  .gap-xl-160 {
    gap: 10rem;
  }
  .gap-xl-200 {
    gap: 12.5rem;
  }
  .row-gap-xl-0 {
    row-gap: 0;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem;
  }
  .row-gap-xl-3 {
    row-gap: 1rem;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem;
  }
  .row-gap-xl-5 {
    row-gap: 3rem;
  }
  .row-gap-xl-7 {
    row-gap: 0.4375rem;
  }
  .row-gap-xl-10 {
    row-gap: 0.625rem;
  }
  .row-gap-xl-20 {
    row-gap: 1.25rem;
  }
  .row-gap-xl-30 {
    row-gap: 1.875rem;
  }
  .row-gap-xl-35 {
    row-gap: 2.1875rem;
  }
  .row-gap-xl-40 {
    row-gap: 2.5rem;
  }
  .row-gap-xl-60 {
    row-gap: 3.75rem;
  }
  .row-gap-xl-70 {
    row-gap: 4.375rem;
  }
  .row-gap-xl-80 {
    row-gap: 5rem;
  }
  .row-gap-xl-90 {
    row-gap: 5.625rem;
  }
  .row-gap-xl-100 {
    row-gap: 6.25rem;
  }
  .row-gap-xl-120 {
    row-gap: 7.5rem;
  }
  .row-gap-xl-130 {
    row-gap: 8.125rem;
  }
  .row-gap-xl-140 {
    row-gap: 8.75rem;
  }
  .row-gap-xl-150 {
    row-gap: 9.375rem;
  }
  .row-gap-xl-160 {
    row-gap: 10rem;
  }
  .row-gap-xl-200 {
    row-gap: 12.5rem;
  }
  .column-gap-xl-0 {
    column-gap: 0;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem;
  }
  .column-gap-xl-3 {
    column-gap: 1rem;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem;
  }
  .column-gap-xl-5 {
    column-gap: 3rem;
  }
  .column-gap-xl-7 {
    column-gap: 0.4375rem;
  }
  .column-gap-xl-10 {
    column-gap: 0.625rem;
  }
  .column-gap-xl-20 {
    column-gap: 1.25rem;
  }
  .column-gap-xl-30 {
    column-gap: 1.875rem;
  }
  .column-gap-xl-35 {
    column-gap: 2.1875rem;
  }
  .column-gap-xl-40 {
    column-gap: 2.5rem;
  }
  .column-gap-xl-60 {
    column-gap: 3.75rem;
  }
  .column-gap-xl-70 {
    column-gap: 4.375rem;
  }
  .column-gap-xl-80 {
    column-gap: 5rem;
  }
  .column-gap-xl-90 {
    column-gap: 5.625rem;
  }
  .column-gap-xl-100 {
    column-gap: 6.25rem;
  }
  .column-gap-xl-120 {
    column-gap: 7.5rem;
  }
  .column-gap-xl-130 {
    column-gap: 8.125rem;
  }
  .column-gap-xl-140 {
    column-gap: 8.75rem;
  }
  .column-gap-xl-150 {
    column-gap: 9.375rem;
  }
  .column-gap-xl-160 {
    column-gap: 10rem;
  }
  .column-gap-xl-200 {
    column-gap: 12.5rem;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .object-fit-xxl-contain {
    object-fit: contain;
  }
  .object-fit-xxl-cover {
    object-fit: cover;
  }
  .object-fit-xxl-fill {
    object-fit: fill;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxl-none {
    object-fit: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-inline-grid {
    display: inline-grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-1 {
    margin: 0.25rem;
  }
  .m-xxl-2 {
    margin: 0.5rem;
  }
  .m-xxl-3 {
    margin: 1rem;
  }
  .m-xxl-4 {
    margin: 1.5rem;
  }
  .m-xxl-5 {
    margin: 3rem;
  }
  .m-xxl-7 {
    margin: 0.4375rem;
  }
  .m-xxl-10 {
    margin: 0.625rem;
  }
  .m-xxl-20 {
    margin: 1.25rem;
  }
  .m-xxl-30 {
    margin: 1.875rem;
  }
  .m-xxl-35 {
    margin: 2.1875rem;
  }
  .m-xxl-40 {
    margin: 2.5rem;
  }
  .m-xxl-60 {
    margin: 3.75rem;
  }
  .m-xxl-70 {
    margin: 4.375rem;
  }
  .m-xxl-80 {
    margin: 5rem;
  }
  .m-xxl-90 {
    margin: 5.625rem;
  }
  .m-xxl-100 {
    margin: 6.25rem;
  }
  .m-xxl-120 {
    margin: 7.5rem;
  }
  .m-xxl-130 {
    margin: 8.125rem;
  }
  .m-xxl-140 {
    margin: 8.75rem;
  }
  .m-xxl-150 {
    margin: 9.375rem;
  }
  .m-xxl-160 {
    margin: 10rem;
  }
  .m-xxl-200 {
    margin: 12.5rem;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xxl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xxl-5 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xxl-7 {
    margin-right: 0.4375rem;
    margin-left: 0.4375rem;
  }
  .mx-xxl-10 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-xxl-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xxl-30 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-xxl-35 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-xxl-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxl-60 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xxl-70 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-xxl-80 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxl-90 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-xxl-100 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-xxl-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-xxl-130 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-xxl-140 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-xxl-150 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-xxl-160 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-xxl-200 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xxl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xxl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xxl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xxl-7 {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }
  .my-xxl-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-xxl-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xxl-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-xxl-35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-xxl-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxl-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xxl-70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-xxl-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxl-90 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-xxl-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-xxl-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-xxl-130 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-xxl-140 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-xxl-150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-xxl-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-xxl-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem;
  }
  .mt-xxl-3 {
    margin-top: 1rem;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem;
  }
  .mt-xxl-5 {
    margin-top: 3rem;
  }
  .mt-xxl-7 {
    margin-top: 0.4375rem;
  }
  .mt-xxl-10 {
    margin-top: 0.625rem;
  }
  .mt-xxl-20 {
    margin-top: 1.25rem;
  }
  .mt-xxl-30 {
    margin-top: 1.875rem;
  }
  .mt-xxl-35 {
    margin-top: 2.1875rem;
  }
  .mt-xxl-40 {
    margin-top: 2.5rem;
  }
  .mt-xxl-60 {
    margin-top: 3.75rem;
  }
  .mt-xxl-70 {
    margin-top: 4.375rem;
  }
  .mt-xxl-80 {
    margin-top: 5rem;
  }
  .mt-xxl-90 {
    margin-top: 5.625rem;
  }
  .mt-xxl-100 {
    margin-top: 6.25rem;
  }
  .mt-xxl-120 {
    margin-top: 7.5rem;
  }
  .mt-xxl-130 {
    margin-top: 8.125rem;
  }
  .mt-xxl-140 {
    margin-top: 8.75rem;
  }
  .mt-xxl-150 {
    margin-top: 9.375rem;
  }
  .mt-xxl-160 {
    margin-top: 10rem;
  }
  .mt-xxl-200 {
    margin-top: 12.5rem;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-1 {
    margin-right: 0.25rem;
  }
  .me-xxl-2 {
    margin-right: 0.5rem;
  }
  .me-xxl-3 {
    margin-right: 1rem;
  }
  .me-xxl-4 {
    margin-right: 1.5rem;
  }
  .me-xxl-5 {
    margin-right: 3rem;
  }
  .me-xxl-7 {
    margin-right: 0.4375rem;
  }
  .me-xxl-10 {
    margin-right: 0.625rem;
  }
  .me-xxl-20 {
    margin-right: 1.25rem;
  }
  .me-xxl-30 {
    margin-right: 1.875rem;
  }
  .me-xxl-35 {
    margin-right: 2.1875rem;
  }
  .me-xxl-40 {
    margin-right: 2.5rem;
  }
  .me-xxl-60 {
    margin-right: 3.75rem;
  }
  .me-xxl-70 {
    margin-right: 4.375rem;
  }
  .me-xxl-80 {
    margin-right: 5rem;
  }
  .me-xxl-90 {
    margin-right: 5.625rem;
  }
  .me-xxl-100 {
    margin-right: 6.25rem;
  }
  .me-xxl-120 {
    margin-right: 7.5rem;
  }
  .me-xxl-130 {
    margin-right: 8.125rem;
  }
  .me-xxl-140 {
    margin-right: 8.75rem;
  }
  .me-xxl-150 {
    margin-right: 9.375rem;
  }
  .me-xxl-160 {
    margin-right: 10rem;
  }
  .me-xxl-200 {
    margin-right: 12.5rem;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem;
  }
  .mb-xxl-7 {
    margin-bottom: 0.4375rem;
  }
  .mb-xxl-10 {
    margin-bottom: 0.625rem;
  }
  .mb-xxl-20 {
    margin-bottom: 1.25rem;
  }
  .mb-xxl-30 {
    margin-bottom: 1.875rem;
  }
  .mb-xxl-35 {
    margin-bottom: 2.1875rem;
  }
  .mb-xxl-40 {
    margin-bottom: 2.5rem;
  }
  .mb-xxl-60 {
    margin-bottom: 3.75rem;
  }
  .mb-xxl-70 {
    margin-bottom: 4.375rem;
  }
  .mb-xxl-80 {
    margin-bottom: 5rem;
  }
  .mb-xxl-90 {
    margin-bottom: 5.625rem;
  }
  .mb-xxl-100 {
    margin-bottom: 6.25rem;
  }
  .mb-xxl-120 {
    margin-bottom: 7.5rem;
  }
  .mb-xxl-130 {
    margin-bottom: 8.125rem;
  }
  .mb-xxl-140 {
    margin-bottom: 8.75rem;
  }
  .mb-xxl-150 {
    margin-bottom: 9.375rem;
  }
  .mb-xxl-160 {
    margin-bottom: 10rem;
  }
  .mb-xxl-200 {
    margin-bottom: 12.5rem;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem;
  }
  .ms-xxl-3 {
    margin-left: 1rem;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem;
  }
  .ms-xxl-5 {
    margin-left: 3rem;
  }
  .ms-xxl-7 {
    margin-left: 0.4375rem;
  }
  .ms-xxl-10 {
    margin-left: 0.625rem;
  }
  .ms-xxl-20 {
    margin-left: 1.25rem;
  }
  .ms-xxl-30 {
    margin-left: 1.875rem;
  }
  .ms-xxl-35 {
    margin-left: 2.1875rem;
  }
  .ms-xxl-40 {
    margin-left: 2.5rem;
  }
  .ms-xxl-60 {
    margin-left: 3.75rem;
  }
  .ms-xxl-70 {
    margin-left: 4.375rem;
  }
  .ms-xxl-80 {
    margin-left: 5rem;
  }
  .ms-xxl-90 {
    margin-left: 5.625rem;
  }
  .ms-xxl-100 {
    margin-left: 6.25rem;
  }
  .ms-xxl-120 {
    margin-left: 7.5rem;
  }
  .ms-xxl-130 {
    margin-left: 8.125rem;
  }
  .ms-xxl-140 {
    margin-left: 8.75rem;
  }
  .ms-xxl-150 {
    margin-left: 9.375rem;
  }
  .ms-xxl-160 {
    margin-left: 10rem;
  }
  .ms-xxl-200 {
    margin-left: 12.5rem;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .m-xxl-n1 {
    margin: -0.25rem;
  }
  .m-xxl-n2 {
    margin: -0.5rem;
  }
  .m-xxl-n3 {
    margin: -1rem;
  }
  .m-xxl-n4 {
    margin: -1.5rem;
  }
  .m-xxl-n5 {
    margin: -3rem;
  }
  .m-xxl-n7 {
    margin: -0.4375rem;
  }
  .m-xxl-n10 {
    margin: -0.625rem;
  }
  .m-xxl-n20 {
    margin: -1.25rem;
  }
  .m-xxl-n30 {
    margin: -1.875rem;
  }
  .m-xxl-n35 {
    margin: -2.1875rem;
  }
  .m-xxl-n40 {
    margin: -2.5rem;
  }
  .m-xxl-n60 {
    margin: -3.75rem;
  }
  .m-xxl-n70 {
    margin: -4.375rem;
  }
  .m-xxl-n80 {
    margin: -5rem;
  }
  .m-xxl-n90 {
    margin: -5.625rem;
  }
  .m-xxl-n100 {
    margin: -6.25rem;
  }
  .m-xxl-n120 {
    margin: -7.5rem;
  }
  .m-xxl-n130 {
    margin: -8.125rem;
  }
  .m-xxl-n140 {
    margin: -8.75rem;
  }
  .m-xxl-n150 {
    margin: -9.375rem;
  }
  .m-xxl-n160 {
    margin: -10rem;
  }
  .m-xxl-n200 {
    margin: -12.5rem;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xxl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xxl-n5 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xxl-n7 {
    margin-right: -0.4375rem;
    margin-left: -0.4375rem;
  }
  .mx-xxl-n10 {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  .mx-xxl-n20 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .mx-xxl-n30 {
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .mx-xxl-n35 {
    margin-right: -2.1875rem;
    margin-left: -2.1875rem;
  }
  .mx-xxl-n40 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xxl-n60 {
    margin-right: -3.75rem;
    margin-left: -3.75rem;
  }
  .mx-xxl-n70 {
    margin-right: -4.375rem;
    margin-left: -4.375rem;
  }
  .mx-xxl-n80 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xxl-n90 {
    margin-right: -5.625rem;
    margin-left: -5.625rem;
  }
  .mx-xxl-n100 {
    margin-right: -6.25rem;
    margin-left: -6.25rem;
  }
  .mx-xxl-n120 {
    margin-right: -7.5rem;
    margin-left: -7.5rem;
  }
  .mx-xxl-n130 {
    margin-right: -8.125rem;
    margin-left: -8.125rem;
  }
  .mx-xxl-n140 {
    margin-right: -8.75rem;
    margin-left: -8.75rem;
  }
  .mx-xxl-n150 {
    margin-right: -9.375rem;
    margin-left: -9.375rem;
  }
  .mx-xxl-n160 {
    margin-right: -10rem;
    margin-left: -10rem;
  }
  .mx-xxl-n200 {
    margin-right: -12.5rem;
    margin-left: -12.5rem;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xxl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xxl-n5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xxl-n7 {
    margin-top: -0.4375rem;
    margin-bottom: -0.4375rem;
  }
  .my-xxl-n10 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .my-xxl-n20 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .my-xxl-n30 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  .my-xxl-n35 {
    margin-top: -2.1875rem;
    margin-bottom: -2.1875rem;
  }
  .my-xxl-n40 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xxl-n60 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .my-xxl-n70 {
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
  .my-xxl-n80 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xxl-n90 {
    margin-top: -5.625rem;
    margin-bottom: -5.625rem;
  }
  .my-xxl-n100 {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
  .my-xxl-n120 {
    margin-top: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .my-xxl-n130 {
    margin-top: -8.125rem;
    margin-bottom: -8.125rem;
  }
  .my-xxl-n140 {
    margin-top: -8.75rem;
    margin-bottom: -8.75rem;
  }
  .my-xxl-n150 {
    margin-top: -9.375rem;
    margin-bottom: -9.375rem;
  }
  .my-xxl-n160 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .my-xxl-n200 {
    margin-top: -12.5rem;
    margin-bottom: -12.5rem;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem;
  }
  .mt-xxl-n3 {
    margin-top: -1rem;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem;
  }
  .mt-xxl-n5 {
    margin-top: -3rem;
  }
  .mt-xxl-n7 {
    margin-top: -0.4375rem;
  }
  .mt-xxl-n10 {
    margin-top: -0.625rem;
  }
  .mt-xxl-n20 {
    margin-top: -1.25rem;
  }
  .mt-xxl-n30 {
    margin-top: -1.875rem;
  }
  .mt-xxl-n35 {
    margin-top: -2.1875rem;
  }
  .mt-xxl-n40 {
    margin-top: -2.5rem;
  }
  .mt-xxl-n60 {
    margin-top: -3.75rem;
  }
  .mt-xxl-n70 {
    margin-top: -4.375rem;
  }
  .mt-xxl-n80 {
    margin-top: -5rem;
  }
  .mt-xxl-n90 {
    margin-top: -5.625rem;
  }
  .mt-xxl-n100 {
    margin-top: -6.25rem;
  }
  .mt-xxl-n120 {
    margin-top: -7.5rem;
  }
  .mt-xxl-n130 {
    margin-top: -8.125rem;
  }
  .mt-xxl-n140 {
    margin-top: -8.75rem;
  }
  .mt-xxl-n150 {
    margin-top: -9.375rem;
  }
  .mt-xxl-n160 {
    margin-top: -10rem;
  }
  .mt-xxl-n200 {
    margin-top: -12.5rem;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem;
  }
  .me-xxl-n3 {
    margin-right: -1rem;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem;
  }
  .me-xxl-n5 {
    margin-right: -3rem;
  }
  .me-xxl-n7 {
    margin-right: -0.4375rem;
  }
  .me-xxl-n10 {
    margin-right: -0.625rem;
  }
  .me-xxl-n20 {
    margin-right: -1.25rem;
  }
  .me-xxl-n30 {
    margin-right: -1.875rem;
  }
  .me-xxl-n35 {
    margin-right: -2.1875rem;
  }
  .me-xxl-n40 {
    margin-right: -2.5rem;
  }
  .me-xxl-n60 {
    margin-right: -3.75rem;
  }
  .me-xxl-n70 {
    margin-right: -4.375rem;
  }
  .me-xxl-n80 {
    margin-right: -5rem;
  }
  .me-xxl-n90 {
    margin-right: -5.625rem;
  }
  .me-xxl-n100 {
    margin-right: -6.25rem;
  }
  .me-xxl-n120 {
    margin-right: -7.5rem;
  }
  .me-xxl-n130 {
    margin-right: -8.125rem;
  }
  .me-xxl-n140 {
    margin-right: -8.75rem;
  }
  .me-xxl-n150 {
    margin-right: -9.375rem;
  }
  .me-xxl-n160 {
    margin-right: -10rem;
  }
  .me-xxl-n200 {
    margin-right: -12.5rem;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem;
  }
  .mb-xxl-n7 {
    margin-bottom: -0.4375rem;
  }
  .mb-xxl-n10 {
    margin-bottom: -0.625rem;
  }
  .mb-xxl-n20 {
    margin-bottom: -1.25rem;
  }
  .mb-xxl-n30 {
    margin-bottom: -1.875rem;
  }
  .mb-xxl-n35 {
    margin-bottom: -2.1875rem;
  }
  .mb-xxl-n40 {
    margin-bottom: -2.5rem;
  }
  .mb-xxl-n60 {
    margin-bottom: -3.75rem;
  }
  .mb-xxl-n70 {
    margin-bottom: -4.375rem;
  }
  .mb-xxl-n80 {
    margin-bottom: -5rem;
  }
  .mb-xxl-n90 {
    margin-bottom: -5.625rem;
  }
  .mb-xxl-n100 {
    margin-bottom: -6.25rem;
  }
  .mb-xxl-n120 {
    margin-bottom: -7.5rem;
  }
  .mb-xxl-n130 {
    margin-bottom: -8.125rem;
  }
  .mb-xxl-n140 {
    margin-bottom: -8.75rem;
  }
  .mb-xxl-n150 {
    margin-bottom: -9.375rem;
  }
  .mb-xxl-n160 {
    margin-bottom: -10rem;
  }
  .mb-xxl-n200 {
    margin-bottom: -12.5rem;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem;
  }
  .ms-xxl-n3 {
    margin-left: -1rem;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem;
  }
  .ms-xxl-n5 {
    margin-left: -3rem;
  }
  .ms-xxl-n7 {
    margin-left: -0.4375rem;
  }
  .ms-xxl-n10 {
    margin-left: -0.625rem;
  }
  .ms-xxl-n20 {
    margin-left: -1.25rem;
  }
  .ms-xxl-n30 {
    margin-left: -1.875rem;
  }
  .ms-xxl-n35 {
    margin-left: -2.1875rem;
  }
  .ms-xxl-n40 {
    margin-left: -2.5rem;
  }
  .ms-xxl-n60 {
    margin-left: -3.75rem;
  }
  .ms-xxl-n70 {
    margin-left: -4.375rem;
  }
  .ms-xxl-n80 {
    margin-left: -5rem;
  }
  .ms-xxl-n90 {
    margin-left: -5.625rem;
  }
  .ms-xxl-n100 {
    margin-left: -6.25rem;
  }
  .ms-xxl-n120 {
    margin-left: -7.5rem;
  }
  .ms-xxl-n130 {
    margin-left: -8.125rem;
  }
  .ms-xxl-n140 {
    margin-left: -8.75rem;
  }
  .ms-xxl-n150 {
    margin-left: -9.375rem;
  }
  .ms-xxl-n160 {
    margin-left: -10rem;
  }
  .ms-xxl-n200 {
    margin-left: -12.5rem;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-1 {
    padding: 0.25rem;
  }
  .p-xxl-2 {
    padding: 0.5rem;
  }
  .p-xxl-3 {
    padding: 1rem;
  }
  .p-xxl-4 {
    padding: 1.5rem;
  }
  .p-xxl-5 {
    padding: 3rem;
  }
  .p-xxl-7 {
    padding: 0.4375rem;
  }
  .p-xxl-10 {
    padding: 0.625rem;
  }
  .p-xxl-20 {
    padding: 1.25rem;
  }
  .p-xxl-30 {
    padding: 1.875rem;
  }
  .p-xxl-35 {
    padding: 2.1875rem;
  }
  .p-xxl-40 {
    padding: 2.5rem;
  }
  .p-xxl-60 {
    padding: 3.75rem;
  }
  .p-xxl-70 {
    padding: 4.375rem;
  }
  .p-xxl-80 {
    padding: 5rem;
  }
  .p-xxl-90 {
    padding: 5.625rem;
  }
  .p-xxl-100 {
    padding: 6.25rem;
  }
  .p-xxl-120 {
    padding: 7.5rem;
  }
  .p-xxl-130 {
    padding: 8.125rem;
  }
  .p-xxl-140 {
    padding: 8.75rem;
  }
  .p-xxl-150 {
    padding: 9.375rem;
  }
  .p-xxl-160 {
    padding: 10rem;
  }
  .p-xxl-200 {
    padding: 12.5rem;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xxl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xxl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xxl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xxl-5 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xxl-7 {
    padding-right: 0.4375rem;
    padding-left: 0.4375rem;
  }
  .px-xxl-10 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-xxl-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xxl-30 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-xxl-35 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-xxl-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxl-60 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xxl-70 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-xxl-80 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxl-90 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-xxl-100 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-xxl-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-xxl-130 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-xxl-140 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-xxl-150 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-xxl-160 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-xxl-200 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xxl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xxl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xxl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xxl-7 {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .py-xxl-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-xxl-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xxl-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-xxl-35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-xxl-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxl-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xxl-70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-xxl-80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxl-90 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-xxl-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-xxl-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-xxl-130 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-xxl-140 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-xxl-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-xxl-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-xxl-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem;
  }
  .pt-xxl-3 {
    padding-top: 1rem;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem;
  }
  .pt-xxl-5 {
    padding-top: 3rem;
  }
  .pt-xxl-7 {
    padding-top: 0.4375rem;
  }
  .pt-xxl-10 {
    padding-top: 0.625rem;
  }
  .pt-xxl-20 {
    padding-top: 1.25rem;
  }
  .pt-xxl-30 {
    padding-top: 1.875rem;
  }
  .pt-xxl-35 {
    padding-top: 2.1875rem;
  }
  .pt-xxl-40 {
    padding-top: 2.5rem;
  }
  .pt-xxl-60 {
    padding-top: 3.75rem;
  }
  .pt-xxl-70 {
    padding-top: 4.375rem;
  }
  .pt-xxl-80 {
    padding-top: 5rem;
  }
  .pt-xxl-90 {
    padding-top: 5.625rem;
  }
  .pt-xxl-100 {
    padding-top: 6.25rem;
  }
  .pt-xxl-120 {
    padding-top: 7.5rem;
  }
  .pt-xxl-130 {
    padding-top: 8.125rem;
  }
  .pt-xxl-140 {
    padding-top: 8.75rem;
  }
  .pt-xxl-150 {
    padding-top: 9.375rem;
  }
  .pt-xxl-160 {
    padding-top: 10rem;
  }
  .pt-xxl-200 {
    padding-top: 12.5rem;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem;
  }
  .pe-xxl-3 {
    padding-right: 1rem;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem;
  }
  .pe-xxl-5 {
    padding-right: 3rem;
  }
  .pe-xxl-7 {
    padding-right: 0.4375rem;
  }
  .pe-xxl-10 {
    padding-right: 0.625rem;
  }
  .pe-xxl-20 {
    padding-right: 1.25rem;
  }
  .pe-xxl-30 {
    padding-right: 1.875rem;
  }
  .pe-xxl-35 {
    padding-right: 2.1875rem;
  }
  .pe-xxl-40 {
    padding-right: 2.5rem;
  }
  .pe-xxl-60 {
    padding-right: 3.75rem;
  }
  .pe-xxl-70 {
    padding-right: 4.375rem;
  }
  .pe-xxl-80 {
    padding-right: 5rem;
  }
  .pe-xxl-90 {
    padding-right: 5.625rem;
  }
  .pe-xxl-100 {
    padding-right: 6.25rem;
  }
  .pe-xxl-120 {
    padding-right: 7.5rem;
  }
  .pe-xxl-130 {
    padding-right: 8.125rem;
  }
  .pe-xxl-140 {
    padding-right: 8.75rem;
  }
  .pe-xxl-150 {
    padding-right: 9.375rem;
  }
  .pe-xxl-160 {
    padding-right: 10rem;
  }
  .pe-xxl-200 {
    padding-right: 12.5rem;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem;
  }
  .pb-xxl-7 {
    padding-bottom: 0.4375rem;
  }
  .pb-xxl-10 {
    padding-bottom: 0.625rem;
  }
  .pb-xxl-20 {
    padding-bottom: 1.25rem;
  }
  .pb-xxl-30 {
    padding-bottom: 1.875rem;
  }
  .pb-xxl-35 {
    padding-bottom: 2.1875rem;
  }
  .pb-xxl-40 {
    padding-bottom: 2.5rem;
  }
  .pb-xxl-60 {
    padding-bottom: 3.75rem;
  }
  .pb-xxl-70 {
    padding-bottom: 4.375rem;
  }
  .pb-xxl-80 {
    padding-bottom: 5rem;
  }
  .pb-xxl-90 {
    padding-bottom: 5.625rem;
  }
  .pb-xxl-100 {
    padding-bottom: 6.25rem;
  }
  .pb-xxl-120 {
    padding-bottom: 7.5rem;
  }
  .pb-xxl-130 {
    padding-bottom: 8.125rem;
  }
  .pb-xxl-140 {
    padding-bottom: 8.75rem;
  }
  .pb-xxl-150 {
    padding-bottom: 9.375rem;
  }
  .pb-xxl-160 {
    padding-bottom: 10rem;
  }
  .pb-xxl-200 {
    padding-bottom: 12.5rem;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem;
  }
  .ps-xxl-3 {
    padding-left: 1rem;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem;
  }
  .ps-xxl-5 {
    padding-left: 3rem;
  }
  .ps-xxl-7 {
    padding-left: 0.4375rem;
  }
  .ps-xxl-10 {
    padding-left: 0.625rem;
  }
  .ps-xxl-20 {
    padding-left: 1.25rem;
  }
  .ps-xxl-30 {
    padding-left: 1.875rem;
  }
  .ps-xxl-35 {
    padding-left: 2.1875rem;
  }
  .ps-xxl-40 {
    padding-left: 2.5rem;
  }
  .ps-xxl-60 {
    padding-left: 3.75rem;
  }
  .ps-xxl-70 {
    padding-left: 4.375rem;
  }
  .ps-xxl-80 {
    padding-left: 5rem;
  }
  .ps-xxl-90 {
    padding-left: 5.625rem;
  }
  .ps-xxl-100 {
    padding-left: 6.25rem;
  }
  .ps-xxl-120 {
    padding-left: 7.5rem;
  }
  .ps-xxl-130 {
    padding-left: 8.125rem;
  }
  .ps-xxl-140 {
    padding-left: 8.75rem;
  }
  .ps-xxl-150 {
    padding-left: 9.375rem;
  }
  .ps-xxl-160 {
    padding-left: 10rem;
  }
  .ps-xxl-200 {
    padding-left: 12.5rem;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-1 {
    gap: 0.25rem;
  }
  .gap-xxl-2 {
    gap: 0.5rem;
  }
  .gap-xxl-3 {
    gap: 1rem;
  }
  .gap-xxl-4 {
    gap: 1.5rem;
  }
  .gap-xxl-5 {
    gap: 3rem;
  }
  .gap-xxl-7 {
    gap: 0.4375rem;
  }
  .gap-xxl-10 {
    gap: 0.625rem;
  }
  .gap-xxl-20 {
    gap: 1.25rem;
  }
  .gap-xxl-30 {
    gap: 1.875rem;
  }
  .gap-xxl-35 {
    gap: 2.1875rem;
  }
  .gap-xxl-40 {
    gap: 2.5rem;
  }
  .gap-xxl-60 {
    gap: 3.75rem;
  }
  .gap-xxl-70 {
    gap: 4.375rem;
  }
  .gap-xxl-80 {
    gap: 5rem;
  }
  .gap-xxl-90 {
    gap: 5.625rem;
  }
  .gap-xxl-100 {
    gap: 6.25rem;
  }
  .gap-xxl-120 {
    gap: 7.5rem;
  }
  .gap-xxl-130 {
    gap: 8.125rem;
  }
  .gap-xxl-140 {
    gap: 8.75rem;
  }
  .gap-xxl-150 {
    gap: 9.375rem;
  }
  .gap-xxl-160 {
    gap: 10rem;
  }
  .gap-xxl-200 {
    gap: 12.5rem;
  }
  .row-gap-xxl-0 {
    row-gap: 0;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem;
  }
  .row-gap-xxl-7 {
    row-gap: 0.4375rem;
  }
  .row-gap-xxl-10 {
    row-gap: 0.625rem;
  }
  .row-gap-xxl-20 {
    row-gap: 1.25rem;
  }
  .row-gap-xxl-30 {
    row-gap: 1.875rem;
  }
  .row-gap-xxl-35 {
    row-gap: 2.1875rem;
  }
  .row-gap-xxl-40 {
    row-gap: 2.5rem;
  }
  .row-gap-xxl-60 {
    row-gap: 3.75rem;
  }
  .row-gap-xxl-70 {
    row-gap: 4.375rem;
  }
  .row-gap-xxl-80 {
    row-gap: 5rem;
  }
  .row-gap-xxl-90 {
    row-gap: 5.625rem;
  }
  .row-gap-xxl-100 {
    row-gap: 6.25rem;
  }
  .row-gap-xxl-120 {
    row-gap: 7.5rem;
  }
  .row-gap-xxl-130 {
    row-gap: 8.125rem;
  }
  .row-gap-xxl-140 {
    row-gap: 8.75rem;
  }
  .row-gap-xxl-150 {
    row-gap: 9.375rem;
  }
  .row-gap-xxl-160 {
    row-gap: 10rem;
  }
  .row-gap-xxl-200 {
    row-gap: 12.5rem;
  }
  .column-gap-xxl-0 {
    column-gap: 0;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem;
  }
  .column-gap-xxl-7 {
    column-gap: 0.4375rem;
  }
  .column-gap-xxl-10 {
    column-gap: 0.625rem;
  }
  .column-gap-xxl-20 {
    column-gap: 1.25rem;
  }
  .column-gap-xxl-30 {
    column-gap: 1.875rem;
  }
  .column-gap-xxl-35 {
    column-gap: 2.1875rem;
  }
  .column-gap-xxl-40 {
    column-gap: 2.5rem;
  }
  .column-gap-xxl-60 {
    column-gap: 3.75rem;
  }
  .column-gap-xxl-70 {
    column-gap: 4.375rem;
  }
  .column-gap-xxl-80 {
    column-gap: 5rem;
  }
  .column-gap-xxl-90 {
    column-gap: 5.625rem;
  }
  .column-gap-xxl-100 {
    column-gap: 6.25rem;
  }
  .column-gap-xxl-120 {
    column-gap: 7.5rem;
  }
  .column-gap-xxl-130 {
    column-gap: 8.125rem;
  }
  .column-gap-xxl-140 {
    column-gap: 8.75rem;
  }
  .column-gap-xxl-150 {
    column-gap: 9.375rem;
  }
  .column-gap-xxl-160 {
    column-gap: 10rem;
  }
  .column-gap-xxl-200 {
    column-gap: 12.5rem;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4.6875rem;
  }
  .fs-2 {
    font-size: 4rem;
  }
  .fs-3 {
    font-size: 2.5rem;
  }
  .fs-4 {
    font-size: 2rem;
  }
  .fs-5 {
    font-size: 1.5rem;
  }
}

@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-inline-grid {
    display: inline-grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}

/*# sourceMappingURL=style.css.map */