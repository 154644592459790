// === Card ========================================= >>>>>

.card-title,.post-list{
    --#{$prefix}link-color-rgb: var(--#{$prefix}heading-color-rgb);
    --#{$prefix}link-color: var(--#{$prefix}heading-color);
    --#{$prefix}link-hover-color: var(--#{$prefix}heading-color);
    --#{$prefix}link-hover-color-rgb: var(--#{$prefix}heading-color-rgb);    
    a{
        transition: $transition-base;
    }
}

.card{
    .blog-custom-link{
        --#{$prefix}link-color: var(--#{$prefix}body-color);
        --#{$prefix}link-color-rgb: var(--#{$prefix}body-color-rgb);
        transition: all .4s ease-in-out;
    }
    &:hover{
        .blog-custom-link{
            --#{$prefix}link-color: var(--#{$prefix}primary);
            --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
        }
    }
    @include media-breakpoint-down(xxl){
        .card-info{
            font-size: 16px;
        }
    }
}

.card-style-1{
    --#{$prefix}card-spacer-x: 0;
    .card-image-wrapper{
        height: 180.5px;
        min-height: 180.5px;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: transform .5s ease-in-out;
        }     
        
        @include media-breakpoint-down(lg){
            width: 100%;
            height: 100%;        
        } 
    }
    &:hover{
        img{
            transform: scale(1.08);
        }
    }
    .card-text{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.card-style-2{
    --#{$prefix}card-spacer-x: 0;
    --#{$prefix}card-bg: var(--#{$prefix}body-bg);
    .card-image-wrapper{
        height: 133px;
        min-height: 133px;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: transform .5s ease-in-out;
        }  
        @include media-breakpoint-down(xxl){
            height: 159px;
            min-height: 159px;        
        }     
        @include media-breakpoint-down(lg){
            width: 100%;
            height: 100%;        
        } 
    }
    .card-text{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 355px;
        @include media-breakpoint-down(xxl){
            max-width: 310px;        
        }
        @include media-breakpoint-down(sm){
            max-width: 282px;        
        }
    }
    .arrow-icon-3{    
        --#{$prefix}arrow-icon-3-stroke-color: #9C9CA0; 
        --#{$prefix}arrow-style-1-bg: var(--#{$prefix}body-color-style-3); 
    }
    &:hover{
        img{
            transform: scale(1.08);
        }
        .arrow-icon-3{    
            --#{$prefix}arrow-icon-3-stroke-color: var(--#{$prefix}white); 
            --#{$prefix}arrow-style-1-bg: var(--#{$prefix}primary); 
            svg{
                animation: toLeftFromRight .6s alternate-reverse;
            }
        }
        .card-title,.post-list{
            --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
            --#{$prefix}link-color: var(--#{$prefix}primary);
            --#{$prefix}link-hover-color: var(--#{$prefix}primary);
            --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);
        }
    }
}


.card-custom-lists {
    li{
        border-bottom: 2px solid var(--#{$prefix}body-color-style-3);
        padding-bottom: 30px;
        &:last-child{
            border: none;
            padding-bottom: 0;
        }
        @include media-breakpoint-down(lg){
            margin-bottom: 24px;
            padding-bottom: 24px;     
            &:last-child{
                border: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }   
        }
    }
}

.card-style-3{
    --#{$prefix}card-bg: var(--#{$prefix}body-bg);
    --#{$prefix}card-image-height: 300px;
    --#{$prefix}card-spacer-y: 20px;
    --#{$prefix}card-spacer-x: 0;
    .card-image-wrapper{
        height: var(--#{$prefix}card-image-height);
        min-height: var(--#{$prefix}card-image-height);
        overflow: hidden;
        img{
            height: 100%;
            object-fit: cover;
            transition: transform .5s ease-in-out;
        }
    }
    
    .card-title,p{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &.card-custom{
        --#{$prefix}card-spacer-x: 24px;
        --#{$prefix}card-image-height: 240px;
        box-shadow: 0 0.15rem 0.7rem rgba(0, 0, 0, 0.07);
        @include media-breakpoint-down(sm) {
            --#{$prefix}card-spacer-x: 20px;      
        }
        .border-10{
            border-radius: 10px 10px 0 0;
            img{
                border-radius: 10px 10px 0 0;
            }
        }
    }
    &:hover{        
        img{
            transform: scale(1.08);
        }
    }
}


.card-style-4{
    --#{$prefix}card-spacer-y: 30px;
    --#{$prefix}card-spacer-x: 24px;
    --#{$prefix}card-inner-border-radius: 0;
    --#{$prefix}card-img-height: 322px;
    border-radius: 10px;    
    .card-image-wrapper{
        height: var(--#{$prefix}card-img-height);
        min-height: var(--#{$prefix}card-img-height);
        img{
            height: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
        }
    }
    .card-title{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.card-style-5{
    --#{$prefix}card-spacer-y: 0;
    --#{$prefix}card-spacer-x: 0;
    --#{$prefix}card-img-height: 150px;    
    padding: 10px;
    .card-image-wrapper{
        height: var(--#{$prefix}card-img-height);
        min-height: var(--#{$prefix}card-img-height);
        border-radius: 4px;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 4px;
            transition: transform .5s ease-in-out;
        }
    }
    &:hover{
        img{
            transform: scale(1.07);
        }
    }
    .card-title,p{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    @include media-breakpoint-down(xxl){
        --#{$prefix}card-img-height: 158px;
    }
    @include media-breakpoint-down(xl){
        --#{$prefix}card-img-height: 177px;
    }
    @include media-breakpoint-down(lg){
        --#{$prefix}card-img-height: 200px;
    }
}

.card-style-6{
    --#{$prefix}card-spacer-x: 0;
    --#{$prefix}card-spacer-y: 0;
    --#{$prefix}card-bg: var(--#{$prefix}body-bg);
    .card-image-wrapper{
        height: 80px;
        min-height: 80px;
        width: 104px;
        border-radius: 4px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 4px;
        }            
        @include media-breakpoint-down(xxl){
            height: 110px;
            min-height: 110px;    
            width: 84px;    
        }   
        @include media-breakpoint-down(xl){                
            width: 100%;    
        }
        @include media-breakpoint-down(lg){
            width: 100%;
            height: 100%;        
        } 
    }
}

.card-style-7{    
    --#{$prefix}card-spacer-y: 1.5rem;
    --#{$prefix}card-spacer-x: 1.5rem;
    --#{$prefix}card-image-border: var(--#{$prefix}primary);
    .card-image-wrapper{
        height: 306px;
        min-height: 306px;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .5s ease-in-out;
        }
    }
    .card-image-border{
        border-bottom: 3px solid var(--#{$prefix}card-image-border);
    }
    .card-title{
        --#{$prefix}link-color: var(--#{$prefix}white);
        --#{$prefix}link-color-rgb: var(--#{$prefix}white-rgb);
        --#{$prefix}link-hover-color: var(--#{$prefix}primary);
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);
    }
    .card-body{
        border-radius: 0 0 var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius);
    }
    &:hover{
        --#{$prefix}card-image-border: var(--#{$prefix}white);
        .btn-link-icon svg{
            transform: rotate(45deg);
        }
        img{
            transform: scale(1.07);
        }
    }    
}

.bg-custom-light{
    .card-style-1,.card-style-2,.card-style-3{
        --#{$prefix}card-bg: var(--#{$prefix}bg-custom-light);
    }
}

// === Card ========================================= >>>>>



// === Card-Pricing ========================================= >>>>>
.card-pricing{
    --#{$prefix}border-color: var(--#{$prefix}body-color-style-3);
    --#{$prefix}card-spacer-y: 30px;
    --#{$prefix}card-spacer-x: 30px;
    --#{$prefix}border-radius: 10px;
    @include media-breakpoint-down(sm){
        --#{$prefix}card-spacer-x: 16px;
    }
    &.card-pricing-2{
        --#{$prefix}card-spacer-y: 40px;
        --#{$prefix}card-spacer-x: 40px;
        .card-img-top{
            display: none;
        }
        &.active{
            .card-body{
                &::before{
                    background-image: none;
                } 
                border-radius: 10px;
            }            
        }
    }
    &.card-pricing-3{
        --#{$prefix}card-spacer-y: 60px;
        --#{$prefix}card-spacer-x: 60px;
        .pricing-list{
            padding: 0;
        }
        @include media-breakpoint-down(sm){
            --#{$prefix}card-spacer-y: 40px;
            --#{$prefix}card-spacer-x: 30px;
        }
    }
    .card-img-top{
        height: 180px;
        object-fit: cover;
        @include media-breakpoint-down(xl){
            height: 250px;
        }
    }
    .pricing-amount{
        color: var(--#{$prefix}primary);
        transition: all .4s ease-in-out;
    }
    .pricing-quantity{
        font-family: "Lexend", sans-serif;
        transition: all .4s ease-in-out;
    }
    .card-body{
        transition: all .4s ease-in-out;
    }

    .pricing-list{
        position: relative;
        padding: 0 16px;
        li{
            font-family: "Lexend", sans-serif;
        }
        @include media-breakpoint-down(sm){
            padding: 0 16px;
            li{
                font-size: 16px;
            }
            .check-icon svg{
                width: 14px;
            }
        }
    }

    &.active{
        --#{$prefix}border-color: var(--#{$prefix}primary);
        .card-body{
            background-color: rgba(var(--#{$prefix}secondary-rgb), 1);
            --#{$prefix}card-title-color: var(--#{$prefix}white);
            --#{$prefix}card-color: var(--#{$prefix}white);
            border-radius: 0 0 10px 10px;
            position: relative;

            &.card-body-bg-1{
                --#{$prefix}card-body-bg: url('../images/pricing-bg-1.jpg');
            }
            &.card-body-bg-2{
                --#{$prefix}card-body-bg: url('../images/pricing-bg-2.jpg');
            }
            &.card-body-bg-3{
                --#{$prefix}card-body-bg: url('../images/pricing-bg-3.jpg');
            }

            .pricing-amount{
                color: var(--#{$prefix}white);
            }
            .pricing-quantity{
                color: var(--#{$prefix}primary);
            }
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: var(--#{$prefix}card-body-bg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                opacity: .1;
            }
            .btn-secondary{
                --#{$prefix}btn-bg: var(--#{$prefix}primary);
                --#{$prefix}btn-border-color: var(--#{$prefix}primary);      
                --#{$prefix}btn-color: var(--#{$prefix}white);
                --#{$prefix}btn-hover-bg: var(--#{$prefix}white);
                --#{$prefix}btn-active-bg: var(--#{$prefix}white);
                --#{$prefix}btn-hover-color: var(--#{$prefix}primary);
                --#{$prefix}btn-active-color: var(--#{$prefix}primary); 
                --#{$prefix}btn-hover-border-color: var(--#{$prefix}white);   
                --#{$prefix}btn-active-border-color: var(--#{$prefix}white);
            }
        }
        .btn-arrow{
            --#{$prefix}btn-arrow-bg: var(--#{$prefix}primary);
            svg{
                transform: rotate(45deg);
            }            
        }
    }
}
@media (max-width: 390px){
    .card-pricing{        
        --#{$prefix}card-spacer-x: 10px;
        .pricing-list{
            padding: 0;
        }
        &.card-pricing-2{
            --#{$prefix}card-spacer-y: 30px;
            --#{$prefix}card-spacer-x: 16px;            
        }
        &.card-pricing-3{
            --#{$prefix}card-spacer-y: 30px;
            --#{$prefix}card-spacer-x: 20px;            
        }
    }
}
@media (max-width: 349px){
    .card-pricing{                
        &.card-pricing-2{
            --#{$prefix}card-spacer-y: 24px;
            --#{$prefix}card-spacer-x: 10px;            
        }
        &.card-pricing-3{
            --#{$prefix}card-spacer-y: 24px;
            --#{$prefix}card-spacer-x: 16px;            
        }
    }
}
@media screen and (min-width: 1200px) and (max-width: 1399.9px){
    .card-pricing{ 
        --#{$prefix}card-spacer-x: 15px;       
        .pricing-list{
            padding: 0 6px;
        }
        &.card-pricing-2{
            --#{$prefix}card-spacer-x: 20px;
        }
        &.card-pricing-3{
            --#{$prefix}card-spacer-y: 50px;
            --#{$prefix}card-spacer-x: 24px;
        }
    }
}
// === Card-Pricing ========================================= >>>>>