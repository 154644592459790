// ======================== Header-Landing ==========================>>>> 
.header-landing{
    .navbar {
        --#{$prefix}navbar-color: var(--#{$prefix}heading-color);
    }
    .nav-link {
        --#{$prefix}nav-link-font-weight: 600;
        &:hover{
            --#{$prefix}nav-link-font-weight: 600;
        }
    }    
}
.shadow-on-hover{ 
    position: relative;
    overflow: hidden;
    img{
        transition: transform .4s ease-in-out;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--#{$prefix}secondary-rgb), .3);
        opacity: 0;
        transition: opacity .4s ease-in-out;
    }
    &:hover{
        &::after{
            opacity: 1;    
            transform: scale(1.02);                   
        }
        img{
            transform: scale(1.02);
        } 
    }
}
// ======================== Header-Landing ==========================>>>> 


// ======================== Hero-Landing ==========================>>>> 
.hero-landing{
    --#{$prefix}hero-landing-spacing: 140px 0 500px 0;
    .hero-inner{
        position: relative;
        padding: var(--#{$prefix}hero-landing-spacing);
        z-index: 1;
    }
    .hero-wrapper {        
        --bg-parallax-image: url(../images/landing-page/hero-landing-bg.jpg);    
        --bg-parallax-opacity: 1;   
    }

    border-bottom: 10px solid var(--#{$prefix}primary);

    @include media-breakpoint-down(xxl){
        --#{$prefix}hero-landing-spacing: 120px 0 450px 0;
    }
    @include media-breakpoint-down(xl){
        --#{$prefix}hero-landing-spacing: 100px 0 380px 0;
    }
    @include media-breakpoint-down(lg){
        --#{$prefix}hero-landing-spacing: 100px 0 340px 0;
    }
    @include media-breakpoint-down(md){
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
    @include media-breakpoint-down(sm){
        --#{$prefix}hero-landing-spacing: 100px 0 210px 0;
    }
}
.hero-inner-image{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    max-width: 1600px;
}
.arrow-image{
    @include media-breakpoint-down(lg){
        width: 130px;
    }
    @include media-breakpoint-down(md){
        width: 110px;
    }
}
// ======================== Hero-Landing ==========================>>>> 


// ======================== Demos ==========================>>>>      
.demo-title {   
    transition: all 530ms ease-in-out;    
}    
.demo-image{
    --#{$prefix}demo-image-bg: var(--#{$prefix}body-color-style-3);
    background-color: var(--#{$prefix}demo-image-bg);
    position: relative;
    top: 0;
    transition: all 400ms ease-in-out;       
    border-radius: 4px;
    img{
        border-radius: 2px;
    }
}
.demo-wrapper{
    --#{$prefix}link-color-rgb: var(--#{$prefix}black-rgb);
    --#{$prefix}link-color: var(--#{$prefix}black);
    --#{$prefix}link-hover-color: var(--#{$prefix}primary);
    --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);
    width: fit-content;
    &:hover{
        .demo-image{
            top: -12px;
        }
        .demo-title{
            --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
            --#{$prefix}link-color: var(--#{$prefix}primary);            
        }
    }    
}
.demoSwiper-wrapper{
    --swiper-theme-color: var(--#{$prefix}primary);
    .swiper-pagination-bullets.swiper-pagination-horizontal{
        bottom: -55px;
        @include media-breakpoint-down(lg){
            bottom: -45px;
        }
    }
}
// ======================== Demos ==========================>>>>


// ======================== Feature Wrapper ==========================>>>> 
.feature-wrapper{
    --#{$prefix}feature-wrapper-bg: var(--#{$prefix}body-color-style-3);
    --#{$prefix}feature-inner-bg: var(--#{$prefix}body-bg);
    border-radius: 10px;
    background-color: var(--#{$prefix}feature-wrapper-bg);
    width: 410px;
    height: 360px;
    overflow: hidden;
    padding: 20px;
    .feature-inner{
        background-color: var(--#{$prefix}feature-inner-bg);
        height: 100%;
        border-radius: 8px;
    }
    img{
        transition: transform .4s ease-in-out;
    }
    .feature-title{
        transition: all .4s ease-in-out;
    }
    &:hover{
        img{
            transform: scale(1.07);
        }
        .feature-title{
            --#{$prefix}heading-color: var(--#{$prefix}primary);
        }
    }
    @include media-breakpoint-down(xxl){
        width: 350px;
        height: 310px;
    }
    @include media-breakpoint-down(xl){
        width: 290px;
        height: 280px;
        img{
            max-width: 190px;
            object-fit: cover;
        }
        .feature-title{
            font-size: 30px;
        }
    }
    @include media-breakpoint-down(lg){
        width: 332px;
        height: 282px;
        img{
            max-width: 210px;
            object-fit: cover;
        }        
    }
    @include media-breakpoint-down(md){
        width: 242px;
        height: 240px;
        img{
            max-width: 180px;
            max-height: 95px;
            object-fit: cover;
        }
        .feature-title{
            font-size: 26px;
        }        
    }
    @include media-breakpoint-down(sm){
        width: 100%;
        img{
            max-width: 100%;
        }
        .feature-title{
            font-size: 30px;
        }        
    }
}
// ======================== Feature Wrapper ==========================>>>> 


// ======================== Footer-Landing ==========================>>>> 
.support-text{
    font-size: 64px;
    @include media-breakpoint-down(xxl){
        font-size: 60px;
    }
    @include media-breakpoint-down(xl){
        font-size: 52px;
    }
    @include media-breakpoint-down(lg){
        font-size: 45px;
    }
    @include media-breakpoint-down(md){
        font-size: 40px;
    }
    @include media-breakpoint-down(sm){
        font-size: 26px;
    }
}
.support-image{
    @include media-breakpoint-down(md){
        width: 200px;
    }
    @include media-breakpoint-down(sm){
        width: 175px;
    }
}
// ======================== Footer-Landing ==========================>>>> 

.faq-landing{
    border-top: 10px solid var(--#{$prefix}primary);
    border-bottom: 10px solid var(--#{$prefix}primary);
    @include media-breakpoint-down(md){
        border-top: 5px solid var(--#{$prefix}primary);
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
}
.landing-inner-heading{
    @include media-breakpoint-down(xxl){
        font-size: 52px;
    }
    @include media-breakpoint-down(xl){
        font-size: 45px;
    }
    @include media-breakpoint-down(lg){
        font-size: 38px;
    }
    @include media-breakpoint-down(md){
        font-size: 35px;
    }
    @include media-breakpoint-down(sm){
        font-size: 30px;
    }
}
.section-responsive{
    border-top: 10px solid var(--#{$prefix}primary);
    border-bottom: 10px solid var(--#{$prefix}primary);
    @include media-breakpoint-down(md){
        border-top: 5px solid var(--#{$prefix}primary);
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
}
.inner-page-features{
    border-bottom: 10px solid var(--#{$prefix}primary);
    @include media-breakpoint-down(md){
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
}
.section-inner-pages{
    border-bottom: 10px solid var(--#{$prefix}primary);
    @include media-breakpoint-down(md){
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
}