body{       
    padding-right: 0 !important;  
}

.page{    
    @include media-breakpoint-down(lg){
        overflow-x: hidden;
    }
}

:root{
    --#{$prefix}primary: #F06728;
    --#{$prefix}primary-rgb: 240, 103, 40;
    --#{$prefix}secondary: #25224B;
    --#{$prefix}secondary-rgb: 37, 34, 75;
    
    --#{$prefix}border-color: var(--#{$prefix}body-color-style-1);
    --#{$prefix}border-width: 2px;

    --#{$prefix}font-family-style-1: "Lexend", sans-serif;
    --#{$prefix}font-family-style-2: "Yantramanav", sans-serif;

    --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
    --#{$prefix}link-color: var(--#{$prefix}primary);
    --#{$prefix}link-hover-color: var(--#{$prefix}primary);
    --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

    --#{$prefix}body-color-style-1: #f9f9f9;
    --#{$prefix}body-color-style-1-rgb: 249, 249, 249;

    --#{$prefix}body-color-style-2: #484655;
    --#{$prefix}body-color-style-2-rgb: 72, 70, 85, 1;

    --#{$prefix}body-color-style-3: #EBEBEF;
    --#{$prefix}body-color-style-3-rgb: 235, 235, 239;

    --#{$prefix}bg-custom-light: #fffbf9;
    --#{$prefix}bg-custom-light-rgb: 255, 251, 249;

    --#{$prefix}bg-custom-light-style-2: #EFEFF7;
    --#{$prefix}bg-custom-light-style-2-rgb: 239, 239, 247;

    --#{$prefix}heading-color-style-1: #4D447B;
    --#{$prefix}heading-color-style-1-rgb: 77, 68, 123;

    --#{$prefix}brands-border-color: #D9D9D9;
    --#{$prefix}border-color-style-1: #9C9CA0;
    --#{$prefix}border-color-style-2: #EBEBEF;
}

[data-bs-theme="light"]{
    --#{$prefix}primary: #F06728;
    --#{$prefix}primary-rgb: 240, 103, 40;
    --#{$prefix}secondary: #25224B;
    --#{$prefix}secondary-rgb: 37, 34, 75;
    --#{$prefix}border-color: var(--#{$prefix}primary);
    .form-control{
        --#{$prefix}border-width: 0;
    }
}

[data-bs-theme="dark"]{
        --#{$prefix}heading-color-style-1: #EFEFF7;
        --#{$prefix}heading-color-style-1-rgb: 239, 239, 247;
        --#{$prefix}bg-custom-light: #000;
        --#{$prefix}bg-custom-light-rgb: 0,0,0;

        --#{$prefix}bg-custom-light-style-2: #000;
        --#{$prefix}bg-custom-light-style-2-rgb: 0,0,0;

        --#{$prefix}body-color-style-3: var(--#{$prefix}body-bg);
        --#{$prefix}body-color-style-2: #f5f5f6;

          
    .heading-color-style-1{
        --#{$prefix}heading-color-style-1: #EFEFF7;
        --#{$prefix}heading-color-style-1-rgb: 239, 239, 247;
    }
    .shadow{
        box-shadow: 0 .1rem .95rem rgba(255, 255, 255, 0.1) !important;
    }
    .team-image-hover{
        --#{$prefix}team-image-hover-bg: rgba(33, 33, 33, .9);
    }
    .portfolio-image-hover{
        --#{$prefix}portfolio-image-hover-bg: rgba(33, 33, 33, .9);
    }
    .arrow-icon{
        --#{$prefix}arrow-icon-stroke-color: #EFEFF7;           
    }
    .about-service{
        --#{$prefix}about-service-text-color: rgba(255,255,255, .8);
    }

    .contact-about{    
        --#{$prefix}link-color-rgb: var(--#{$prefix}body-color-style-2-rgb);
        --#{$prefix}link-color: var(--#{$prefix}body-color-style-2);
        --#{$prefix}link-hover-color: var(--#{$prefix}body-color-style-2);
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}body-color-style-2-rgb);
    
        .contact-icon{         
            --#{$prefix}contact-icon-bg: var(--#{$prefix}body-color-style-2);
        }
    }
    
    .progress{
        --#{$prefix}progress-bg: #696194;
        --#{$prefix}progress-bar-bg: var(--#{$prefix}white);
    }
    .section-header{
        --#{$prefix}header-box-shadow: 0 2px 10px rgba(var(--#{$prefix}white-rgb), .05); 
    }        
    .header-2{
        --#{$prefix}header-bg: var(--#{$prefix}body-bg);
    }
    .check-circle-icon{
        --#{$prefix}check-icon-stroke-color: #EFEFF7;     
        --#{$prefix}check-icon-color: var(--#{$prefix}body-bg);    
    }    
    .btn-primary {
        --#{$prefix}btn-hover-color: var(--#{$prefix}secondary);
        --#{$prefix}btn-active-color: var(--#{$prefix}secondary); 
        --#{$prefix}btn-hover-bg: var(--#{$prefix}white);
        --#{$prefix}btn-active-bg: var(--#{$prefix}white);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}white);   
        --#{$prefix}btn-active-border-color: var(--#{$prefix}white); 

        &:hover{
            .arrow-icon-2{
                --#{$prefix}arrow-icon-2-stroke-color: var(--#{$prefix}secondary);           
            }
        }
    } 
    .btn-secondary {
        --#{$prefix}btn-bg: var(--#{$prefix}white);
        --#{$prefix}btn-color: var(--#{$prefix}secondary);    
        --#{$prefix}btn-border-color: var(--#{$prefix}white);
        --#{$prefix}btn-hover-color: var(--#{$prefix}white);
        --#{$prefix}btn-active-color: var(--#{$prefix}white);
        --#{$prefix}btn-hover-bg: var(--#{$prefix}primary);
        --#{$prefix}btn-active-bg: var(--#{$prefix}primary);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}primary);  
        --#{$prefix}btn-active-border-color: var(--#{$prefix}primary);  
    }
    .navbar {
        --#{$prefix}navbar-color: var(--#{$prefix}white);
    }
    .card{       
        --#{$prefix}card-box-shadow: rgba(var(--#{$prefix}white-rgb), 0.08) 0px 4px 5px;
        &.card-shadow-style-1{
            --#{$prefix}card-box-shadow: rgba(var(--#{$prefix}white-rgb), 0.08) 0px 4px 5px;
        }
        &.card-shadow-style-2{
            --#{$prefix}card-box-shadow: rgba(var(--#{$prefix}white-rgb), 0.08) 0px 4px 5px;
        }
    }
    .top-bar-contact-lists{
        --#{$prefix}link-color-rgb: var(--#{$prefix}white-rgb);
        --#{$prefix}link-color: var(--#{$prefix}white);
        --#{$prefix}link-hover-color: var(--#{$prefix}white);
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}white-rgb);
        --#{$prefix}top-bar-icons-color: var(--#{$prefix}white);
    }   
}

@include media-breakpoint-down(xxl){
    .display-3{
        font-size: 3.2rem;
    }
    .display-6{
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px){
    h3{
        font-size: 38px;
    }
}